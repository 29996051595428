
import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from './../../components/pageheader';
import { useNavigate } from 'react-router-dom';
import VehicleForm from './vehicle-form';

const clientObject = {
    reg_no: "", driver_name: "", owning_company: "", isExternal: false
}

const NewVehicle = () => {

    const nav_items = [
        { title: 'Vehicles', href: '/app/vehicles' },
        { title: 'New Vehicle' }
    ];

    // const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const handleSubmit = (details) => {

        // const action = searchParams.get('action');

        // if (action === "create_event") {
        //     navigate(`/app/vehicles/new?client=${details.id}`);
        // } else {
        //     navigate(`/app/clients/${details.id}`);
        // }

        navigate(`/app/vehicles/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <VehicleForm
                    details={clientObject}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/vehicles').VehicleObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/vehicles').VehicleObject>>} props.setDetails 
 */
const EditVehicle = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Vehicles', href: '/app/vehicles' },
        { title: details.reg_no, href: `/app/vehicles/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/vehicles/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.title}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <VehicleForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewVehicle, EditVehicle };