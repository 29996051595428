import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { error as errorAlert, successAlert } from './../../components/toastr';
import { SubmitButton, CancelButton } from './../../components/btns';
import { payrollTypes } from '../../assets/constants';
import { PageLoading } from '../../components/loading';
import { Select } from '../../components/select';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import cur from '../../components/currency';
import { createPayrollPayment, getAllStaff, updatePayrollPayment } from '../../resources/api/payroll';

const { Control, Control: { Feedback }, Label } = Form;

const wokerObject = {
    staff_id: "",
    duration: 0,
    amount: 0,
    overtime_hrs: 0
}

const calculateWage = (payRollType, rate, duration = 0, OTRate = 0, OTHrs = 0) => {

    if (!payRollType) return 0;

    const { definedRates, requiresDuration, doOvertime } = payrollTypes[payRollType];

    if (!definedRates) return 0;

    if (!requiresDuration) return parseFloat(rate);

    if (!duration) return 0;

    let total = parseFloat(duration) * parseFloat(rate);

    if (!doOvertime || !OTHrs || !OTRate) return total;

    return total + (parseFloat(OTHrs) * parseFloat(OTRate));
}


/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import("../../resources/api/payroll").StaffPaymentObject} props.details
 */
const PaymentForm = ({ details: labourObject, action, onSubmit }) => {

    /**  */

    const [details, setDetails] = useState(labourObject);
    const [workers, setWorkers] = useState(labourObject.workers || []);
    const [staff, setStaff] = useState([]);

    const [deleted_workers, setDeletedWorkers] = useState([]);

    const [loaded, setLoaded] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    const workers_list = useMemo(() => {
        const _workers = workers.map(i => i.staff_id);
        return staff.filter(c => _workers.indexOf(c.id) === -1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workers.length, staff.length])

    /** @type {"manual"|"autoNoOT"|"autoOT"} */
    const autoCalc = useMemo(() => {

        if (!details.payroll_type) return "manual";

        if (!payrollTypes[details.payroll_type].definedRates) return "manual";

        if (payrollTypes[details.payroll_type].requiresDuration) {
            return payrollTypes[details.payroll_type].doOvertime ? "autoOT" : "autoNoOT";
        }

    }, [details.payroll_type])

    const addWorkers = ids => {


        const _workers = staff
            .filter(w => ids.indexOf(w.id) !== -1)
            .map(w => ({
                ...wokerObject,
                rate: w.pay_rate,
                amount: calculateWage(
                    details.payroll_type,
                    w.pay_rate
                ),
                staff_id: w.id,
                isNew: 1,
                display_name: w.display_name
            })
            );


        setWorkers([...workers.concat(_workers)])
    }

    const updateWorker = (field, value, idx) => {

        const _workers = [...workers];
        let _w = _workers[idx];
        _w = { ..._w, [field]: value, hasChanged: 1 };

        if (autoCalc !== "manual") {
            _w.amount = calculateWage(
                details.payroll_type,
                _w.rate,
                _w.duration,
                details.overtime_rate,
                _w.overtime_hrs
            );
        }

        _workers.splice(idx, 1, _w);
        setWorkers(_workers);
    }

    const deleteWorker = idx => {

        const _workers = [...workers],
            [_w] = _workers.splice(idx, 1);

        if ('id' in _w) {
            setDeletedWorkers(w => w.concat(_w.id));
        }
        setWorkers(_workers);
    }

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }


        if (workers.length < 1) {
            errorAlert("You must select at a least staff member for this event.", "Form Errors");
            return;
        }


        setValidated(false);
        setSubmitting(true);

        let promise;

        if (action === "create") {
            promise = createPayrollPayment({
                ...details,
                workers: workers.map(w => ({
                    ...w,
                    expense_type: payrollTypes[details.payroll_type].expenseCategory
                }))
            });
        }
        else {
            promise = updatePayrollPayment({
                ...details,
                new_workers: workers
                    .filter(p => !!p.isNew)
                    .map(w => ({
                        ...w, expense_type: payrollTypes[details.payroll_type].expenseCategory
                    })),
                updated_workers: workers.filter(p => (!p.isNew && !!p.hasChanged)),
                deleted_workers
            }, details.id);
        }

        promise
            .then(({ payment, message }) => {
                successAlert(message);
                onSubmit(payment);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    useEffect(() => {
        getAllStaff(true)
            .then(({ staff }) => {
                setStaff(
                    staff.map(c => ({ ...c, title: c.display_name, description: `${c.email} ${c.telephone}` }))
                );

                setWorkers(workers => workers.map(w =>
                ({
                    ...w,
                    rate: staff.find(s => s.id === w.staff_id).pay_rate,
                    amount: calculateWage(
                        details.payroll_type,
                        staff.find(s => s.id === w.staff_id).pay_rate,
                        w.duration,
                        details.overtime_rate,
                        w.overtime_hrs
                    )
                })));
            })
            .catch(e => errorAlert(e))
            .finally(() => setLoaded(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        const _workers = [...workers].map(_w => ({
            ..._w,
            amount: autoCalc !== "manual" ?
                calculateWage(
                    details.payroll_type,
                    _w.rate,
                    _w.duration,
                    details.overtime_rate,
                    _w.overtime_hrs
                ) :
                _w.amount
        }))

        setWorkers(_workers);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.overtime_rate, autoCalc]);


    if (!loaded) return <PageLoading>Loading staff...</PageLoading>;

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Payroll Type</Label>
                    <Form.Select
                        value={details.payroll_type}
                        onChange={e => setDetails({ ...details, payroll_type: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {Object.keys(payrollTypes)
                            .filter(t => !!payrollTypes[t].isManual)
                            .map(t => <option value={t} key={t}>{payrollTypes[t].title}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Please choose an action
                    </Feedback>
                </Col>

                {(autoCalc === "autoOT") &&
                    <Col sm={6} className="my-1">
                        <Label className="form-field-title">Overtime Rate</Label>
                        <Control
                            value={details.overtime_rate}
                            type="number"
                            step={1}
                            onChange={e => setDetails({ ...details, overtime_rate: e.currentTarget.value })}
                            required={true}
                        />
                        <Feedback type="invalid">
                            A base rate must be provided
                        </Feedback>
                    </Col>
                }

            </Row>


            <h4 className="form-section-label">Workers</h4>
            {workers.length === 0 &&
                <Form.Text as={"div"} className="my-2 lead">
                    No staff added yet. Add a staff to continue.
                </Form.Text>}

            <Row className="d-none d-sm-flex">
                <Col sm={6}>
                    <Label className="form-field-title">Name</Label>
                </Col>
                {(["autoNoOT", "autoOT"].indexOf(autoCalc) !== -1) &&
                    <Col sm={2}>
                        <Label className="form-field-title">Duration</Label>
                    </Col>
                }

                {(autoCalc === "autoOT") &&
                    <Col sm={2}>
                        <Label className="form-field-title">Overtime Hrs</Label>
                    </Col>
                }

                <Col sm={(autoCalc === "autoOT") ? 2 : ((autoCalc === "autoNoOT") ? 4 : 6)}>
                    <Label className="form-field-title">Amount</Label>
                </Col>
            </Row>

            {
                workers.map((w, idx) => (
                    <div key={idx} className="my-4 my-sm-0 g-2 border-start border-primary ps-1" style={{ borderLeftWidth: '3px' }}>
                        <Row key={idx} className="g-2">
                            <Col sm={6}>
                                <Label className="form-field-title d-sm-none">Name</Label>
                                <Form.Control
                                    value={w.display_name}
                                    readOnly
                                    size="sm"
                                />
                            </Col>

                            {(["autoNoOT", "autoOT"].indexOf(autoCalc) !== -1) &&
                                <Col sm={2}>
                                    <Label className="form-field-title d-sm-none">Duration</Label>
                                    <Form.Control
                                        value={w.duration}
                                        onChange={e => updateWorker("duration", e.currentTarget.value, idx)}
                                        placeholder="Days"
                                        size="sm"
                                        type="number"
                                        step={0.5}
                                        min={0}
                                        required
                                    />
                                    <Feedback type="invalid">
                                        Must be in multiples of 0.5
                                    </Feedback>

                                </Col>
                            }

                            {(autoCalc === "autoOT") &&
                                <Col sm={2}>
                                    <Label className="form-field-title d-sm-none">Overtime Hrs</Label>
                                    <Form.Control
                                        value={w.overtime_hrs}
                                        onChange={e => updateWorker("overtime_hrs", e.currentTarget.value, idx)}
                                        placeholder="hours"
                                        size="sm"
                                        type="number"
                                        step={0.5}
                                        min={0}
                                        required
                                    />
                                    <Feedback type="invalid">
                                        Must be in multiples of 0.5
                                    </Feedback>

                                </Col>
                            }

                            <Col sm={(autoCalc === "autoOT") ? 2 : ((autoCalc === "autoNoOT") ? 4 : 6)}>
                                <Label className="form-field-title d-sm-none">Amount</Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        value={autoCalc !== "manual" ? cur(w.amount, 0).format() : w.amount}
                                        readOnly={autoCalc !== "manual"}
                                        type={autoCalc !== "manual" ? "text" : "number"}
                                        step={100}
                                        size="sm"
                                        onChange={e => updateWorker("amount", e.currentTarget.value, idx)}
                                        required={autoCalc === "manual"}
                                    />
                                    <Button
                                        variant="outline-danger"
                                        className="border-0"
                                        onClick={() => deleteWorker(idx)}
                                    >
                                        <i className="fas fa-times" />
                                    </Button>

                                    <Control.Feedback type="invalid">
                                        Amount must be provided, must be in multiples of 100
                                    </Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                ))
            }

            <Row className="mt-4 flex-column-reverse flex-sm-row">

                <Col sm={6}>
                    <Select
                        className='rounded-pill'
                        size="sm"
                        maxItems={12}
                        onSelect={addWorkers}
                        items={workers_list}
                    >
                        <i className="fas fa-plus-circle me-2" />Add Staff
                    </Select>
                </Col>
                <Col sm={6} className="fw-normal text-end" style={{ fontSize: "2rem" }}>
                    {cur(workers.reduce((p, c) => (p + (parseFloat(c.amount) || 0)), 0)).format()}
                </Col>

            </Row>


            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Payment" : "Submit Payment"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form >
    )
}


export default PaymentForm;