import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import ViewClientVendors from './view-clientvendors';
import ClientVendorDetails from './clientvendor-details';

const ClientVendors = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 3) {
        return <ErrorLoading>You do not have the permission to view/add/edit client-vendors.</ErrorLoading>
    }


    return (
        <Routes>
            <Route path="/:clientvendorid/*" element={<ClientVendorDetails />} />
            <Route path="/" element={<ViewClientVendors />} />
        </Routes>
    )
};

export default ClientVendors;