import { Form, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { DateSelector } from '../../components/datetimepicker';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import moment from 'moment';
import { SubmitButton, CancelButton } from '../../components/btns';
import { RequisitionItems } from '../../components/finances';
// import File from '../../components/files';
import SelectInput from '../../components/select';
import { useEffect } from 'react';
import { getClients } from '../../resources/api/clients';
// import { PageLoading } from '../../components/loading';
import { getEvents } from '../../resources/api/events';
import { createRequisition, updateRequisition } from '../../resources/api/requisitions';


const { Control, Control: { Feedback }, Label, Text } = Form;


/**
 * 
 * @param {Object} props
 * @param {"create"|"edit"} props.action 
 * @param {import('../../resources/api/requisitions').RequisitionObject} props.details 
 * @param {(details: import('../../resources/api/requisitions').RequisitionObject) => void} props.onSubmit 
 */
const RequisitionForm = ({ action = "create", details: requisitionObject, onSubmit }) => {

    const [details, setDetails] = useState(requisitionObject);
    const [items, setItems] = useState(requisitionObject.items || []);
    const [deleted_items, setDeletedItems] = useState([]);

    // const [files, setFiles] = useState([]);
    const [clients, setClients] = useState([]);
    const [events, setEvents] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);


    useEffect(() => {

        infoAlert("Loading the clientele list...");

        getClients()
            .then(({ clients }) => {
                setClients(
                    clients.map(c => ({ ...c, description: `${c.email} ${c.telephone}` }))
                );

                successAlert("Successfully loaded the clientele list");
            })
            .catch(e => errorAlert(e))
    }, []);

    useEffect(() => {

        // setDetails(d => ({ ...d, isBillable: false, event_id: "", markup: "" }));
        setEvents([]);

        if (!details.client_id) return;

        infoAlert("Loading events for this client");

        getEvents(true, { client_id: details.client_id })
            .then(({ events }) => {

                setEvents(events.map(e => ({
                    ...e,
                    title: e.location,
                    description: `${e.reference} | ` + moment(e.event_date).format("DD MMMM YYYY")
                })));
                successAlert("Events have been loaded");
            })
            .catch(e => errorAlert(`Could not get client events. Reason: ${e}`))

    }, [details.client_id])




    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        const data = {
            ...details,
        }

        let promise;

        if (action === "create") {
            data.items = items;
            promise = createRequisition(data);
        } else {
            promise = updateRequisition({
                ...data,
                new_items: items.filter(i => i.isNew),
                updated_items: items.filter(i => (!!i.id && i.hasChanged)),
                deleted_items
            }, data.id)
        }

        promise
            .then(({ requisition, message }) => {
                successAlert(message);
                onSubmit(requisition);
            })
            .catch(e => {
                setSubmitting(false);
                errorAlert(e);
            })

    }


    return (

        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label mt-5">Details</h4>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Date</Label>
                    <DateSelector
                        minDate={moment().add(-1, 'months').format('YYYY-MM-DD')}
                        maxDate={0}
                        value={details.requisition_date}
                        onChange={val => setDetails(d => ({ ...d, requisition_date: val }))}
                        required
                    />
                    <Feedback type="invalid">
                        You must select a requisition date.
                    </Feedback>
                </Col>
            </Row>

            <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Description</Label>
                    <Control 
                        as="textarea"
                        rows={2}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        value={details.description || ""}
                        onChange={e => setDetails({ ...details, description: e.currentTarget.value })}
                        required
                        maxLength={200}
                    />
                    <Feedback type="invalid">
                        Describe what the expense is about inless than 200 characters. No characters: {details.description.length}
                    </Feedback>
                </Col>
            </Row>



            <h4 className="form-section-label mt-5">Items</h4>

            <RequisitionItems
                requisitionitems={{ items, setItems }}
                deleteditems={{ deleted_items, setDeletedItems }}
            />

            <h4 className="form-section-label mt-5">Other Details</h4>
            <Text muted>
                Add the requisition to a client.
            </Text>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Client</Label>
                    <SelectInput
                        value={[details.client_id]}
                        onChange={([id]) => setDetails(d => ({ ...d, client_id: id || "" }))}
                        options={clients}

                        maxItems={1}
                        errorText="The client must exist in the existing lists of clients."
                    />
                </Col>

                {!!details.client_id &&
                    < Col sm={6} className="my-1">
                        <Label className="form-field-title">Event</Label>
                        <SelectInput
                            value={[details.event_id]}
                            onChange={([id]) => setDetails(d => ({ ...d, event_id: id || "" }))}
                            options={events}
                            required
                            maxItems={1}
                            errorText="The client must exist in the existing lists of clients."
                            sortBy="event_date"
                        />
                    </Col>
                }
            </Row>

            {/* <Row>
                <Col sm={6} className="my-2">
                    <Label className="form-field-title">Receipt</Label>
                    <File maxFiles={1} onChange={files => setFiles(files)} value={files} />
                </Col>
            </Row> */}

            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        Submit Requisition
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>

        </Form >
    )
}


export default RequisitionForm;