
import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { AppUserContext } from '../../App';
import Nav from '../../components/breadcrumb';
// import { ErrorLoading } from '../../components/loading';
import RequisitionForm from './requisition-form';

const requisition = {
    category: '',
    requisition_date: '',
    description: '',
    client_id: '',
    event_id: '',
    receipt_file_id: '',
    markup: "",
    user_reference: "", 
    vendor: "",
    items: [],
}

const NewRequisition = () => {

    const nav_items = [
        { title: 'Requisitions', href: '/app/requisitions' },
        { title: 'New Requisition' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        navigate(`/app/requisitions/${details.id}`);
    }

    // const { profile } = useContext(AppUserContext);

    // if (profile.roles.indexOf('finance') === -1) {
    //     return <ErrorLoading>You do not have permission to create an expense.</ErrorLoading>;
    // }



    return (
        <>
            <Nav items={nav_items} />

            <RequisitionForm
                details={requisition}
                action="create"
                onSubmit={handleSubmit}
            />
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/requisitions').RequisitionObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/requisitions').RequisitionObject>>} props.setDetails 
 */
const EditRequisition = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Requisitions', href: '/app/requisitions' },
        { title: details.reference, href: `/app/requisitions/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/requisitions/${details.id}`);
    }

    // const { profile } = useContext(AppUserContext);

    // if (profile.roles.indexOf('finance') === -1) {
    //     return <ErrorLoading>You do not have permission to update an expense.</ErrorLoading>;
    // }


    return (

        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <RequisitionForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { EditRequisition };


export default NewRequisition;