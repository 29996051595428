import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import New from './bill-manipulation';
import View from './view-bill';
import ViewAll from './view-bills';

const Bills = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to access expenses.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="new" element={<New />} />
            <Route path=":billid/*" element={<View />} />
            <Route path="/" element={<ViewAll />} />
        </Routes>
    )
};

export default Bills;