import $ from 'jquery';


export const BASEURL = process.env.REACT_APP_BASEURL;
export const APIURL = process.env.REACT_APP_APIURL;
export const WEBSITEURL = process.env.REACT_APP_WEBURL;


/**
 * 
 * @param {{[any:string]: any}} jqXHR 
 */
const getError = (jqXHR) => {
    var contentType = jqXHR.getResponseHeader("Content-Type"),
        content = jqXHR.responseText || jqXHR.statusText,
        r;

    if (!content) {
        return 'An unknown error occured.';
    }

    if (contentType === 'application/json') {
        try {
            r = JSON.parse(content);
        } catch (e) {
            r = { message: content };
        }

        return r.message;

    } else {

        return content;
    }
}


/**
 * Make a network request 
 * @typedef {{
 * url: string,
 * method: "POST" | "GET" | "DELETE" | "PUT", 
 * dataType: "xml" | "html" | "script" | "json",
 * data: {[x:string]: any},
 * headers: {[x:string]: any}
 * crossDomain: boolean
 * }} AjaxSettings - Request settings.
 */

/**
 * 
 * @param {AjaxSettings} d 
 * @returns 
 */
export const fetchData = (d) => {

    let { method, data, ...others } = d;
    method = method ? (['PUT', 'POST', 'GET', 'DELETE'].indexOf(method) === -1 ? 'GET' : method) : 'GET';

    if ((method !== "GET") && !!data) data = JSON.stringify(data);

    return new Promise((resolve, reject) => {
        $.ajax({
            method: method,
            dataType: 'json',
            contentType: 'appplication/json',
            data,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            ...others
        })
            .done(({ code, ...others }) => {
                if (code !== 0) {
                    return reject(others.message);
                }

                return resolve(others);
            })
            .fail(jq => reject(getError(jq)));
    })
}


/** @param {AjaxSettings} d */
export const postData = (d) => fetchData({ ...d, method: "POST" });

/** @param {AjaxSettings} d */
export const deleleData = d => fetchData({ ...d, method: "DELETE" });

/** @param {AjaxSettings} d */
export const getData = (d) => fetchData({ ...d, method: "GET" });


export { deleleData as deleteData };
export default fetchData;

