import { Form, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { DateSelector } from '../../components/datetimepicker';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import moment from 'moment';
import { SubmitButton, CancelButton } from '../../components/btns';
import { BillItems } from '../../components/finances';
import { createBill, updateBill } from '../../resources/api/bills';
import SelectInput from '../../components/select';
import { useEffect } from 'react';
import { getClients } from '../../resources/api/clients';
import FileInput from '../../components/files';
import { getVendors } from '../../resources/api/vendors';
import { getOrders } from '../../resources/api/orders';
import cur from '../../components/currency';
import { getEvents } from '../../resources/api/events';


const { Control, Control: { Feedback }, Label, Text } = Form;


/**
 * 
 * @param {Object} props
 * @param {"create"|"edit"} props.action 
 * @param {import('../../resources/api/bills').BillObject} props.details 
 * @param {(details: import('../../resources/api/bills').BillObject) => void} props.onSubmit 
 */
const BillForm = ({ action = "create", details: billObject, onSubmit }) => {

    const [details, setDetails] = useState(billObject);
    const [items, setItems] = useState(billObject.items || []);
    const [deleted_items, setDeletedItems] = useState([]);

    const [clients, setClients] = useState([]);
    const [events, setEvents] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [orders, setOrders] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);


    useEffect(() => {

        infoAlert("Loading the vendors & clients lists...");

        getClients()
            .then(({ clients }) => {
                setClients(
                    clients.map(c => ({ ...c, description: c.address }))
                );
            })
            .catch(errorAlert)

        getVendors()
            .then(({ vendors }) => {
                setVendors(vendors.map(c => ({ ...c, description: c.address })));
            })
            .catch(e => errorAlert(e))
    }, []);

    useEffect(() => {

        setOrders([]);

        if (!details.vendor_id) return;

        infoAlert('Getting purchase orders for this vendor...');

        getOrders(1, { vendor_id: details.vendor_id, status: ["sent", "open"] })
            .then(({ orders }) => {
                setOrders(orders.map(o => ({
                    ...o,
                    title: o.order_no,
                    description: `${moment(o.order_date).format("DD MMMM YYYY")} | ${cur(o.total_amount, 0).format()}`
                })))

            })
            .catch(e => errorAlert(`Could not load vendor orders. Error: ${e}`))

    }, [details.vendor_id])

    useEffect(() => {

        setEvents([]);

        if (!details.client_id) return;

        infoAlert("Loading jobs for this client");

        getEvents(true, { client_id: details.client_id })
            .then(({ events }) => {

                setEvents(events.map(e => ({
                    ...e,
                    title: `${e.location} | ${moment(e.event_date).format("DD MMMM YYYY")}`,
                    description: `${e.reference}` 
                })));
                successAlert("Jobs have been loaded");
            })
            .catch(e => errorAlert(`Could not get client jobs. Reason: ${e}`))

    }, [details.client_id])






    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        const data = {
            ...details,
            isTaxInclusive: details.isTaxInclusive ? 1 : 0,
            tax_percent: details.tax_percent || 0
        }

        let promise;

        if (action === "create") {
            data.items = items;
            promise = createBill(data);
        } else {
            promise = updateBill({
                ...data,
                new_items: items.filter(i => i.isNew),
                updated_items: items.filter(i => (!!i.id && i.hasChanged)),
                deleted_items
            }, data.id)
        }

        promise
            .then(({ bill, message }) => {
                successAlert(message);
                onSubmit(bill);
            })
            .catch(e => {
                setSubmitting(false);
                errorAlert(e);
            })

    }


    return (

        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label mt-5">Details</h4>

            <Row>
                <Col sm={6} className='my-1'>
                    <Label className="form-field-title">Vendor</Label>
                    {(!billObject.vendor) ?
                        <SelectInput
                            value={[details.vendor_id]}
                            onChange={([vid]) => setDetails(d => ({ ...d, vendor_id: vid || "" }))}
                            options={vendors}
                            placeholder='-'
                            errorText='Required'
                            maxItems={1}
                            required

                        />
                        :
                        <Control value={billObject.vendor.title} readOnly />
                    }
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Vendor Reference</Label>
                    <Control
                        value={details.vendor_reference}
                        onChange={e => setDetails({ ...details, vendor_reference: e.currentTarget.value })}
                        required
                    />
                </Col>
                <Col sm={6} className='my-1'>
                    <Label className="form-field-title">Purchase Order</Label>

                    {(!billObject.order) ?
                        <SelectInput
                            value={[details.purchase_order_id]}
                            onChange={([vid]) => setDetails(d => ({ ...d, purchase_order_id: vid || "" }))}
                            options={orders}
                            placeholder='-'
                            errorText='Required'
                            maxItems={1}
                        />
                        :
                        <Control value={billObject.order.order_no} readOnly />
                    }

                </Col>
            </Row>
            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Bill Date</Label>
                    <DateSelector
                        minDate={moment().add(-1, 'months').format('YYYY-MM-DD')}
                        maxDate={0}
                        value={details.bill_date}
                        onChange={val => setDetails(d => ({ ...d, bill_date: val }))}
                        required
                    />
                    <Feedback type="invalid">
                        You must select an bill date.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Due Date</Label>
                    <DateSelector
                        minDate={details.bill_date || 0}
                        value={details.due_date}
                        onChange={val => setDetails(d => ({ ...d, due_date: val }))}
                        required
                    />
                    <Feedback type="invalid">
                        You must select an bill date.
                    </Feedback>
                </Col>
            </Row>


            <h4 className="form-section-label mt-5">Items</h4>
            <Form.Check
                className=""
                type="switch"
                id="custom-switch"
                checked={details.isTaxInclusive}
                onChange={() => setDetails(d => ({ ...d, isTaxInclusive: !d.isTaxInclusive }))}
                label={details.isTaxInclusive ? "Prices are tax inclusive." : "Prices are not tax inclusive."}
            />

            <BillItems
                expenseitems={{ items, setItems }}
                deleteditems={{ deleted_items, setDeletedItems }}
                isTaxInclusive={details.isTaxInclusive}
            />



            <h4 className="form-section-label mt-5">Other Details</h4>
            <Text muted>
                Add the bill to a client and even further a job to track particular job bills.
            </Text>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Client</Label>
                    <SelectInput
                        value={[details.client_id]}
                        onChange={([id]) => setDetails(d => ({ ...d, client_id: id || "" }))}
                        options={clients}
                        placeholder="-"
                        maxItems={1}
                        errorText="The client must exist in the existing lists of clients."
                    />
                </Col>

                {!!details.client_id &&
                    <Col sm={6} className="my-1">
                        <Label className="form-field-title">Event</Label>
                        <SelectInput
                            value={[details.event_id]}
                            placeholder="-"
                            onChange={([id]) => setDetails(d => ({ ...d, event_id: id || "" }))}
                            options={events}
                            required
                            maxItems={1}
                            errorText="The client must exist in the existing lists of clients."
                            sortBy="event_date"
                        />
                    </Col>
                }
            </Row>


            <Row>
                <Col sm={6} className="my-2">
                    <Label className="form-field-title">Vendor Invoice</Label>
                    <FileInput
                        placeholder='-'
                        maxFiles={1}
                        onChange={([file]) => setDetails(d => ({ ...d, file_id: file || "" }))}
                        value={[details.file_id]}
                        required={(!details.purchase_order_id) && (action === "create")}
                    />
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        Submit
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>

        </Form >
    )
}


export default BillForm;