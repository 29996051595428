import { Link, useNavigate } from 'react-router-dom';


/**
 * 
 * @param {{
 *  title: string
 *  href: string|null
 * }} param0 
 */
const NavItem = ({ title, href = null }) => {

    if (href) {
        return (
            <li className="breadcrumb-item">
                <Link to={href}>{title}</Link>
            </li>
        )

    } else {
        return (
            <li className="breadcrumb-item active" aria-current="page">
                {title}
            </li>
        )
    }
}

/**
 * 
 * @param {{items: {title: string, href: string}[], module: "app"}} props 
 */
const BreadCrumbNav = ({ items, module = 'app' }) => {

    const navigate = useNavigate();
    const goBack = (e) => { e.preventDefault(); navigate(-1) };

    return (
        <nav aria-label="breadcrumb" className="breadcrumb-container">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <a onClick={goBack} href='#.' className='pe-2'><i className="fas fa-circle-left"></i></a>
                    <Link to={`/${module}`}><i className="fas fa-home"></i></Link>
                </li>
                {items.map(({ title, href }, i) => <NavItem key={i} title={title} href={href || null} />)}
            </ol>
        </nav>
    )
}

export default BreadCrumbNav;