import Nav from '../../components/breadcrumb';
// import PageHeader, { Title } from './../../components/pageheader';
import PaymentForm from "./payment-form";

import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { PageLoading } from '../../components/loading';

const paymentObject = {
    id: "", event_id: "", reference: "", amount: "", payee: "", payment_date: "", items: [], account_id: "", method: ""
}

const NewPayment = () => {

    const nav_items = [
        { title: 'Payments', href: '/app/payments' },
        { title: 'New Payment' }
    ];

    const [searchParams] = useSearchParams();
    const [details, setDetails] = useState(paymentObject);
    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        let eventid = searchParams.get('event');
        const _details = { ...details, event_id: eventid || "" };

        setDetails(_details);
        setLoaded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (details) => {
        navigate(`/app/payments/${details.id}`);
    }


    if (!loaded) return <PageLoading />;

    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <PaymentForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/payments').PaymentObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/payments').PaymentObject>>} props.setDetails 
 */
const EditPayment = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Payments', href: '/app/payments' },
        { title: details.reference, href: `/app/payments/${details.id}` },
        { title: 'Edit Details' }
    ];


    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/payments/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            {/* <PageHeader maxWidth="800">
                <Title>{details.payment_no}</Title>
            </PageHeader> */}

            <div className="max-800 p-2">
                <PaymentForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewPayment, EditPayment };