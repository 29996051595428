import { useContext, useEffect, useState } from "react";
import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { ErrorLoading, PageLoading } from "../../components/loading";
import { UCWords, sortFunction } from "../../components/resources";
import { Table } from "react-bootstrap";
import moment from "moment";
import cur from "../../components/currency";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { deleteClient, getClient, getClientStatement } from "../../resources/api/clientvendors";
import { ConfirmAction } from "../../components/prompt";
import { AppUserContext } from "../../App";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/clientvendors").ClientVendorObject} props.details
 */
const ViewStatement = ({ details }) => {

    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [totals, setTotals] = useState([]);

    const { profile } = useContext(AppUserContext);
    const navigate = useNavigate();

    useEffect(() => {

        setLoaded(false);
        setError();

        getClientStatement(details.id)
            .then(({ statement: { bills, payments, expenses, bill_payments, events } }) => {
                const _transactions = [
                    ...bills.map(i => ({
                        id: i.id,
                        type: 'bills',
                        reference: i.reference,
                        description: i.vendor_reference,
                        date: i.bill_date,
                        credit: i.total_amount,
                        debit: 0
                    })),
                    ...bill_payments.map(p => ({
                        id: p.id,
                        type: 'bill-payments',
                        reference: p.reference,
                        description: p.receipt_no,
                        date: p.payment_date,
                        debit: p.total_amount,
                        credit: 0
                    })),
                    ...expenses.map(p => ({
                        id: p.id,
                        type: 'expenses',
                        reference: p.reference,
                        description: p.receipt_no,
                        date: p.payment_date,
                        credit: p.total_amount,
                        debit: p.total_amount
                    })),
                    ...events.map(i => ({
                        id: i.id,
                        type: 'events',
                        reference: i.reference,
                        description: `${i.location} | ${i.category}`,
                        date: i.event_date,
                        debit: i.amount,
                        credit: 0
                    })),
                    ...payments.map(p => ({
                        id: p.id,
                        type: 'payments',
                        reference: p.reference,
                        description: p.method,
                        date: p.payment_date,
                        credit: p.total_amount,
                        debit: 0
                    }))
                ].sort((a, b) => sortFunction(a, b, 'date', "asc"));

                let running_balance = 0;

                setTransactions(_transactions.map(t => ({ ...t, running_balance: (running_balance += (t.debit - t.credit)) })).reverse())

                setTotals(({
                    credit: _transactions.reduce((p, c) => c.credit + p, 0),
                    debit: _transactions.reduce((p, c) => c.debit + p, 0),
                }))
            })
            .catch(setError)
            .finally(() => setLoaded(true))

    }, [details.id])

    const nav_items = [
        { title: 'Client Vendors', href: "/app/client-vendors" },
        { title: details.title }
    ]
    const handleDelete = () => {

        infoAlert('Deleting Account, please hold...');

        deleteClient(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate("/app/client-vendors");
            })
            .catch(e => errorAlert(e));
    }


    if (!isLoaded) return <PageLoading>Loading statement details...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.title} - Statement</Title>
                <ActionBar> {(
                    (profile.permission_level === 1)
                ) &&
                    <ButtonToolbar>
                        <ConfirmAction
                            as={Btn}
                            title="Delete Account"
                            confirmationText="Are you sure you want to delete this client-vendor? "
                            handleAction={handleDelete}
                        >
                            <i className="fas fa-trash-alt" />
                        </ConfirmAction>
                    </ButtonToolbar>

                }
                </ActionBar>
            </PageHeader>

            <div className="max-1000">

                <Table borderless size="sm" hover>
                    <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "35%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Reference</th>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map(t => (
                            <tr key={t.id}>
                                <td>{moment(t.date).format('DD MMM YYYY')}</td>
                                <td><Link to={`/app/${t.type}/${t.id}`}>{t.reference}</Link></td>
                                <td>{UCWords(t.type.replace(/-/g, " "))}</td>
                                <td>{t.description}</td>
                                <td>{!!t.debit && <>{cur(t.debit, 0).format()}</>}</td>
                                <td style={{ color: "var(--bs-danger)" }}>{!!t.credit && <>{cur(t.credit, 0).format()}</>}</td>
                                <td style={{ color: (t.running_balance < 0) ? "var(--bs-danger)" : "inherit" }}>{cur(t.running_balance, 0).format()}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={4} />
                            <th>{cur(totals.debit, 0).format()}</th>
                            <th>{cur(totals.credit, 0).format()}</th>
                            {/* <th /> */}
                            <th>{cur(totals.debit - totals.credit, 0).format()}</th>
                        </tr>
                    </tfoot>
                </Table>

            </div>

        </>
    )

}



const ClientVendorDetails = () => {

    const { clientvendorid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();


    useEffect(() => {

        setLoaded(false);
        setError(null);

        getClient(clientvendorid, ['client', 'vendor'])
            .then(({ client_vendor }) => {
                setDetails(client_vendor);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [clientvendorid])

    //load events details

    if (!isLoaded) return <PageLoading />;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Routes>
            <Route path="/" element={<ViewStatement details={details} />} />
        </Routes>
    )

}

export default ClientVendorDetails;
