
import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { AppUserContext } from '../../App';
import Nav from '../../components/breadcrumb';
import MaintenanceForm from './maintenance-form';

const requisition = {
    vehicle_id: '',
    maintenance_date: '',
    type: '',
    actions: '',
    observations: '',
    remarks: '',
    garage: "",
    items: [],
}

const NewMaintenance = () => {

    const nav_items = [
        { title: 'Maintenance', href: '/app/maintenance' },
        { title: 'New Record' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        navigate(`/app/maintenance/${details.id}`);
    }

    // const { profile } = useContext(AppUserContext);

    // if (profile.roles.indexOf('finance') === -1) {
    //     return <ErrorLoading>You do not have permission to create an expense.</ErrorLoading>;
    // }



    return (
        <>
            <Nav items={nav_items} />

            <MaintenanceForm
                details={requisition}
                action="create"
                onSubmit={handleSubmit}
            />
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/maintenance').MaintenanceObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/maintenance').MaintenanceObject>>} props.setDetails 
 */
const EditMaintenance = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Maintenance', href: '/app/maintenance' },
        { title: details.reference, href: `/app/maintenance/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/maintenance/${details.id}`);
    }

    // const { profile } = useContext(AppUserContext);

    // if (profile.roles.indexOf('finance') === -1) {
    //     return <ErrorLoading>You do not have permission to update an expense.</ErrorLoading>;
    // }


    return (

        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <MaintenanceForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { EditMaintenance };


export default NewMaintenance;