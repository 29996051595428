import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { error as errorAlert, successAlert } from './../../components/toastr';
import { SubmitButton, CancelButton } from './../../components/btns';
import { DateSelector } from '../../components/datetimepicker';
import { UCWords } from '../../components/resources';
import { DimensionedProducts, EventCategories, EventClassifications, EventColours, EventProducts } from '../../assets/constants';
import { PageLoading } from '../../components/loading';
import { getClients } from '../../resources/api/clients';
import SelectInput from '../../components/select';
import { Link, useNavigate } from 'react-router-dom';
import { createEvent, updateEvent } from '../../resources/api/events';

const { Control, Control: { Feedback }, Label } = Form;
const productObject = { product: "", qty: "1", unit_price: "0", dimensions: "" , no_days: "1"};

/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/events').EventObject} props.details
 * @param {(details: import('../../resources/api/events').EventObject) => void} props.onSubmit
 */
const EventForm = ({ details: eventObject, action, onSubmit }) => {


    const [details, setDetails] = useState(eventObject);
    const [prods, setProds] = useState(eventObject.products || []);

    const [deleted_products, setDeletedProducts] = useState([]);

    const [clients, setClients] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    const updateProduct = (field, value, idx) => {

        const _prods = [...prods];
        let _prod = _prods[idx];
        _prod = { ..._prod, [field]: value, hasChanged: 1 };
        _prods.splice(idx, 1, _prod);
        setProds(_prods);
    }

    const deleteProduct = idx => {

        const _prods = [...prods],
            [_prod] = _prods.splice(idx, 1);

        if ('id' in _prod) {
            setDeletedProducts(p => p.concat(_prod.id));
        }
        setProds(_prods);
    }

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        if (prods.length < 1) {
            errorAlert("You must select at least one product for this event.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let promise;

        if (action === "create") {
            promise = createEvent({ ...details, products: prods });
        } else {
            promise = updateEvent({
                ...details,
                new_products: prods.filter(p => !!p.isNew),
                updated_products: prods.filter(p => (!p.isNew && !!p.hasChanged)),
                deleted_products
            }, details.id);
        }

        promise
            .then(({ event, message }) => {
                successAlert(message);
                onSubmit(event);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    useEffect(() => {
        getClients()
            .then(({ clients }) => {
                setClients(
                    clients.map(c => ({ ...c, description: `${c.email} ${c.telephone}` }))
                );
            })
            .catch(e => errorAlert(e))
            .finally(() => setLoaded(true));
    }, []);


    if (!loaded) return <PageLoading>Loading clients...</PageLoading>;

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Client</Label>
                    <SelectInput
                        value={[details.client_id]}
                        onChange={([id]) => setDetails(d => ({ ...d, client_id: id || "" }))}
                        options={clients}
                        required
                        maxItems={1}
                        errorText="The client must exist in the existing lists of clients."
                    />
                    <Form.Text muted style={{ lineHeight: 1, display: "block" }}>
                        The client must exist in the existing lists of clients.
                        <Link to="/app/clients/new?action=create_event">Click here</Link> to add a new client.
                    </Form.Text>
                </Col>
            </Row>
            <Row className="g-2">
                <Col sm={3} className="my-1">
                    <Label className="form-field-title">Event Date</Label>
                    <DateSelector
                        value={details.event_date}
                        onChange={date =>
                            setDetails(d => ({
                                ...d,
                                event_date: date,
                                end_date: (!d.end_date || (d.end_date < date)) ? date : d.end_date
                            }))}
                        // placeholder="when is the event taking place?"
                        required={true}
                        minDate="2021-01-01"

                    />
                    <Feedback type="invalid">
                        A valid date must be provided.
                    </Feedback>
                </Col>
                <Col sm={3} className="my-1">
                    <Label className="form-field-title">End Date</Label>
                    <DateSelector
                        value={details.end_date}
                        onChange={date =>
                            setDetails(d => ({
                                ...d,
                                end_date: date
                            }))}
                        // placeholder="when is the event taking place?"
                        required={true}
                        minDate={details.event_date}

                    />
                    <Feedback type="invalid">
                        A valid date must be provided.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Event Location</Label>
                    <Control
                        value={details.location}
                        onChange={e => setDetails({ ...details, location: e.currentTarget.value })}
                        placeholder="building name, streetname, city"
                        required={true}
                    />
                    <Feedback type="invalid">
                        A location must be provided
                    </Feedback>
                </Col>
            </Row>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Classification</Label>
                    <Form.Select
                        value={details.classification}
                        onChange={e => setDetails({ ...details, classification: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {EventClassifications.map(t => <option value={t} key={t}>{UCWords(t)}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Please choose a classification for this event.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Category</Label>
                    <Form.Select
                        value={details.category}
                        onChange={e => setDetails({ ...details, category: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {EventCategories.map(t => <option value={t} key={t}>{UCWords(t)}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Please choose a category for this event.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Colour</Label>
                    <Form.Select
                        value={details.colour}
                        onChange={e => setDetails({ ...details, colour: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {EventColours.map(t => <option value={t} key={t}>{UCWords(t)}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Please choose a category for this event.
                    </Feedback>
                </Col>
            </Row>


            <h4 className="form-section-label">Products</h4>
            {prods.length === 0 &&
                <Form.Text as={"div"} className="my-2 lead">
                    No Products added yet. Add a product to continue.
                </Form.Text>}

            <Row className="d-none d-sm-flex">
                <Col sm={3}>
                    <Label className="form-field-title">Title</Label>
                </Col>
                <Col sm={2}>
                    <Label className="form-field-title">Dimensions</Label>
                </Col>
                <Col sm={2}>
                    <Label className="form-field-title">Days</Label>
                </Col>
                <Col sm={2}>
                    <Label className="form-field-title">Qty</Label>
                </Col>
                <Col sm={3}>
                    <Label className="form-field-title">Unit Price</Label>
                </Col>
            </Row>

            {prods.map((p, idx) => (
                <Row key={idx} className="pb-3 pb-sm-2 my-1 border-bottom g-2">
                    <Col sm={3} xs={8}>
                        <Label className="form-field-title d-sm-none">Title</Label>
                        <Form.Select
                            value={p.product}
                            onChange={e => updateProduct("product", e.currentTarget.value, idx)}
                            placeholder="Which one?"
                            required={true}
                            size="sm"
                        >
                            <option value="">Choose a product</option>
                            {EventProducts.map(p => <option key={p} value={p}>{p}</option>)}
                        </Form.Select>
                        <Feedback type="invalid">
                            A product must be selected.
                        </Feedback>

                    </Col>
                    <Col sm={2} xs={4}>
                        <Label className="form-field-title d-sm-none">Dimensions</Label>
                        <Form.Control
                            value={p.dimensions}
                            onChange={e => updateProduct("dimensions", e.currentTarget.value, idx)}
                            // placeholder="Product dimensions"
                            size="sm"
                            pattern="[0-9xX]+"
                            required={DimensionedProducts.indexOf(p.product) !== -1}
                        />
                        <Feedback type="invalid">
                            Size must be in the format of ##x## e.g., 22x46
                        </Feedback>

                    </Col>
                    <Col sm={2} xs={3}>
                        <Label className="form-field-title d-sm-none">Days</Label>
                        <Control
                            value={p.no_days}
                            onChange={e => updateProduct("no_days", e.currentTarget.value, idx)}
                            type="number"
                            min="1"
                            step="1"
                            // placeholder="e.g.,2"
                            required={true}
                            size="sm"
                        />
                        <Feedback type="invalid">
                            Must not be less than 1.
                        </Feedback>
                    </Col>
                    <Col sm={2} xs={3}>
                        <Label className="form-field-title d-sm-none">Qty</Label>
                        <Control
                            value={p.qty}
                            onChange={e => updateProduct("qty", e.currentTarget.value, idx)}
                            type="number"
                            min="1"
                            step="1"
                            // placeholder="e.g.,2"
                            required={true}
                            size="sm"
                        />
                        <Feedback type="invalid">
                            Must not be less than 1.
                        </Feedback>
                    </Col>
                    <Col sm={3} xs={6}>
                        <Label className="form-field-title d-sm-none">Unit Price</Label>
                        <InputGroup hasValidation>
                            <Control
                                value={p.unit_price}
                                onChange={e => updateProduct("unit_price", e.currentTarget.value, idx)}
                                type="number"
                                min="1"
                                step="0.05"
                                // placeholder="per item"
                                required={true}
                                size="sm"
                            />
                            <Button
                                variant="outline-danger"
                                className="border-0"
                                onClick={() => deleteProduct(idx)}
                            >
                                <i className="fas fa-times" />
                            </Button>
                            <Feedback type="invalid">
                                Unit process must be not less than 1 in multiples of 0.05
                            </Feedback>
                        </InputGroup>

                    </Col>
                </Row>
            ))}

            <div className="mt-4">
                <Button
                    variant="secondary"
                    className="rounded-pill" size="sm"
                    onClick={() => setProds([...prods.concat({ ...productObject, isNew: 1 })])}
                >
                    <i className="fas fa-plus-circle me-2" />New Product
                </Button>
            </div>


            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Event" : "Create Event"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}


export default EventForm;