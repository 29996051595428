import { APIURL, postData, getData } from "../fetch";


/**
 * 
 * @typedef {{
 * id: string
 * user_id: string
 * endpoint: string
 * token: string
 * key: string 
 * date_added: string 
 * date_updated: string
 * }} SubscriptionObject 
 */




/**
 * 
 * @param {SubscriptionObject} data 
 * @returns {Promise<{code: number, message: string, subscription: SubscriptionObject}>}
 */
const createSubscription = (data) => {
    return postData({
        url: `${APIURL}/push-subscriptions`,
        data
    })
}

/**
 * 
 * @param {SubscriptionObject} data 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, subscription: SubscriptionObject}>}
 */
const updateSubscription = (data, id) => {
    return postData({
        url: `${APIURL}/push-subscriptions/${id}`,
        data
    })
}

/**
 * 
 * @returns {Promise<{code: number, message: string, key: string}>}
 */
const getPublicKey = () => getData({ url: `${APIURL}/push-subscriptions/public-key` })



export {
    createSubscription,
    updateSubscription,
    getPublicKey
}