import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Button, ListGroup, Offcanvas, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { errorAlert, infoAlert } from "../components/toastr";
import { useVisibility } from "../components/custom-hooks";
import { getNotifications, getNewNotifications as getNew, readNotification, readNotificationByRef } from "../resources/api/notifications";
import { groupBy, sortFunction } from "../components/resources";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoading } from "../components/loading";



const Notifications = () => {

    const navigate = useNavigate();
    const isVisible = useVisibility();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [view, setView] = useState("unread");

    const [lastdatetime, setLastDatetime] = useState("");

    /**@type {[import("../resources/api/notifications").NotificationObject[], React.Dispatch<React.SetStateAction<import("../resources/api/notifications").NotificationObject[]>>]} notifications */
    const [notifications, setNotifications] = useState([]);


    const handleClick = id => {

        let items = [...notifications],
            ind = items.findIndex(i => (i.id === id)),
            item = items[ind];

        if (!item.isRead) {
            readNotification(item.id)
                .then(({ notification }) => {
                    items.splice(ind, 1, notification);
                    setNotifications(items);
                })
                .catch(e => errorAlert(`Could not unread the notification. ${e}`))
        }

        navigate(item.url);
        handleClose();
    };

    // get all notifications
    useEffect(() => {
        getNotifications()
            .then(({ notifications }) => {
                notifications.sort((a, b) => sortFunction(a, b, "date_added", "desc"));
                setNotifications(notifications);

            })
            .catch(e => errorAlert(`Could not load notifications. ${e}`))
    }, [])

    const getNewNotifications = useCallback(() => {

        getNew(lastdatetime)
            .then(({ notifications }) => {
                if (notifications.length === 0) return;

                infoAlert(`${notifications.length} new notifications`, "New Notifications", { onclick: handleShow });
                setNotifications(_notifications =>
                    _notifications
                        .concat(notifications)
                        .sort((a, b) => sortFunction(a, b, "date_added", "desc"))
                );

            })
            .catch(e => errorAlert(`Could not load new notifications. ${e}`));

        //get new notifications
        //if any log them in the infoAlert
        //then add them to the list of notifications
        //order them by date_ascending 
        //log the last gotten notification
        //update the unread notifications button
    }, [lastdatetime])

    useEffect(() => {

        if (!isVisible) return;

        getNewNotifications();
        const intervalPeriod = 10 * 60 * 1000; // 5 minutes
        const getInterval = setInterval(getNewNotifications, intervalPeriod);

        return () => clearInterval(getInterval);

    }, [isVisible, getNewNotifications])

    useEffect(() => {
        if (notifications.length === 0) setLastDatetime(moment().format("YYYY-MM-DD HH:mm:ss"));
        else setLastDatetime(notifications[0].date_added);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications.length])


    const unreadNotificationCount = useMemo(() => {

        return notifications.filter(i => !i.isRead).length;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(notifications)])

    /** @type {{[any:string]: import("../resources/api/notifications").NotificationObject[]}} */
    const display_notifications = useMemo(() => {

        let items = [...notifications];

        if (view === "unread") items = items.filter(i => !i.isRead);

        return groupBy(items, "_date");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(notifications), view])




    return (
        <>
            <Button id="notification_button" className="bx-shadow" onClick={handleShow}>
                <i className="far fa-bell fa-lg" /> <Badge bg="danger" style={{ padding: '.2rem .3rem' }}>{unreadNotificationCount}</Badge>
            </Button>
            <Offcanvas show={show} placement="end" onHide={handleClose} name="notificationbar">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Notifications</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="small p-1">

                    <div className="text-center">
                        <ToggleButtonGroup type="radio" name="NotificationView" size="sm" value={view} onChange={val => setView(val)}>
                            <ToggleButton id="tbg-radio-1" value="unread" variant="primary">
                                Unread
                            </ToggleButton>
                            <ToggleButton id="tbg-radio-2" value="all" variant="primary">
                                Everything
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                    {(Object.keys(display_notifications).length === 0) &&
                        <div className="text-center my-2 my-sm-4 lead">Nothing to display <i className="far fa-face-sad-tear ms-1" /></div>
                    }

                    {Object.keys(display_notifications).map(date => (
                        <Fragment key={date}>
                            <div className="text-center my-2">
                                <Badge>{moment(date).format("dddd DD MMMM YYYY")}</Badge>
                            </div>

                            <ListGroup variant="flush" className="app_notification_group">
                                {display_notifications[date].map(n => (
                                    <ListGroup.Item
                                        key={n.id}
                                        action
                                        onClick={() => handleClick(n.id)}
                                        variant={n.isRead ? null : "primary"}
                                    >
                                        {!n.isRead && <i className="fas fa-circle fa-2xs text-primary me-1" />}
                                        {n.message}
                                        <span className="float-end small">{n._time}</span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Fragment>
                    ))}


                    {/* <div className="text-center my-2">
                        <Badge>Thursday 24 January</Badge>
                    </div>

                    <ListGroup variant="flush" className="app_notification_group">
                        <ListGroup.Item action onClick={handleClick}>
                            Invoice Invoice No was created.
                            <span className="float-end small">12:55</span>
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            Dapibus ac facilisis in
                            <span className="float-end small">12:55</span>
                        </ListGroup.Item>
                        <ListGroup.Item action onClick={handleClick}>
                            Morbi leo risus
                            <span className="float-end small">12:55</span>
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            Porta ac consectetur ac
                            <span className="float-end small">12:55</span>
                        </ListGroup.Item>
                    </ListGroup> */}


                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}


export const NotificationClick = () => {

    const { reference } = useParams();
    const navigate = useNavigate();


    useEffect(() => {

        if (reference === "test") {
            infoAlert("That was just a test notification for you to see how it will appear. Thanks.");
            navigate("/app");
            return;
        }

        readNotificationByRef(reference)
            .then(({ notification }) => {
                navigate(notification.url);
            })
            .catch(e => {
                errorAlert(`Could not process notificaton. Error: ${e}`);
                navigate("/app");
            })

    }, [reference, navigate])


    return (<PageLoading>Loading Your Notification</PageLoading>)

}


export default Notifications;