import { APIURL, getData, postData, deleteData } from "../fetch";

/**
 * 
 * @typedef {{
 * id: string,
 * title: string,
 * client_id: string
 * client: string|import("./clients").ClientObject
 * vendor_id: string
 * vendor: string|import("./vendors").VendorObject
 * balance: number
 * date_added: string
 * added_by: string
 * }} ClientVendorObject 
 */


/**
 * 
 * @typedef {{
 * statement: {
 *  events: import("./events").EventObject[]
 *  payments: import("./payments").PaymentObject[]
 *  bills: import("./bills").BillObject[]
 *  bill_payments: import("./bill-payments").PaymentObject[]
 *  expenses: import("./expenses").ExpenseObject[]
 * }}} StatementObject 
 */

/**
 * 
 * @param {ClientVendorObject} data 
 * @returns {Promise<{code: number, message: string, client_vendor: ClientVendorObject}>}
 */
const createClient = (data) => {
    return postData({
        url: `${APIURL}/client-vendors`,
        data
    })
}

/**
 * 
 * @param {ClientVendorObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, client_vendor: ClientVendorObject}>}
 */
const updateClient = (data, id) => {
    return postData({
        url: `${APIURL}/client-vendors/${id}`,
        data
    })
}


/**
 * 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, client_vendor: ClientVendorObject}>}
 */
const refreshClient = (id) => postData({ url: `${APIURL}/client-vendors/${id}/refresh` });


/**
 * 
 * @param {ClientVendorObject} data 
 * @returns {Promise<{code: number, message: string, client_vendors: ClientVendorObject[]}>}
 */
const getClients = () => getData({ url: `${APIURL}/client-vendors` })

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, client_vendor: ClientVendorObject}>}
 */
const getClient = (id, includes = null) => {

    const obj = { url: `${APIURL}/client-vendors/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, statement: StatementObject}>}
 */
const getClientStatement = id => getData({ url: `${APIURL}/client-vendors/${id}/statement` })



/**
 * @param {string} id ID of the note
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteClient = id => deleteData({ url: `${APIURL}/client-vendors/${id}` });

export {
    createClient,
    getClient,
    getClients,
    updateClient,
    getClientStatement,
    deleteClient,
    refreshClient
}