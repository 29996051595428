import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Form, Table, Card, Row, Col } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from '../../components/pagination';
// import { datatype, date, finance, name } from 'faker';
import { Link, useSearchParams } from 'react-router-dom';
import { useScreenType } from '../../components/custom-hooks';
import currency from '../../components/currency';
import moment from 'moment';
import { getPayments } from '../../resources/api/bill-payments';
import { ErrorLoading, PageLoading } from '../../components/loading';
// import { errorAlert } from '../../components/toastr';
// import { createFile } from '../../components/exceller';
import { UCWords, sortFunction } from '../../components/resources';
import cur from '../../components/currency';



/**
 * View table items on screens larger than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {import('../../resources/api/bill-payments').PaymentObject[]} props.items
 * @param {number} props.currentPage
 * @param {number} props.noPageItems
*/
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems }) => {

    const El = ({ field, children, ...props }) => <th {...props}>{children} <SortButton field={field} {...srt} handleSort={handleSort} /></th>;

    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            {/* <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "14%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "18%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "11%" }} />
                <col span="1" style={{ width: "12%" }} />
            </colgroup> */}
            <thead>
                <tr>
                    <th>#</th>
                    <El field="receipt_no">Receipt No</El>
                    <El field="payment_date">Pay Date</El>
                    <El field="vendor">Vendor</El>
                    <El field="method">Method</El>
                    <El field="account">Account</El>
                    <El className="text-end" field="total_amount">Amount</El>
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td>
                            <Link to={`/app/bill-payments/${e.id}`}>
                                {e.receipt_no}
                            </Link>
                        </td>
                        <td>{moment(e.payment_date).format("DD MMM YYYY")}</td>
                        <td>
                            <Link to={`/app/vendors/${e.vendor_id}`}>
                                {e.vendor}
                            </Link>
                        </td>
                        <td>{UCWords(e.method.replace(/-/g, " "))}</td>
                        <td><Link to={`/app/accounts/${e.account_id}`}>{e.account}</Link></td>
                        <td className="text-end">{currency(e.total_amount, 0).format()}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {import('../../resources/api/bill-payments').PaymentObject[]} props.items
 */
const ViewMobile = ({ srt, handleSort, items }) => {

    const El = ({ field, children }) => (
        <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
            {children} <SortButton field={field} {...srt} handleSort={handleSort} />
        </span>
    )
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <El field="receipt_no">Receipt No</El>
                <El field="vendor">Vendor</El>
                <El field="payment_date">Date</El>
                <El field="account">Account</El>
                <El field="method">Method</El>
                <El field="total_amount">Amount</El>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span className="text-secondary font-weight-normal">{e.reference}</span>
                            <span className="text-muted font-weight-normal">{moment(e.payment_date).format("ddd DD MMM YYYY")}</span>
                        </div>

                        <div className="lead mb-2 fw-normal">
                            <Link to={`/app/bill-payments/${e.id}`}>{e.receipt_no}</Link>
                        </div>
                        <Row>
                            <Col xs={4} className="small">
                                VENDOR
                            </Col>
                            <Col xs={8}>
                                <Link to={`/app/vendors/${e.vendor_id}`}>{e.vendor}</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                AMOUNT
                            </Col>
                            <Col xs={8} className="fw-bold">
                                {cur(e.total_amount).format()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                ACCOUNT
                            </Col>
                            <Col xs={8}>
                                <Link to={`/app/accounts/${e.account_id}`}>{UCWords(e.account.replace(/-/g, " "))}</Link> via {UCWords(e.method.replace(/-/g, " "))}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}

/**
 * module that handles view of clients
 * match path /app/clients
 */
const ViewPayments = () => {


    const [searchParams] = useSearchParams();

    const [srt, setSrt] = useState({
        currentField: 'payment_date',
        currentOrder: 'desc'
    });

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'receipt_no' });


    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [navItems, setNavItems] = useState([{
        title: 'Bill Payments'
    }]);

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);

    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {
        const v = search.val, f = search.field;
        if (v.length < 2 || f === "") return setItems(orginalItems);
        setItems([...orginalItems.filter(i => i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1)]);
    }, [search.val, search.field, orginalItems]);


    /**
     * get the items
     */
    useEffect(() => {

        setLoaded(false);
        setError(null);

        let conditions = null,
            vendor_id = searchParams.get("vendor");

        if (vendor_id) conditions = { vendor_id };

        getPayments(true, conditions)
            .then(({ payments }) => {

                if (payments.length > 0) {

                    if (vendor_id) {
                        let vendor = payments[0].vendor;
                        setNavItems([{ title: "Bill Payments", href: `/app/bill-payments` }, { title: vendor }]);
                    } else {
                        setNavItems([{ title: "Bill Payments" }]);
                    }

                    setOriginalItems(payments);
                    setItems(payments);
                }

            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [searchParams])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => sortFunction(a, b, srt.currentField, srt.currentOrder);
        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }



    if (!loaded) return <PageLoading>Loading all payments...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1000">
                <Title>Bill Payments</Title>
                <ActionBar>
                    {/* <ButtonToolbar>
                        <Btn title="Download">
                            <i className="fas fa-cloud-download-alt" />
                        </Btn>
                    </ButtonToolbar> */}
                    <ButtonToolbar>
                        <Btn href="/app/bill-payments/new" title="New Payment">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>

                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="Search For" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="receipt_no">Receipt No</option>
                            <option value="vendor">Vendor</option>
                            <option value="account">Account</option>
                            <option value="method">Method</option>
                            <option value="payment_date">Date</option>
                        </Form.Select>
                    </Col>


                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} /> :
                    <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>
        </>
    )
}


export default ViewPayments;