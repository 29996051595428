import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../components/btns";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import { createClient, updateClient } from "../../resources/api/clientvendors";
import { getClients } from "../../resources/api/clients";
import { getVendors } from "../../resources/api/vendors";
import SelectInput from "../../components/select";


const { Control, Label } = Form;

/**
 * @param {Object} props
 * @param {"create"|"edit"} props.action
 * @param {(details: import("../../resources/api/clientvendors").ClientVendorObject) => void} props.onUpload
 * @param {{
 *  show: boolean
 *  setShow: React.Dispatch<React.SetStateAction<boolean>>
 * }} props.show
 * @param {{
 *  details: import("../../resources/api/clientvendors").ClientVendorObject, 
 *  setDetails: React.Dispatch<React.SetStateAction<import("../../resources/api/clientvendors").ClientVendorObject>>
 * }} props.clientDetails
 */
const ClientVendorForm = ({ onUpload, action = "create", clientDetails, show: toShow }) => {

    const inputref = useRef();

    const { show, setShow } = toShow;
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    const { details, setDetails } = clientDetails;

    const [preload, setPreLoad] = useState({ clients: [], vendors: [] })

    useEffect(() => {

        infoAlert("Loading the vendors & clients lists...");

        Promise.all([getClients(), getVendors()])
            .then(([{ clients }, { vendors }]) => {
                setPreLoad(({
                    clients: clients.map(c => ({ ...c, description: c.address })),
                    vendors: vendors.map(c => ({ ...c, description: c.address }))
                }))
            })
            .catch(e => errorAlert(e))
    }, []);



    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);
        setValidated(false);

        let promise;

        if (action === "create") {
            promise = createClient(details);
        } else {
            promise = updateClient(details, details.id);
        }

        promise
            .then(({ client_vendor, message }) => {
                form.reset();
                successAlert(message);
                onUpload(client_vendor);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (
        <Modal show={show} onExit={() => setValidated(false)} centered animation={false} >
            <Modal.Body>
                <Form noValidate validated={validated} ref={inputref}>


                    <Row className="g-1">
                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Title</Label>
                            <Control
                                value={details.title}
                                onChange={e => setDetails({ ...details, title: e.currentTarget.value })}
                                required
                            />
                            <Control.Feedback type="invalid">
                                Title is compulsory
                            </Control.Feedback>
                        </Col>
                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Vendor</Label>
                            <SelectInput
                                value={[details.vendor_id]}
                                onChange={([vid]) => setDetails(d => ({ ...d, vendor_id: vid || "" }))}
                                options={preload.vendors}
                                placeholder='-'
                                errorText='Required'
                                maxItems={1}
                                required

                            />
                        </Col>
                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Client</Label>
                            <SelectInput
                                value={[details.client_id]}
                                onChange={([vid]) => setDetails(d => ({ ...d, client_id: vid || "" }))}
                                options={preload.clients}
                                placeholder='-'
                                errorText='Required'
                                maxItems={1}
                                required

                            />
                        </Col>

                    </Row>
                    <Row>
                        <Col className="mt-4 mb-3 text-end">
                            <SubmitButton isSubmitting={handlingSubmit} onClick={handleSubmit}>
                                {action === "edit" ? "Update" : "Submit"}
                            </SubmitButton>
                            <CancelButton isSubmitting={handlingSubmit} onClick={() => setShow(false)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}


export default ClientVendorForm;