// import moment from "moment";
import { useEffect, useState } from "react";
// import { Table } from "react-bootstrap";
import { Routes, Route, useParams } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { getVehicle } from "../../resources/api/vehicles";
import { EditVehicle } from "./vehicle-manipulation";
import moment from "moment";
import cur from "../../components/currency";
import ViewRefuels from "../refuels/view-refuels";
import ViewHires from "../vehicle-hires/view-hires";
import HireDetails from "../vehicle-hires/view-details";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/vehicles").VehicleObject} props.details
 */
const ViewDetails = ({ details }) => {

    const nav_items = [
        { title: 'Vehicles', href: "/app/vehicles" },
        { title: details.reg_no }
    ]


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.reg_no}</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn href={`/app/vehicles/${details.id}/refuels`} title="Refuels">
                            <i className="fas fa-gas-pump" />
                        </Btn>
                        {details.isExternal &&
                            <Btn href={`/app/vehicles/${details.id}/hires`} title="Hires">
                                <i className="fas fa-taxi" />
                            </Btn>
                        }

                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/vehicles/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/vehicles/new" title="New Vehicle">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                {/* <h4>Vehicle Details</h4> */}
                <dl>
                    <dt>Manufacturer, Model, Year</dt>
                    <dd>{details.manufacturer}, {details.model}, {details.manufacture_year}</dd>
                    <dt>Ownership Status</dt>
                    <dd>{details.isExternal ? "Rented" : "Owned"}</dd>
                    <dt>Owning Company</dt>
                    <dd>{details.owning_company || `n/a`}</dd>
                    <dt>Purchase Date</dt>
                    <dd>{!!details.purchase_date ? moment(details.purchase_date).format("DD MMMM YYYY") : `n/a`}</dd>
                    <dt>Purchase Price</dt>
                    <dd>{!!details.purchase_price ? cur(details.purchase_price, 0).format() : `n/a`}</dd>

                </dl>

                {/* <ViewEvents events={details.events} /> */}

            </div>

        </>
    )

}


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/events").EventObject[]} props.events 
 * @returns 
 */
// const ViewEvents = ({ events }) => {

//     if (events.length === 0) return <></>

//     return (
//         <>
//             <h4 className="mt-3 mt-md-5">Events</h4>
//             <Table responsive hover size="sm" style={{ maxWidth: "600px" }}>
//                 <thead>
//                     <tr>
//                         <th>Event</th>
//                         <th>Date</th>
//                         <th className="text-end">Total Amount</th>
//                         <th className="text-end">Balance</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {events.map(e => (
//                         <tr key={e.id}>
//                             <td>
//                                 <Link to={`/app/events/${e.id}`} >{e.reference}</Link>
//                             </td>
//                             <td>{moment(e.event_date).format("DD MMM YYYY")}</td>
//                             <td className="text-end">{cur(e.amount, 0).format()}</td>
//                             <td className="text-end">{cur(e.balance, 0).format()}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//                 <tfoot>
//                     <tr>
//                         <th colSpan={2}>Total</th>
//                         <th className="text-end">{cur(events.reduce((p, c) => p + c.amount, 0), 0).format()}</th>
//                         <th className="text-end">{cur(events.reduce((p, c) => p + c.balance, 0), 0).format()}</th>
//                     </tr>
//                 </tfoot>
//             </Table>
//         </>
//     )
// }


const VehicleDetails = () => {

    const { vehicleid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();


    useEffect(() => {

        setLoaded(false);
        setError(null);

        // const sortFn = (a, b) => sortFunction(a, b, 'event_date', "asc");

        getVehicle(vehicleid, [])
            .then(({ vehicle }) => {

                // const events = [...client.events];
                // events.sort(sortFn);

                setDetails({ ...vehicle });
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [vehicleid])

    //load events details

    if (!isLoaded) {
        return <PageLoading />
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Routes>
            <Route path="/hires/:hireid" element={<HireDetails details={details} />} />
            <Route path="/hires" element={<ViewHires details={details} />} />
            <Route path="/refuels" element={<ViewRefuels details={details} />} />
            <Route path="/edit" element={<EditVehicle details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewDetails details={details} />} />
        </Routes>
    )

}


export default VehicleDetails;