import { Form, Row, Col, FormSelect } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { DateSelector } from '../../components/datetimepicker';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import moment from 'moment';
import { SubmitButton, CancelButton } from '../../components/btns';
import SelectInput from '../../components/select';
import { useEffect } from 'react';
import { getVehicles } from '../../resources/api/vehicles';
import { createMaintenance, updateMaintenance } from '../../resources/api/maintenance';
import { MaintenanceItems } from '../../components/vehicles';
import { AppUserContext } from '../../App';


const { Control, Control: { Feedback }, Label } = Form;


/**
 * 
 * @param {Object} props
 * @param {"create"|"edit"} props.action 
 * @param {import('../../resources/api/maintenance').MaintenanceObject} props.details 
 * @param {(details: import('../../resources/api/maintenance').MaintenanceObject) => void} props.onSubmit 
 */
const MaintenanceForm = ({ action = "create", details: maintenanceObject, onSubmit }) => {

    const [details, setDetails] = useState(maintenanceObject);
    const [items, setItems] = useState(maintenanceObject.items || []);
    const [deleted_items, setDeletedItems] = useState([]);

    // const [files, setFiles] = useState([]);
    const [vehicles, setVehicles] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const { profile } = useContext(AppUserContext);


    useEffect(() => {

        infoAlert("Loading the vehicle list...");

        getVehicles()
            .then(({ vehicles: _vehicles }) => {
                setVehicles(
                    _vehicles
                        .filter(i => !i.isExternal)
                        .map(c => ({ ...c, title: c.reg_no, description: `${c.manufacturer} ${c.model} ${c.manufacture_year}` }))
                );

                successAlert("Successfully loaded the vehicle list");
            })
            .catch(e => errorAlert(e))
    }, []);


    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        const data = {
            ...details,
        }

        let promise;

        if (action === "create") {
            data.items = items;
            promise = createMaintenance(data);
        } else {
            promise = updateMaintenance({
                ...data,
                new_items: items.filter(i => i.isNew),
                updated_items: items.filter(i => (!!i.id && i.hasChanged)),
                deleted_items
            }, data.id)
        }

        promise
            .then(({ maintenance, message }) => {
                successAlert(message);
                onSubmit(maintenance);
            })
            .catch(e => {
                setSubmitting(false);
                errorAlert(e);
            })

    }


    return (

        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label mt-5">Details</h4>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Date</Label>
                    <DateSelector
                        minDate={(profile.permission_level <= 2) ? false : moment().add(-1, 'months').format('YYYY-MM-DD')}
                        maxDate={0}
                        value={details.maintenance_date}
                        onChange={val => setDetails(d => ({ ...d, maintenance_date: val }))}
                        required
                    />
                    <Feedback type="invalid">
                        You must select a date.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Type</Label>
                    <FormSelect
                        value={details.type}
                        onChange={e => setDetails({ ...details, type: e.currentTarget.value })}
                        required
                    >
                        <option value="" />
                        <option value="repair">Repair</option>
                        <option value="service">Service</option>
                    </FormSelect>
                    <Feedback type="invalid">
                        You must select an option
                    </Feedback>
                </Col>
            </Row>


            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Vehicle</Label>
                    <SelectInput
                        value={[details.vehicle_id]}
                        placeholder='-'
                        maxItems={1}
                        onChange={([id]) => setDetails(d => ({ ...d, vehicle_id: id || "" }))}
                        options={vehicles}
                        required
                        errorText='You must select a vehicle'
                    />
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Odometer Reading</Label>
                    <Control
                        type="number"
                        value={details.odometer_reading}
                        onChange={e => setDetails({ ...details, odometer_reading: e.currentTarget.value })}
                        required
                    />
                    <Feedback type="invalid">
                        Odometer Reading is required.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Garage</Label>
                    <Control
                        value={details.garage}
                        onChange={e => setDetails({ ...details, garage: e.currentTarget.value })}
                        required
                    />
                    <Feedback type="invalid">
                        Required.
                    </Feedback>
                </Col>
            </Row>

            <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Observations</Label>
                    <Control
                        as="textarea"
                        rows={2}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        value={details.observations || ""}
                        onChange={e => setDetails({ ...details, observations: e.currentTarget.value })}
                        required
                        maxLength={200}
                    />
                    <Feedback type="invalid">
                        Required
                    </Feedback>
                    <Form.Text muted>What led to taking the vehicle for this activity?</Form.Text>
                </Col>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Actions</Label>
                    <Control
                        as="textarea"
                        rows={2}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        value={details.actions || ""}
                        onChange={e => setDetails({ ...details, actions: e.currentTarget.value })}
                        required
                        maxLength={200}
                    />
                    <Feedback type="invalid">
                        Required
                    </Feedback>
                    <Form.Text muted>What was done to the vehicle?</Form.Text>
                </Col>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Remarks</Label>
                    <Control
                        as="textarea"
                        rows={2}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        value={details.remarks || ""}
                        onChange={e => setDetails({ ...details, remarks: e.currentTarget.value })}
                        maxLength={200}
                    />
                    <Form.Text muted>Anything we should know after the activity? Recommended future works? Something to monitor? Useful information?</Form.Text>
                </Col>
            </Row>



            <h4 className="form-section-label mt-5">Items</h4>

            <MaintenanceItems
                maintenanceitems={{ items, setItems }}
                deleteditems={{ deleted_items, setDeletedItems }}
            />


            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        Submit Form
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>

        </Form >
    )
}


export default MaintenanceForm;