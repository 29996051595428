import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { getPayrollPayment } from '../../resources/api/payroll';
// import PageHeader, { Title } from './../../components/pageheader';
import LabourForm from './labour-form';

const labourObject = {
    event_id: "",
    activity: "",
    activity_date: "",
    base_rate: 0,
    overtime_rate: 0,
    risk_factor: 0,
    workers: ""
};


/**
 * handle page for creating trip
 * @param {Object} props
 * @param {import('../../resources/api/events').EventObject} props.event_details
 */
const NewLabour = ({ event_details }) => {

    const nav_items = [
        { title: 'Events', href: '/app/events' },
        { title: event_details.reference, href: `/app/events/${event_details.id}` },
        { title: 'Labour', href: `/app/events/${event_details.id}/labour` },
        { title: 'New Schedule' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        navigate(`/app/events/${details.event_id}/labour`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <LabourForm
                    event_details={event_details}
                    details={labourObject}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/events").EventObject} props.event_details 
 */
const EditLabour = ({ event_details }) => {

    const nav_items = [
        { title: 'Events', href: '/app/events' },
        { title: event_details.reference, href: `/app/events/${event_details.id}` },
        { title: 'Labour', href: `/app/events/${event_details.id}/labour` },
        { title: 'Edit Schedule' }
    ];


    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const navigate = useNavigate();

    const { labourid } = useParams();

    useEffect(() => {

        setLoaded(false);

        getPayrollPayment(labourid, ['workers'])
            .then(({ payment }) => {
                setDetails(payment);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [labourid])



    const handleSubmit = (details) => {
        // setDetails(d => ({ ...d, ...details }));
        // navigate(`/app/events/${details.id}`);        
        navigate(`/app/events/${details.event_id}/labour`);
    }

    if (!isLoaded) {
        return <PageLoading>Loading event details...</PageLoading>
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>


    return (
        <>
            <Nav items={nav_items} />

            {/* <PageHeader maxWidth="800">
                <Title>{details.reference}</Title>
            </PageHeader> */}

            <div className="max-800 p-2">
                <LabourForm
                    event_details={event_details}
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewLabour, EditLabour };