import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Group from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import { useEffect, useMemo, useState } from 'react';
import { error as errorAlert, info as infoAlert } from './toastr';
import currency from './currency';
import { FormSelect, InputGroup } from 'react-bootstrap';
import { useCallback } from 'react';
import { UCWords } from './resources';
import { expenseCategories, taxes } from '../assets/constants';
import cur from './currency';

/**
 * 
 * @param {{
 * invoiceitems: {items: string[], setItems: React.Dispatch<React.SetStateAction<string[]>>}
 * children: React.ReactNode
 * }} props 
 */
const InvoiceItems = ({ invoiceitems, children }) => {

    const { Row } = Form;
    const { items, setItems } = invoiceitems;

    /**
     * add a new all empty invoice item.
     */
    const addItem = () => {
        setItems(items.concat({
            title: '',
            description: '',
            qty: '',
            rate: '',
            tax: '',
            expense_id: null
        }));
    }

    /**
     * UPdate an item in the list
     * @param {number} index 
     * @param {} value 
     */
    const updateItem = (index, value) => {

        // console.log(value);
        let current_items = [...items];
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    /**
     * Deletes an item in poisition index from the list.
     * @param {number} index Index of item in the list
     */
    const deleteItem = index => {
        if (items.length < 2) return errorAlert("You must have at least one invoice item on the invoice");
        setItems(items.filter((e, i) => i !== index));
        infoAlert("Invoice item has been deleted.");
    }

    /**
     * {number}
     */
    const sub_total = items.reduce((p, c) => c.total ? p + c.total : p, 0);

    return (
        <>
            <Row className="d-none d-sm-flex">
                <Col sm={5} className="py-0">
                    <Form.Label className="form-field-title">Title</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Quantity</Form.Label>
                </Col>
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Unit Price</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Tax</Form.Label>
                </Col>
            </Row>
            {items.map((e, i) => <InvoiceItem key={i} details={e} onChange={value => updateItem(i, value)} onDelete={() => deleteItem(i)} />)}
            <Row>
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={addItem}>
                            <i className="fas fa-plus-circle mr-2" />Add Item
                        </Button>
                        {children}
                    </div>

                    <div>
                        Subtotal: UGX <span className="font-weight-bold h4 my-0">{currency(sub_total).format()}</span>
                    </div>

                </Col>
            </Row>
        </>
    )
}

/**
 * 
 * @param {{details:
 * {title: string, 
 * description: string,
 * qty: number, 
 * rate: number,
 * tax: string},
 * onChange: ({title: string, description: string, qty: number, rate: number, tax: number, expense_id: string | null}) => void
 * }} props 
 */
const InvoiceItem = ({ details, onChange, onDelete }) => {

    const { Label, Control } = Form;
    const [showDes, setShowDes] = useState(false);
    useEffect(() => {
        if (details.qty && details.rate) {
            onChange({ ...details, total: details.qty * details.rate });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.qty, details.rate]);

    return (
        <div className="my-3 pb-1 pb-sm-0 my-sm-1 border-bottom">
            <Row className="label-sm-hide">
                <Col xs={12} sm={5} className="my-1">
                    <div>
                        <Label className="form-field-title">Title</Label>
                        <Control
                            placeholder="Title of item"
                            value={details.title}
                            onChange={e => onChange(({ ...details, title: e.currentTarget.value }))}
                            required
                            disabled={!!details.expense_id}

                        />
                        <Control.Feedback type="invalid">
                            Provide the item title
                        </Control.Feedback>
                    </div>

                    {showDes &&
                        <div className="mt-1">
                            <Control
                                size="sm"
                                value={details.description}
                                onChange={e => onChange(({ ...details, description: e.currentTarget.value }))}
                                placeholder="Description of the item"
                                as="textarea"
                                rows={1}
                                onFocus={e => e.currentTarget.rows = 4}
                                onBlur={e => e.currentTarget.rows = 1}

                            />
                        </div>}
                    <Button size="sm" className="" variant="link" onClick={() => setShowDes(d => !d)}>
                        {showDes ? "Hide Description" : (details.description === "" ? "Add Description" : "Show Description")}
                    </Button>
                </Col>
                <Col xs={3} sm={2} className="my-1">
                    <Label className="form-field-title">Quantity</Label>
                    <Control
                        value={details.qty}
                        onChange={e => onChange(({ ...details, qty: e.currentTarget.value }))}
                        placeholder="e.g., 1"
                        type="number"
                        required
                        min="1"
                        disabled={!!details.expense_id}
                    />
                    <Control.Feedback type="invalid">
                        Quantity must not be less than 1.
                    </Control.Feedback>
                </Col>
                <Col xs={5} sm={3} className="my-1">
                    <Label className="form-field-title">Unit Price</Label>
                    <Control
                        value={details.rate}
                        onChange={e => onChange(({ ...details, rate: e.currentTarget.value }))}
                        placeholder="e.g., 200.00"
                        type="number"
                        step="0.01"
                        required
                        min="0.01"
                        disabled={!!details.expense_id}
                    />
                    <Control.Feedback type="invalid">
                        Unit price must not be less than 0.01.
                    </Control.Feedback>
                </Col>
                <Col xs={4} sm={2} className="my-1">
                    <Label className="form-field-title">Tax</Label>
                    <InputGroup>
                        <Control
                            as="select"
                            value={details.tax}
                            onChange={e => onChange(({ ...details, tax: e.currentTarget.value }))}
                            custom
                            disabled={!!details.expense_id}
                        >
                            <option value="">None</option>
                            <option value="18">VAT 18%</option>
                        </Control>
                        <InputGroup.Append>
                            <Button className="text-danger" variant="link" onClick={onDelete}>
                                <i className="fas fa-times" />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>

                </Col>
            </Row>
        </div>
    )
}

/**
 * 
 * @param {{notes: string[], setNotes: React.Dispatch<React.SetStateAction<string[]>>}} props 
 */
const InvoiceNotes = ({ notes, setNotes }) => {

    useEffect(() => {
        if (notes.length === 0) setNotes(['']);
    }, [notes.length, setNotes])

    const updateNote = (index, value) => {

        let current_notes = [...notes];
        current_notes[index] = value;
        setNotes(current_notes);
    }

    const deleteNote = index => {
        if (notes.length < 2) return errorAlert("You should have at least one note item on the page");
        setNotes(notes.filter((e, i) => i !== index));
        infoAlert("Note Item has been deleted.");
    }

    return (
        <Form.Row>
            <Col sm={12} className="my-1">
                {notes.map((n, i) => <NoteItem note={n} key={i} placeholder="Notes - any relevant information already covered" onChange={text => updateNote(i, text)} required={notes.length > 1} onDelete={() => deleteNote(i)} />)}
            </Col>
            <Col sm={12} className="my-2">
                <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={() => setNotes(n => [...n, ''])}>
                    <i className="fas fa-plus-circle mr-2" />Add New List Item
                </Button>
            </Col>
        </Form.Row>
    )
}

/**
 * 
 * @param {{
 * note: string, 
 * required: boolean, 
 * placeholder: string,
 * onChange: (value: string) => void,
 * onDelete: () => void
 * }} param0 
 */
const NoteItem = ({ note, onChange, onDelete, required, placeholder }) => {
    return (
        <InputGroup className="my-2">
            <Form.Control as="textarea" rows={1}
                placeholder={placeholder}
                onFocus={e => e.currentTarget.rows = 4}
                onBlur={e => e.currentTarget.rows = 2}
                value={note}
                onChange={e => onChange(e.currentTarget.value)}
                required={required}
            />
            <InputGroup.Append>
                <Button className="text-danger" variant="link" onClick={onDelete}><i className="fas fa-times" /></Button>
            </InputGroup.Append>
            {
                required &&
                <Form.Control.Feedback type="invalid">
                    This field cannot be left empty. You can delete it instead.
                </Form.Control.Feedback>
            }
        </InputGroup>
    )
}

/**
 * 
 * @param {{TCs: string[], setTCs: React.Dispatch<React.SetStateAction<string[]>>}} param0 
 */
const InvoiceTCs = ({ TCs, setTCs }) => {

    useEffect(() => {
        if (TCs.length === 0) setTCs(['']);
    }, [TCs.length, setTCs])

    const updateTC = (index, value) => {

        let current_tcs = [...TCs];
        current_tcs[index] = value;
        setTCs(current_tcs);
    }


    const deleteTC = index => {
        if (TCs.length < 2) return errorAlert("You should at least one TC item on the page");
        setTCs(TCs.filter((e, i) => i !== index));
        infoAlert("Item has been deleted.");
    }

    return (
        <Form.Row>
            <Col sm={12} className="my-1">
                {TCs.map((n, i) => <NoteItem note={n} key={i} placeholder="Terms and Conditions - Late fees, payment methods, delivery schedule" onChange={text => updateTC(i, text)} required={TCs.length > 1} onDelete={() => deleteTC(i)} />)}
            </Col>
            <Col sm={12} className="my-2">
                <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={() => setTCs(n => [...n, ''])}>
                    <i className="fas fa-plus-circle mr-2" />Add New T&amp;C Item
                </Button>
            </Col>
        </Form.Row>
    )
}

/**
 * 
 * @param {{
 * tax?: number
 * total?: number
 * discount?: number
 * due?: number
 * balance?: number
 * }} props 
 */
const DisplayTotals = ({ tax = 0, total = 0, discount = 0, due = 0, balance = 0 }) => {
    const style1 = {
        fontWeight: 'normal',
        width: '220px',
        display: 'inline-block',
        marginTop: 0,
        marginBottom: 0
    }
    return (
        <div className="d-flex flex-column align-items-end my-3">
            <div className="my-1 text-right">
                TAX (UGX): <span style={style1} className="h4">{currency(tax).format()}</span>
            </div>
            <div className="my-1 text-right">
                TOTAL (UGX): <span style={style1} className="h4">{currency(total).format()}</span>
            </div>
            <div className="my-1 text-right">
                DISCOUNT (UGX): <span style={style1} className="h4">{currency(discount).format()}</span>
            </div>
            <div className="my-1 text-right">
                DUE (UGX): <span style={style1} className="h4 font-weight-bold">{currency(due).format()}</span>
            </div>
            {
                balance > 0 &&
                <div className="my-1 text-right text-danger">
                    BALANCE (UGX): <span style={style1} className="h4 font-weight-bold">{currency(balance).format()}</span>
                </div>
            }
        </div>
    )
}

/**
 * 
 * @param {{
 * items: {rate: number, title: string, description: string|null, qty: number}[]
 * }} param0 
 */
const DisplayInvoiceItems = ({ items }) => {

    return (
        <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "45%", textAlign: 'center' }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "20%" }} />
                <col span="1" style={{ width: "22%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                {
                    items.map((e, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                                <span className="d-block font-weight-normal">{e.title}</span>
                                {e.description && <div className="description">{e.description}</div>}
                            </td>
                            <td>{e.qty}</td>
                            <td>{currency(e.rate).format()}</td>
                            <td>{currency(e.rate * e.qty).format()}</td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
}

/**
 * 
 * @param {{
 * notes: string[]
 * noDataMessage: string
 * }} param0 
 */
const DisplayInvoiceNotes = ({ notes = [], noDataMessage }) => {

    if (notes.length === 0) {
        return <div className="lead text-muted mb-2">{noDataMessage || `There are no items to be displayed.`}</div>
    }

    return (
        <>
            {notes.map((e, i) => <li key={i}>{e}</li>)}
        </>
    )
}


/**
 * 
 * @param {{
 * payments: {payment_no: string, method: string, payment_date: string, payment_amount: number, amount: number}[]
 * }} props 
 */
const DisplayInvoicePayments = ({ payments }) => {

    return (
        <Table responsive style={{ minWidth: '650px' }} hover>
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "23%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "21%" }} />
                <col span="1" style={{ width: "19%" }} />
                <col span="1" style={{ width: "19%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Payment No</th>
                    <th>Method</th>
                    <th>Date</th>
                    <th>Total Paid</th>
                    <th>This Invoice</th>
                </tr>
            </thead>
            <tbody>
                {payments.map((e, i) => (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{e.payment_no}</td>
                        <td>{e.method}</td>
                        <td>22 Dec 2020</td>
                        <td>2,800,000.00</td>
                        <td>1,400,000.00</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}


/**
 * Display input fields for payments. 
 * Also displays a sum of all the payments for this payment item.
 * @param {{
 * paymentitems: {items: string[], setItems: React.Dispatch<React.SetStateAction<string[]>>}
 * children: React.ReactNode
 * }} param0 
 */
const PaymentItems = ({ paymentitems, children }) => {

    const { Row } = Form;
    const { items, setItems } = paymentitems;

    /**
     * UPdate an item in the list
     * @param {number} index 
     * @param {} value 
     */
    const updateItem = (index, value) => {

        // console.log(value);
        let current_items = [...items];
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    const deleteItem = index => {
        setItems(items.filter((e, i) => i !== index));
    }


    const sub_total = items.reduce((p, c) => c.amount ? p + parseFloat(c.amount) : p, 0);

    if (items.length === 0) {
        return (
            <div className="my-2 lead text-muted">
                No Items added yet. To add a payment item, add the invoices from the Invoices Dropdown field above.
            </div>
        )
    }

    return (
        <>
            <Row className="d-none d-sm-flex">
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Invoice No</Form.Label>
                </Col>
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Balance</Form.Label>
                </Col>
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Payment Amount</Form.Label>
                </Col>
            </Row>
            {items.map((e, i) => <PaymentItem key={i} details={e} onChange={value => updateItem(i, value)} onDelete={() => deleteItem(i)} />)}
            <Row>
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        {children}
                    </div>

                    <div>
                        Total Payment: UGX <span className="font-weight-bold h4 my-0">{currency(sub_total).format()}</span>
                    </div>
                </Col>
            </Row>
        </>
    )
}


/**
 * 
 * @param {{
 * details: {
 * invoice_id: string,
 * invoice_no: string,
 * balance: number,
 * amount: number
 * },
 * onChange: ({invoice_id: string, amount: number}) => void,
 * onDelete: (invoice_id: string) => void
 * }} param0 
 */
const PaymentItem = ({ details, onChange, onDelete }) => {

    const { Row, Label, Control } = Form;

    return (
        <Row className="my-2 my-sm-0 label-sm-hide border-bottom py-2 py-sm-1">
            <Col sm={4} className="my-1">
                <Label className="form-field-title">Invoice No</Label>
                <Control value={details.invoice_no} readOnly />
            </Col>
            <Col sm={4} className="my-1">
                <Label className="form-field-title">Balance</Label>
                <Control type="text" value={currency(details.balance).format()} readOnly />
            </Col>
            <Col sm={4} className="my-1">
                <Label className="form-field-title">Payment Amount</Label>
                <Group>
                    <Control
                        type="number"
                        step="0.01"
                        placeholder="e.g. 3453.68"
                        value={details.amount}
                        onChange={e => onChange({ ...details, amount: e.currentTarget.value })}
                        max={details.balance}
                        required
                    />
                    <Group.Append>
                        <Button variant="outline-danger" className="border-0" onClick={onDelete}>
                            <i className="fas fa-times" />
                        </Button>
                    </Group.Append>
                    <Control.Feedback type="invalid">
                        Payment against invoice must be greater than 1 and a maximum value of {currency(details.balance).format()}.
                    </Control.Feedback>
                </Group>
            </Col>
        </Row>

    )
}

/** 
 * @typedef {{
 *  title: string, 
 *  description: string
 *  qty: number,
 *  rate: number   
 *  tax: number
 * }} ExpenseItemObject
*/

/**
 * 
 * @param {{
 * expenseitems: {
 *      items: import('../resources/api/expenses').ExpensetItemObject[], 
 *      setItems: React.Dispatch<React.SetStateAction<import('../resources/api/expenses').ExpensetItemObject[]>>}
 * deleteditems: {
 *      deleted_items: string[], 
 *      setDeletedItems: React.Dispatch<React.SetStateAction<string[]>>}
 * isTaxInclusive: boolean
 * children: React.ReactNode
 * }} param0 
 */
const ExpenseItems = ({ expenseitems, isTaxInclusive = true, children, deleteditems }) => {

    const { items, setItems } = expenseitems;
    const { setDeletedItems } = deleteditems;

    /**
     * add a new all empty invoice item.
     */
    const addItem = useCallback(() => {
        setItems(items => items.concat({
            title: '',
            quantity: '',
            unit_price: '',
            tax_percent: 0,
            isNew: true,
            total: 0
        }));
    }, [setItems])

    /**
     * UPdate an item in the list
     * @param {number} index 
     * @param {ExpenseItemObject} value 
     */
    const updateItem = (index, value) => {

        // console.log(value);
        let current_items = [...items];
        value.hasChanged = true;
        if (!!value.quantity && !!value.unit_price) {
            value.total = parseFloat(value.quantity) * parseFloat(value.unit_price);
        } else {
            value.total = 0;
        }
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    /**
     * Deletes an item in poisition index from the list.
     * @param {number} index Index of item in the list
     */
    const deleteItem = index => {
        if (items.length < 2) return errorAlert("You must have at least one expense item on the expense if expense is itemized.");
        const _item = { ...items[index] };

        if (_item.id) setDeletedItems(items => items.concat(_item.id));

        setItems(items.filter((e, i) => i !== index));
    }

    /**
     * 
     */
    const totals = useMemo(() => {

        if (items.length === 0) return {
            tax: 0,
            total: 0
        };

        let tax, sub, total, _tax = [], _total = [];

        items.forEach(i => {
            if (!(i.unit_price && i.quantity)) return;

            total = i.quantity * i.unit_price;

            if (!i.tax_percent) {
                _total.push(total);
                return;
            }

            if (isTaxInclusive) {
                sub = total / (1 + parseFloat(i.tax_percent) / 100);
                _tax.push(total - sub);
                _total.push(total);
            } else {
                tax = parseFloat(i.tax_percent) / 100 * total;
                _tax.push(tax);
                _total.push(tax + total);
            }
        })

        /** @type {number} */
        const __total = _total.reduce((p, t) => p + t, 0);

        /** @type {number} */
        const __tax = _tax.reduce((a, t) => a + t, 0);

        return {
            tax: __tax,
            total: __total
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(items), isTaxInclusive])

    const style = {
        minWidth: '175px',
        textAlign: 'right',
        display: 'inline-block'
    }

    useEffect(() => {
        if (items.length === 0) addItem();
    }, [items.length, addItem])

    return (
        <>
            <Row className="d-none d-sm-flex g-2">
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Title</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Quantity</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Unit Price</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Tax</Form.Label>
                </Col>
            </Row>
            {items.map((e, i) => <ExpenseItem key={i} details={e} onChange={value => updateItem(i, value)} onDelete={() => deleteItem(i)} />)}
            <Row>
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={addItem}>
                            <i className="fas fa-plus-circle me-2" />Add Item
                        </Button>
                        {children}
                    </div>

                    <div className="text-end">
                        {totals.tax > 0 &&
                            <>
                                Tax: UGX <span style={style} className="font-weight-normal h5 my-0">{currency(totals.tax).format()}</span> <br /></>}
                        Expense Total: UGX <span style={style} className="font-weight-bold h4 my-0">{currency(totals.total).format()}</span>
                    </div>

                </Col>
            </Row>
        </>
    )
}

/**
 * 
 * @param {{
 * details: import('../resources/api/expenses').ExpensetItemObject,
 * onChange: (details: import('../resources/api/expenses').ExpensetItemObject) => void
 * onDelete: () => void
 * }} props 
 */
const ExpenseItem = ({ details, onChange, onDelete }) => {

    const { Label, Control } = Form;

    return (
        <div className="my-3 py-2 py-sm-0 my-sm-0 border-start border-primary label-sm-hide" style={{ borderLeftWidth: '3px!important' }}>
            <Row className='g-2'>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Title</Label>
                    <Control
                        size="sm"
                        value={details.title}
                        onChange={e => onChange(({ ...details, title: e.currentTarget.value }))}
                        required
                    />
                    <Control.Feedback type="invalid">
                        Item must have a title.
                    </Control.Feedback>
                </Col>
                <Col xs={2} sm={2} className="my-1">
                    <Label className="form-field-title">Qty</Label>
                    <Control
                        size="sm"
                        value={details.quantity}
                        onChange={e => onChange(({ ...details, quantity: e.currentTarget.value }))}
                        type="number"
                        required
                        min="1"
                    />
                    <Control.Feedback type="invalid">
                        Quantity must not be less than 1.
                    </Control.Feedback>
                </Col>
                <Col xs={4} sm={2} className="my-1">
                    <Label className="form-field-title">Unit Price</Label>
                    <Control
                        size="sm"
                        value={details.unit_price}
                        onChange={e => onChange(({ ...details, unit_price: e.currentTarget.value }))}
                        type="number"
                        step="0.01"
                        required
                        min="0.01"
                    />
                    <Control.Feedback type="invalid">
                        Unit price must not be less than 0.01.
                    </Control.Feedback>
                </Col>
                <Col xs={3} sm={2} className="my-1">
                    <Label className="form-field-title">Tax</Label>
                    <Form.Select
                        size="sm"
                        as="select"
                        value={details.tax_percent}
                        onChange={e => onChange(({ ...details, tax_percent: e.currentTarget.value }))}
                    >
                        <option value="0">-</option>
                        <option value="18">VAT</option>
                    </Form.Select>
                    <Control.Feedback type="invalid">
                        Unit price must not be less than 0.01.
                    </Control.Feedback>
                </Col>
                <Col xs={3} sm={2} className="my-1">
                    <Label className="form-field-title">Total</Label>
                    <InputGroup>
                        <Control
                            size="sm"
                            value={cur((details.total || 0), 0).format()}
                            readOnly
                        />
                        <Button onClick={onDelete} size="sm" variant="link" className="text-danger" >
                            <i className="fas fa-times" />
                        </Button>
                        <Control.Feedback type="invalid">
                            Unit price must not be less than 0.01.
                        </Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}



/**
 * 
 * @param {{
* requisitionitems: {
    *      items: import('../resources/api/requisitions').RequisitionItemObject[], 
    *      setItems: React.Dispatch<React.SetStateAction<import('../resources/api/requisitions').RequisitionItemObject[]>>}
    * deleteditems: {
    *      deleted_items: string[], 
    *      setDeletedItems: React.Dispatch<React.SetStateAction<string[]>>}
    * children: React.ReactNode
    * }} param0 
    */
const RequisitionItems = ({ requisitionitems, children, deleteditems }) => {

    const { items, setItems } = requisitionitems;
    const { setDeletedItems } = deleteditems;

    /**
     * add a new all empty invoice item.
     */
    const addItem = useCallback(() => {
        setItems(items => items.concat({
            category: "",
            notes: '',
            quantity: '',
            unit_price: '',
            isNew: true
        }));
    }, [setItems])

    /**
     * update an item in the list
     * @param {number} index 
     * @param {ExpenseItemObject} value 
     */
    const updateItem = (index, value) => {

        // console.log(value);
        let current_items = [...items];
        value.hasChanged = true;
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    /**
     * Deletes an item in poisition index from the list.
     * @param {number} index Index of item in the list
     */
    const deleteItem = index => {
        if (items.length < 2) return errorAlert("You must have at least one requisition item on the expense if expense is itemized.");
        const _item = { ...items[index] };

        if (_item.id) setDeletedItems(items => items.concat(_item.id));

        setItems(items.filter((e, i) => i !== index));
    }

    /**
     * 
     */
    const totals = useMemo(() => {

        if (items.length === 0) return {
            tax: 0,
            total: 0
        };

        let total, _total = [];

        items.forEach(i => {
            if (!(i.unit_price && i.quantity)) return;

            total = i.quantity * i.unit_price;
            _total.push(total);
        })

        /** @type {number} */
        const __total = _total.reduce((p, t) => p + t, 0);

        return {
            total: __total
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(items)])

    const style = {
        minWidth: '175px',
        textAlign: 'right',
        display: 'inline-block'
    }

    useEffect(() => {
        if (items.length === 0) addItem();
    }, [items.length, addItem])

    return (
        <>
            <Row className="d-none d-sm-flex g-2">
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Category</Form.Label>
                </Col>
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Notes</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Quantity</Form.Label>
                </Col>
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Unit Price</Form.Label>
                </Col>
            </Row>
            {items.map((e, i) => <RequisitionItem key={i} details={e} onChange={value => updateItem(i, value)} onDelete={() => deleteItem(i)} />)}
            <Row>
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={addItem}>
                            <i className="fas fa-plus-circle me-2" />Add Item
                        </Button>
                        {children}
                    </div>

                    <div className="text-end">
                        Requisition Total: UGX <span style={style} className="font-weight-bold h4 my-0">{currency(totals.total).format()}</span>
                    </div>

                </Col>
            </Row>
        </>
    )
}

/**
 * 
 * @param {{
 * details: import('../resources/api/requisitions').RequisitionItemObject,
 * onChange: (details: import('../resources/api/requisitions').RequisitionItemObject) => void
 * onDelete: () => void
 * }} props 
 */
const RequisitionItem = ({ details, onChange, onDelete }) => {

    const { Label, Control } = Form;

    return (
        <div className="my-3 py-2 py-sm-0 my-sm-0 border-start border-primary label-sm-hide" style={{ borderLeftWidth: '3px!important' }}>
            <Row className='g-2'>
                <Col sm={3} className="my-1">
                    <Label className="form-field-title">Category</Label>
                    <FormSelect
                        size="sm"
                        value={details.category}
                        onChange={e => onChange(({ ...details, category: e.currentTarget.value }))}
                        required
                    >
                        <option value=""></option>
                        {expenseCategories.map(c => <option value={c} key={c}>{UCWords(c.replace(/-/g, " "))}</option>)}
                    </FormSelect>
                    <Control.Feedback type="invalid">
                        You must choose an expense account
                    </Control.Feedback>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Notes</Label>
                    <Control
                        size="sm"
                        as="textarea"
                        placeholder=""
                        value={details.notes}
                        onChange={e => onChange(({ ...details, notes: e.currentTarget.value }))}
                        rows={1}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        required
                        maxLength={150}
                    />
                    <Control.Feedback type="invalid">
                        Describe the item. Max No Characters: 150. No Characters: {details.notes.length}
                    </Control.Feedback>
                </Col>
                <Col xs={5} sm={2} className="my-1">
                    <Label className="form-field-title">Quantity</Label>
                    <Control
                        size="sm"
                        value={details.quantity}
                        onChange={e => onChange(({ ...details, quantity: e.currentTarget.value }))}
                        // placeholder="e.g., 1"
                        type="number"
                        required
                        min="1"
                    />
                    <Control.Feedback type="invalid">
                        Quantity must not be less than 1.
                    </Control.Feedback>
                </Col>
                <Col xs={7} sm={3} className="my-1">
                    <Label className="form-field-title">Unit Price</Label>
                    <InputGroup>
                        <Control
                            size="sm"
                            value={details.unit_price}
                            onChange={e => onChange(({ ...details, unit_price: e.currentTarget.value }))}
                            // placeholder="e.g., 200.00"
                            type="number"
                            step="0.01"
                            required
                            min="0.01"
                        />
                        <Button onClick={onDelete} size="sm" variant="link" className="text-danger" >
                            <i className="fas fa-times" />
                        </Button>
                        <Control.Feedback type="invalid">
                            Unit price must not be less than 0.01.
                        </Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}



/**
 * 
 * @param {{
* expenseitems: {
    *      items: import('../resources/api/expenses').ExpensetItemObject[], 
    *      setItems: React.Dispatch<React.SetStateAction<import('../resources/api/expenses').ExpensetItemObject[]>>}
    * deleteditems: {
    *      deleted_items: string[], 
    *      setDeletedItems: React.Dispatch<React.SetStateAction<string[]>>}
    * isTaxInclusive: boolean
    * children: React.ReactNode
    * }} param0 
    */
const BillItems = ({ expenseitems, isTaxInclusive = true, children, deleteditems }) => {

    const { items, setItems } = expenseitems;
    const { setDeletedItems } = deleteditems;

    /**
     * add a new all empty invoice item.
     */
    const addItem = useCallback(() => {
        setItems(items => items.concat({
            category: '',
            notes: '',
            quantity: '',
            unit_price: '',
            tax_percent: 0,
            isNew: true
        }));
    }, [setItems])

    /**
     * UPdate an item in the list
     * @param {number} index 
     * @param {ExpenseItemObject} value 
     */
    const updateItem = (index, value) => {

        // console.log(value);
        let current_items = [...items];
        value.hasChanged = true;
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    /**
     * Deletes an item in poisition index from the list.
     * @param {number} index Index of item in the list
     */
    const deleteItem = index => {
        if (items.length < 2) return errorAlert("You must have at least one expense item on the expense if expense is itemized.");
        const _item = { ...items[index] };

        if (_item.id) setDeletedItems(items => items.concat(_item.id));

        setItems(items.filter((e, i) => i !== index));
    }

    /**
     * 
     */
    const totals = useMemo(() => {

        if (items.length === 0) return {
            tax: 0,
            total: 0
        };

        let tax, sub, total, _tax = [], _total = [];

        items.forEach(i => {
            if (!(i.unit_price && i.quantity)) return;

            total = i.quantity * i.unit_price;

            if (!i.tax_percent) {
                _total.push(total);
                return;
            }

            if (isTaxInclusive) {
                sub = total / (1 + parseFloat(i.tax_percent) / 100);
                _tax.push(total - sub);
                _total.push(total);
            } else {
                tax = parseFloat(i.tax_percent) / 100 * total;
                _tax.push(tax);
                _total.push(tax + total);
            }
        })

        /** @type {number} */
        const __total = _total.reduce((p, t) => p + t, 0);

        /** @type {number} */
        const __tax = _tax.reduce((a, t) => a + t, 0);

        return {
            tax: __tax,
            total: __total
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(items), isTaxInclusive])

    const style = {
        minWidth: '175px',
        textAlign: 'right',
        display: 'inline-block'
    }

    useEffect(() => {
        if (items.length === 0) addItem();
    }, [items.length, addItem])

    return (
        <>
            <Row className="d-none d-sm-flex g-2 text-center">
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Category</Form.Label>
                </Col>
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Notes</Form.Label>
                </Col>
                <Col sm={1} className="py-0">
                    <Form.Label className="form-field-title">Qty</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Unit Price</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Tax</Form.Label>
                </Col>
            </Row>
            {items.map((e, i) => <BillItem key={i} details={e} onChange={value => updateItem(i, value)} onDelete={() => deleteItem(i)} />)}
            <Row>
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={addItem}>
                            <i className="fas fa-plus-circle me-2" />Add Item
                        </Button>
                        {children}
                    </div>

                    <div className="text-end">
                        {totals.tax > 0 &&
                            <>
                                Tax: UGX <span style={style} className="font-weight-normal h5 my-0">{currency(totals.tax).format()}</span> <br /></>
                        }
                        Total: UGX <span style={style} className="font-weight-bold h4 my-0">{currency(totals.total).format()}</span>
                    </div>

                </Col>
            </Row>
        </>
    )
}

/**
 * 
 * @param {{
 * details: import('../resources/api/expenses').ExpensetItemObject,
 * onChange: (details: import('../resources/api/expenses').ExpensetItemObject) => void
 * onDelete: () => void
 * }} props 
 */
const BillItem = ({ details, onChange, onDelete }) => {

    const { Label, Control } = Form;

    return (
        <div className="my-3 my-sm-0 py-1 py-sm-0 border-start border-primary label-sm-hide" style={{ borderLeftWidth: '3px!important' }}>
            <Row className='g-2'>
                <Col sm={3} className="my-1">
                    <Label className="form-field-title">Category</Label>
                    <Form.Select
                        size="sm"
                        value={details.category}
                        onChange={e => onChange(({ ...details, category: e.currentTarget.value }))}
                        required
                    >
                        <option value=""></option>
                        {expenseCategories.map(c => <option key={c} value={c}>{UCWords(c.replace(/-/g, " "))}</option>)}
                    </Form.Select>
                    <Control.Feedback type="invalid">
                        Select a category account for this expense line.
                    </Control.Feedback>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Notes</Label>
                    <Control
                        as="textarea"
                        size="sm"
                        value={details.notes}
                        onChange={e => onChange(({ ...details, notes: e.currentTarget.value }))}
                        rows={1}
                        onFocus={e => e.currentTarget.rows = 3}
                        onBlur={e => e.currentTarget.rows = 1}
                        required
                    />
                    <Control.Feedback type="invalid">
                        Item must have a title.
                    </Control.Feedback>
                </Col>
                <Col xs={3} sm={1} className="my-1">
                    <Label className="form-field-title">Quantity</Label>
                    <Control
                        size="sm"
                        value={details.quantity}
                        onChange={e => onChange(({ ...details, quantity: e.currentTarget.value }))}
                        type="number"
                        required
                        min="1"
                    />
                    <Control.Feedback type="invalid">
                        Quantity must not be less than 1.
                    </Control.Feedback>
                </Col>
                <Col xs={5} sm={2} className="my-1">
                    <Label className="form-field-title">Unit Price</Label>
                    <Control
                        size="sm"
                        value={details.unit_price}
                        onChange={e => onChange(({ ...details, unit_price: e.currentTarget.value }))}
                        type="number"
                        step="0.01"
                        required
                        min="0.01"
                    />
                    <Control.Feedback type="invalid">
                        Unit price must not be less than 0.01.
                    </Control.Feedback>
                </Col>
                <Col xs={4} sm={2} className="my-1">
                    <Label className="form-field-title">Tax</Label>
                    <InputGroup>
                        <Form.Select
                            size="sm"
                            as="select"
                            value={details.tax_percent}
                            onChange={e => onChange(({ ...details, tax_percent: e.currentTarget.value }))}
                        >
                            <option value="0">-</option>
                            {taxes.map(t => <option key={t.title} value={t.value}>{t.title}</option>)}
                        </Form.Select>
                        <Button onClick={onDelete} size="sm" variant="link" className="text-danger" >
                            <i className="fas fa-times" />
                        </Button>
                        <Control.Feedback type="invalid">
                            Unit price must not be less than 0.01.
                        </Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}




export {
    InvoiceNotes,
    InvoiceTCs,
    DisplayTotals,
    DisplayInvoiceItems,
    DisplayInvoiceNotes,
    DisplayInvoicePayments,
    PaymentItems,
    ExpenseItems,
    RequisitionItems,
    BillItems
};

export default InvoiceItems;