import { useState } from "react";
import { Button, Tab, Table } from "react-bootstrap"
import { ErrorLoading, PageLoading } from "../../../components/loading";
import { useEffect } from "react";
import { getExpenses, printAnalytics } from "../../../resources/api/dashboard";
import moment from "moment";
import cur from "../../../components/currency";
import { UCWords } from "../../../components/resources";
import { Fragment } from "react";
import { APIURL } from "../../../resources/fetch";
import { errorAlert } from "../../../components/toastr";

const Expenses = () => {


    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [payments, setPayments] = useState();

    const [years, setYears] = useState([]);
    const [totals, setTotals] = useState({});

    const [open, setOpen] = useState({});

    const handleOpen = (e, y) => {
        e.preventDefault();

        setOpen(years => ({ ...years, [y]: !years[y] }));
    }


    const onDownloadClick = () => {

        const data = [];

        years.forEach(y => {

            data.push([
                y,
                Array(12).fill(0).map((i, e) => totals[y][e + 1]),
                totals[y]['total'],
                1
            ]);

            Object.keys(payments[y]).sort().forEach((k, ee) => {
                data.push([
                    ee + 1 + ". " + UCWords(k.replace(/-/g, " ")),
                    Array(12).fill(0).map((i, e) => payments[y][k][e + 1]),
                    payments[y][k]['total'],
                    2
                ]);

            })

        })

        printAnalytics({
            title: "Expenses Report",
            data,
            max_levels: 2
        })
            .then(() => {
                window.open(`${APIURL}/dashboard/analytics/pdf`);
            })
            .catch(errorAlert)

    }


    useEffect(() => {

        getExpenses()
            .then(({ data: { expenses: products_payments } }) => {

                setPayments(products_payments);

                let _years = [], _data = {};

                products_payments.forEach(inc => {
                    let y = parseInt(inc.payment_year),
                        m = parseInt(inc.payment_month),
                        a = parseFloat(inc.amount),
                        p = inc.category;

                    if (_years.indexOf(y) === -1) _years.push(y);


                    if (!(y in _data)) _data[y] = {};

                    if (!(p in _data[y])) _data[y][p] = {};

                    if (!(m in _data[y][p])) _data[y][p][m] = [];

                    _data[y][p][m].push(a);

                })


                setYears(_years.sort((a, b) => (b - a)));
                setOpen(_years.map(y => ({ [y]: true })));

                let _tt = 0, _t = 0;

                for (let y in _data) {
                    for (let p in _data[y]) {
                        _data[y][p]['title'] = p;
                        for (let i = 1; i < 13; i++) {
                            if (!(i in _data[y][p])) {
                                _data[y][p][i] = 0;
                            } else {
                                _t = _data[y][p][i].reduce((p, c) => p + c, 0);

                                _tt += _t;
                                _data[y][p][i] = _t;
                            }
                        }
                        _data[y][p]['total'] = _tt;
                        _tt = 0;
                    }
                }

                let products, _totals = {};
                _tt = 0; _t = 0;

                for (let y in _data) {
                    products = Object.keys(_data[y]);
                    _totals[y] = {};

                    for (let i = 1; i < 13; i++) {
                        _t = products.map(p => _data[y][p][i]).reduce((p, c) => p + c, 0);
                        _totals[y][i] = _t;
                        _tt += _t;
                    }

                    _totals[y]['total'] = _tt;
                    _tt = 0;
                }



                setPayments(_data);
                setTotals(_totals);

            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [])


    if (!loaded) return <PageLoading>Loading all expenses...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Tab.Pane eventKey="expenses">

            <Button variant="secondary" onClick={onDownloadClick}><i className="fas fa-print me-1" />Print</Button>

            <Table responsive size="sm" hover className="mt-3">
                <thead>
                    <tr>
                        <th>Year/Month</th>
                        {Array(12).fill(0).map((i, e) => <th key={e} className="text-center">{moment(e + 1, "M").format("MMM")}</th>)}
                        <th className="text-center">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {years.map(y => (
                        <Fragment key={y}>
                            <tr className="bg-secondary text-white">
                                <td>
                                    <h6 className="my-1">
                                        <a href="#." className="me-2 text-white" onClick={(e) => handleOpen(e, y)}>
                                            <i className="fas fa-plus" />
                                        </a>
                                        {y}
                                    </h6>
                                </td>
                                {Array(12).fill(0).map((i, e) => <td className="text-end fw-bold" key={e}>{totals[y][e + 1] === 0 ? "-" : cur(totals[y][e + 1], 0).format()}</td>)}
                                <td className="ps-4 fw-bold text-end">{cur(totals[y]['total'], 0).format()}</td>
                            </tr>

                            {open[y] &&
                                Object.keys(payments[y]).sort()
                                    .map((k, ee) => (
                                        <tr key={`${y}_${k}`}>
                                            <td className="ps-4" style={{ whiteSpace: "nowrap" }}>{ee + 1}. {UCWords(k.replace(/-/g, " "))}</td>
                                            {Array(12).fill(0).map((i, e) => <td className="text-end" key={e}>{payments[y][k][e + 1] === 0 ? "-" : cur(payments[y][k][e + 1], 0).format()}</td>)}
                                            <td className="ps-4 fw-bold text-end">{cur(payments[y][k]['total'], 0).format()}</td>
                                        </tr>
                                    ))}
                        </Fragment>
                    ))}
                </tbody>

            </Table>
        </Tab.Pane>
    )
}

export { Expenses };