import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn } from './../../components/pageheader';
import { Form, Table, Card, Row, Col } from 'react-bootstrap';
import { SortButton } from './../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from './../../components/pagination';
import moment from 'moment';
import currency, { UGX } from './../../components/currency';
import { Link } from 'react-router-dom';
// import { UCWords } from './../../components/resources';
import { useScreenType } from './../../components/custom-hooks';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { payrollTypes } from '../../assets/constants';
import { getStaffPayments } from '../../resources/api/payroll';

/**
 * View table items on screens larger than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/payroll').StaffPaymentObject[]
 * currentPage: number
 * noPageItems: number
 * }} param0 
 */
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems }) => {
    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "25%" }} />
                <col span="1" style={{ width: "17%" }} />
                {/* <col span="1" style={{ width: "25%" }} /> */}
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Ref <SortButton field="reference" {...srt} handleSort={handleSort} /></th>
                    <th>Date <SortButton field="date_added" {...srt} handleSort={handleSort} /></th>
                    <th>Category <SortButton field="payroll_type" {...srt} handleSort={handleSort} /></th>
                    <th style={{ textAlign: 'right' }}>Amount <SortButton field="total_amount" {...srt} handleSort={handleSort} /></th>
                    {/* <th>Status <SortButton field="status" {...srt} handleSort={handleSort} /></th> */}
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td>
                            <Link to={e.payroll_type === "event-labour" ? `/app/events/${e.event_id}/labour` : `/app/staff-payments/${e.id}`}>
                                {e.reference}
                            </Link>
                        </td>
                        <td>{moment(e.date_added).format("DD MMM YYYY")}</td>
                        <td>{payrollTypes[e.payroll_type].title}</td>
                        <td style={{ textAlign: 'right' }}>{currency(e.total_amount).format()}</td>
                        {/* <td>{UCWords(e.status.replace(/-/g, " "))}</td> */}
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/payroll').StaffPaymentObject[]
 * }} param0 
 */
const ViewMobile = ({ srt, handleSort, items }) => {
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Reference <SortButton field="reference" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Date <SortButton field="date_added" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Category <SortButton field="category" {...srt} handleSort={handleSort} />
                </span>
                {/* <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Status <SortButton field="status" {...srt} handleSort={handleSort} />
                </span> */}
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Amount <SortButton field="total_amount" {...srt} handleSort={handleSort} />
                </span>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-3">
                            <Link
                                to={e.payroll_type === "event-labour" ? `/app/events/${e.event_id}/labour` : `/app/staff-payments/${e.id}`}
                                className="lead text-secondary stretched-link">
                                {e.reference}
                            </Link>
                            <span className="text-muted">{moment(e.date_added).format("DD MMM YYYY")}</span>
                        </div>

                        <div>
                            <h4 className="h4">{UGX(e.total_amount).format()}</h4>
                            <span className="lead fw-bold">
                                {payrollTypes[e.payroll_type].title}
                            </span> <br />
                        </div>

                        {/* <div className="d-flex justify-content-between mt-3">
                            <span></span>
                            <span className="text-muted font-weight-normal">{UCWords(e.status.replace(/-/g, " ").toLowerCase())}</span>
                        </div> */}
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}


const ViewPayments = () => {

    const navItems = [{
        title: 'Staff Payments'
    }];

    const [srt, setSrt] = useState({
        currentField: 'date_added',
        currentOrder: 'desc'
    });

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'reference' });

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);


    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {
        const v = search.val, f = search.field;
        if (v.length < 2 || f === "") return setItems(orginalItems);
        setItems([...orginalItems.filter(i => i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1)]);
    }, [search.val, search.field, orginalItems]);


    /**
     * get the items
     */
    useEffect(() => {

        getStaffPayments(true)
            .then(({ payments }) => {
                setOriginalItems(payments);
                setItems(payments);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => {
            if (srt.currentOrder === 'asc') {
                return (a[srt.currentField] > b[srt.currentField]) ? 1 : ((a[srt.currentField] < b[srt.currentField]) ? -1 : 0);
            }
            return (a[srt.currentField] < b[srt.currentField]) ? 1 : ((a[srt.currentField] > b[srt.currentField]) ? -1 : 0);
        }

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }

    if (!loaded) return <PageLoading>Loading all staff payments...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1000">
                <Title>Staff Payments</Title>
                <ActionBar>
                    <Btn href="/app/staff-payments/new">
                        <i className="fas fa-plus-circle me-1" />New Staff Payment
                    </Btn>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">

                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="Search For" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="category">Category</option>
                            <option value="reference">Reference</option>
                            <option value="status">Status</option>
                        </Form.Select>
                    </Col>

                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} /> :
                    <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>

        </>
    )
}


export default ViewPayments;