import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Form, Table, Card, Row, Col, Dropdown } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from '../../components/pagination';
import moment from 'moment';
import currency, { UGX } from '../../components/currency';
import { Link, useSearchParams } from 'react-router-dom';
import { UCWords } from '../../components/resources';
import { useScreenType } from '../../components/custom-hooks';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { getRequisitions } from '../../resources/api/requisitions';

/**
 * View table items on screens larger than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/requisitions').RequisitionObject[]
 * currentPage: number
 * noPageItems: number
 * }} param0 
 */
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems }) => {
    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            <colgroup>
                {/* <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "17%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "18%" }} /> */}
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Ref <SortButton field="reference" {...srt} handleSort={handleSort} /></th>
                    <th>User <SortButton field="display_name" {...srt} handleSort={handleSort} /></th>
                    <th>Date <SortButton field="requisition_date" {...srt} handleSort={handleSort} /></th>
                    <th style={{ textAlign: 'right' }}>Amount <SortButton field="total_amount" {...srt} handleSort={handleSort} /></th>
                    <th>Approved On <SortButton field="date_approved" {...srt} handleSort={handleSort} /></th>
                    <th>Paid On <SortButton field="date_paid" {...srt} handleSort={handleSort} /></th>
                    <th style={{ whiteSpace: "nowrap" }}>Status <SortButton field="status" {...srt} handleSort={handleSort} /></th>
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td><Link to={`/app/requisitions/${e.id}`}>{e.reference}</Link></td>
                        <td>{e.display_name}</td>
                        <td>{moment(e.requisition_date).format("DD MMM YYYY")}</td>
                        <td style={{ textAlign: 'right' }}>{currency(e.total_amount).format()}</td>
                        <td>{!!e.date_approved ? moment(e.date_approved).format("DD MMM YYYY") : "n/a"}</td>
                        <td>{!!e.date_paid ? moment(e.date_paid).format("DD MMM YYYY") : "n/a"}</td>
                        <td>{UCWords(e.status.replace(/-/g, " "))}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/requisitions').RequisitionObject[]
 * }} param0 
 */
const ViewMobile = ({ srt, handleSort, items }) => {
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Reference <SortButton field="reference" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Date <SortButton field="requisition_date" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Amount <SortButton field="total_amount" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Approved On <SortButton field="date_approved" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Paid On <SortButton field="date_paid" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Status <SortButton field="status" {...srt} handleSort={handleSort} />
                </span>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-3">
                            <Link
                                to={`/app/requisitions/${e.id}`}
                                className="lead text-secondary font-weight-normal stretched-link"
                            >
                                {e.reference}
                            </Link>
                            <span className="text-muted">{moment(e.expense_date).format("DD MMM YYYY")}</span>
                        </div>

                        <div>
                            <span className="lead d-block">
                                {e.display_name}
                            </span>
                            <h4 className="h4">{UGX(e.total_amount).format()}</h4>
                            {!!e.date_approved &&
                                <span className="d-block">
                                    Approved on {moment(e.date_approved).format("DD MMM YYYY")}
                                </span>}
                            {!!e.date_paid &&
                                <span className="d-block">
                                    Paid on {moment(e.date_paid).format("DD MMM YYYY")}
                                </span>}

                        </div>

                        <div className="d-flex justify-content-between mt-3">
                            <span />
                            <span className="text-muted font-weight-normal">{e.status.replace(/-/g, " ").toLowerCase()}</span>
                        </div>
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}


const ViewRequisitions = () => {

    const navItems = [{
        title: 'Requisitions'
    }];

    const [srt, setSrt] = useState({
        currentField: 'requisition_date',
        currentOrder: 'desc'
    });


    const [searchParams] = useSearchParams();

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'reference' });

    const [view, setView] = useState("all");

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);

    useEffect(() => {

        const _view = searchParams.get("view") || "all";
        setView(_view);

    }, [searchParams])


    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {

        let view_items = [...orginalItems];

        switch (view) {
            case "draft":
                view_items = view_items.filter(i => (['draft'].indexOf(i.status) !== -1));
                break;
            case "unapproved":
                view_items = view_items.filter(i => (['submitted'].indexOf(i.status) !== -1));
                break;
            case "approved":
                view_items = view_items.filter(i => (['approved'].indexOf(i.status) !== -1));
                break;
            case "processed":
                view_items = view_items.filter(i => (['processed'].indexOf(i.status) !== -1));
                break;
            default:
        }


        const v = search.val, f = search.field;
        if (v.length < 2 || f === "")
            view_items = [...view_items.filter(i => i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1)];


        return setItems(view_items);
    }, [search.val, search.field, orginalItems, view]);


    /**
     * get the items
     */
    useEffect(() => {

        getRequisitions(true)
            .then(({ requisitions }) => {
                // expenses = expenses.map(e => ({ ...e, type: e.isBill ? "bill" : "expense" }));
                setOriginalItems(requisitions);
                setItems(requisitions);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => {
            if (srt.currentOrder === 'asc') {
                return (a[srt.currentField] > b[srt.currentField]) ? 1 : ((a[srt.currentField] < b[srt.currentField]) ? -1 : 0);
            }
            return (a[srt.currentField] < b[srt.currentField]) ? 1 : ((a[srt.currentField] > b[srt.currentField]) ? -1 : 0);
        }

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }


    const views = {
        all: "All",
        draft: "Unsubmitted",
        unapproved: "Submitted",
        approved: "Approved",
        processed: "Paid"
    }

    if (!loaded) return <PageLoading>Loading all requisitions...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1000">
                <Title>Requisitions</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn href="/app/requisitions/process" title="Process Requisitions">
                            <i className="fab fa-cc-visa" />
                        </Btn>
                        <Btn href="/app/requisitions/payments" title="View Payments">
                            <i className="fas fa-hand-holding-usd" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href="/app/requisitions/new" title="New Requisition">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>

                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <Row>
                    <Col>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic">
                                View: <span className='fw-bold'>{views[view]}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {Object.keys(views).map(v => <Dropdown.Item as={Link} key={v} to={`/app/requisitions?view=${v}`}>{views[v]}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>

                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="Search For" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="reference">Reference</option>
                            <option value="display_name">User</option>
                            <option value="requisition_date">Date</option>
                        </Form.Select>
                    </Col>

                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} /> :
                    <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>

        </>
    )
}


export default ViewRequisitions;