import { APIURL, deleleData, getData, postData } from "../fetch";

/**
 * 
 * @typedef {{
 * id: string,
 * refuel_date: string,
 * odometer_reading: number
 * vehicle_id: string
 * station: string 
 * no_litres: number 
 * unit_cost: number 
 * total_cost: number 
 * expense_id: string 
 * date_added: string
 * added_by: string
 * account_id: string
 * method: string
 * }} RefuelObject 
 */

/**
 * 
 * @param {RefuelObject} data 
 * @returns {Promise<{code: number, message: string, refuel: RefuelObject}>}
 */
const createRefuel = (data) => {
    return postData({
        url: `${APIURL}/refuels`,
        data
    })
}

/**
 * 
 * @param {{account_id: string, method: string}} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, refuel: RefuelObject}>}
 */
const updateRefuelExpense = (data, id) => {
    return postData({
        url: `${APIURL}/refuels/${id}/expense`,
        data
    })
}

/**
 * 
 * @param {RefuelObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, refuel: RefuelObject}>}
 */
const updateRefuel = (data, id) => {
    return postData({
        url: `${APIURL}/refuels/${id}`,
        data
    })
}

/**
 * 
 * @param {RefuelObject} conditions 
 * @returns {Promise<{code: number, message: string, refuels: RefuelObject[]}>}
 */
const getRefuels = (conditions = null) => {

    let obj = { url: `${APIURL}/refuels` }
    if (conditions) obj.data = conditions;
    return getData(obj);
}

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, refuel: RefuelObject}>}
 */
const getRefuel = (id, includes = null) => {

    const obj = { url: `${APIURL}/refuels/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteRefuel = id => deleleData({ url: `${APIURL}/refuels/${id}` })


export {
    createRefuel,
    getRefuel,
    getRefuels,
    updateRefuel,
    updateRefuelExpense,
    deleteRefuel
}