import Nav from '../../components/breadcrumb';
import PageHeader, { ActionBar, ActionButton, Title } from '../../components/pageheader';
import { Form, Table, Card, Row, Col, Button } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from '../../components/pagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useScreenType } from '../../components/custom-hooks';
import { ErrorLoading, PageLoading } from '../../components/loading';
import cur from '../../components/currency';
import { getHires } from '../../resources/api/vehicle-hire';
import HireForm from './hire-form';


const form_defaults = {
    bill_date: "",
    unit: "",
    no_units: "",
    unit_cost: ""
}


/**
 * View table items on screens larger than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/vehicle-hire').HireObject[]
 * currentPage: number
 * noPageItems: number
 * }} param0 
 */
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems, onClickEdit, vehicle_id }) => {
    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            {/* <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "17%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
            </colgroup> */}
            <thead>
                <tr>
                    <th>#</th>
                    <th>Date <SortButton field="bill_date" {...srt} handleSort={handleSort} /></th>
                    <th>Qty <SortButton field="no_units" {...srt} handleSort={handleSort} /></th>
                    <th>Per <SortButton field="unit" {...srt} handleSort={handleSort} /></th>
                    <th style={{ textAlign: 'right' }}>Rate <SortButton field="unit_cost" {...srt} handleSort={handleSort} /></th>
                    <th style={{ textAlign: 'right' }}>Total <SortButton field="total_cost" {...srt} handleSort={handleSort} /></th>
                    <th>Signed Off <SortButton field="signoff_date" {...srt} handleSort={handleSort} /></th>
                    <th>Paid <SortButton field="isPaid" {...srt} handleSort={handleSort} /></th>
                    <th>...</th>
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td><Link to={`/app/vehicles/${vehicle_id}/hires/${e.id}`}>
                            {moment(e.bill_date).format("DD MMM YYYY")}
                        </Link></td>
                        <td>{e.no_units}</td>
                        <td>{e.unit}</td>
                        <td style={{ textAlign: 'right' }}>{cur(e.unit_cost, 0).format()}</td>
                        <td style={{ textAlign: 'right' }}>{cur(e.total_cost, 0).format()}</td>
                        <td>{!!e.signoff_date ? moment(e.signoff_date).format("DD MMM YYYY") : "No"}</td>
                        <td>{e.isPaid ? "Yes" : "No"}</td>
                        <td>
                            {!e.signoff_date &&
                                <Button variant="link" size="sm" className='text-danger p-0 me-2' onClick={() => onClickEdit(e.id)}>
                                    <i className="fas fa-pencil-alt" />
                                </Button>
                            }

                        </td>
                    </tr>
                ))}
            </tbody>
        </Table >
    )
}

/**
 * view items on screens smaller than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/vehicle-hire').HireObject[]
 * }} param0 
 */
const ViewMobile = ({ srt, handleSort, items, onClickEdit, vehicle_id }) => {
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Date <SortButton field="bill_date" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Station <SortButton field="unit" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    No Units <SortButton field="no_units" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Rate <SortButton field="unit_cost" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Total <SortButton field="total_cost" {...srt} handleSort={handleSort} />
                </span>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div>
                            <h4 className="h4 d-flex justify-content-between">
                                <Link to={`/app/vehicles/${vehicle_id}/hires/${e.id}`}>
                                    {moment(e.bill_date).format("DD MMM YYYY")}
                                </Link>
                                <span>
                                    {!e.signoff_date &&
                                        <Button variant="link" size="sm" className='text-danger p-0 me-2' onClick={() => onClickEdit(e.id)}>
                                            <i className="fas fa-pencil-alt" />
                                        </Button>}
                                </span>

                            </h4>
                            <p className="mb-1"><i className='fas fa-hashtag fa-fw me-1' /> {e.no_units} per {e.unit}</p>
                            <p className="mb-1"><i className='fas fa-at fa-fw me-1' /> {cur(e.unit_cost, 0).format()}</p>
                            <p className="mb-1"><i className='fas fa-receipt fa-fw me-1' /> {cur(e.total_cost, 0).format()}</p>
                            <p className="mb-1"><i className='fas fa-pen-nib fa-fw me-1' /> {!!e.signoff_date ? moment(e.signoff_date).format("DD MMM YYYY") : "No"}</p>
                            <p className="mb-1"><i className='fas fa-check-circle fa-fw me-1' /> {e.isPaid ? "Yes" : "No"}</p>
                        </div>
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}


/**
 * 
 * @param {Object} props 
 * @param {import('../../resources/api/vehicles').VehicleObject} props.details
 * @returns 
 */
const ViewHires = ({ details }) => {

    const nav_items = [
        { title: 'Vehicles', href: '/app/vehicles' },
        { title: details.reg_no, href: `/app/vehicles/${details.id}` },
        { title: 'Hires' }
    ];

    const [srt, setSrt] = useState({
        currentField: 'bill_date',
        currentOrder: 'desc'
    });

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'bill_date' });

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);

    const [show, setShow] = useState(false);
    const [edit_action, setEditAction] = useState("create");

    const [hireDetails, setHireDetails] = useState(form_defaults);


    const onClickEdit = id => {
        let details = orginalItems.find(i => i.id === id);
        setHireDetails(details);
        setEditAction("edit");
        setShow(true);
    }

    const onClickNew = () => {
        setHireDetails(form_defaults);
        setEditAction("create");
        setShow(true);
    }

    const onUploadRefuel = details => {
        if (edit_action === "create") {
            setOriginalItems(items => items.concat(details));
        } else {
            let items = [...orginalItems];

            items.splice(
                items.findIndex(i => i.id === hireDetails.id),
                1,
                details
            );
            setOriginalItems(items);
        }
    }




    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {
        const v = search.val, f = search.field;
        if (v.length < 2 || f === "") return setItems(orginalItems);
        setItems([...orginalItems.filter(i => i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1)]);
    }, [search.val, search.field, orginalItems]);


    /**
     * get the items
     */
    useEffect(() => {

        getHires({ vehicle_id: details.id })
            .then(({ hires }) => {
                setOriginalItems(hires);
                setItems(hires);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [details.id])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => {
            if (srt.currentOrder === 'asc') {
                return (a[srt.currentField] > b[srt.currentField]) ? 1 : ((a[srt.currentField] < b[srt.currentField]) ? -1 : 0);
            }
            return (a[srt.currentField] < b[srt.currentField]) ? 1 : ((a[srt.currentField] > b[srt.currentField]) ? -1 : 0);
        }

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }

    if (!loaded) return <PageLoading>Loading all hiring records...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    if(!details.isExternal) return <ErrorLoading>This vehicle is owned and therefore cannot have hire records.</ErrorLoading>;


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>Hire Records</Title>
                <ActionBar>
                    <ActionButton onClick={onClickNew}>
                        <i className='fas fa-plus-circle me-2' />New Hire
                    </ActionButton>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">

                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="Search For" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="bill_date">Bill Date</option>
                        </Form.Select>
                    </Col>

                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop
                        srt={srt}
                        handleSort={handleSort}
                        items={displayItems}
                        noPageItems={noPageItems}
                        currentPage={PAGE}
                        onClickEdit={onClickEdit}
                        vehicle_id={details.id}
                    /> :
                    <ViewMobile
                        srt={srt}
                        handleSort={handleSort}
                        items={displayItems}
                        onClickEdit={onClickEdit}
                        vehicle_id={details.id}
                    />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>

            <HireForm
                hireDetails={{ details: hireDetails, setDetails: setHireDetails }}
                action={edit_action}
                show={{ show, setShow }}
                onUpload={onUploadRefuel}
                vehicle_id={details.id}
            />

        </>
    )
}


export default ViewHires;