import fetchData, { APIURL, getData, postData } from "../fetch";


/**
 * 
 * @typedef {{
 *  id: string 
 *  payment_id: string
 *  bill_id: string
 *  amount: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string,
 *  receipt_no: string
 *  bill_amount: number
 *  bill_reference: string
 *  paid_amount: number
 *  bill_balance: number
 * }} PaymentItemObject
 */

/**
 * 
 * @typedef {{
 *  id: string 
 *  reference: string
 *  receipt_no: string
 *  vendor_id: string
 *  vendor: string | import("./vendors").VendorObject
 *  payment_date: string
 *  method: string
 *  account_id: string
 *  account: string|import("./accounts").AccountObject
 *  receipt_file_id: string
 *  total_amount: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string 
 *  items: PaymentItemObject[]
 * }} PaymentObject
 */




/**
 * 
 * @param {PaymentObject} data 
 * @returns {Promise<{code: number, message: string, payment: PaymentObject}>}
 */
const createPayment = data => {
    return postData({
        url: `${APIURL}/bill-payments`,
        data
    })
}

/**
 * 
 * @param {PaymentObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, payment: PaymentObject}>}
 */
const updatePayment = (data, id) => {
    return postData({
        url: `${APIURL}/bill-payments/${id}`,
        data
    })
}

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, bill-payments: PaymentObject[]}>}
 */
const getPayments = (getAll = false, conditions = null) => {

    const obj = { url: `${APIURL}/bill-payments`, data: {} }

    if (getAll) obj.data = { expanded: 1 }
    if (conditions) obj.data = { ...(obj.data || {}), ...conditions }

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, payment: PaymentObject}>}
 */
const getPayment = (id, includes = null) => {

    const obj = { url: `${APIURL}/bill-payments/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}



/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deletePayment = id => fetchData({ url: `${APIURL}/bill-payments/${id}`, method: "DELETE" })




export {
    createPayment,
    getPayment,
    getPayments,
    updatePayment,
    deletePayment,
}