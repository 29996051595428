import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Table } from 'react-bootstrap';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import moment from "moment";
import cur from '../../components/currency';
import { ConfirmAction } from '../../components/prompt';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { AppUserContext } from '../../App';
// import { APIURL } from '../../resources/fetch';
import { ExecutePayment } from './processPayment';
import { getHire, signoffHire } from '../../resources/api/vehicle-hire';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * @param {import('../../resources/api/vehicle-hire').HireObject} props.details
 * 
 */
const ViewHire = ({ details, setDetails }) => {

    const { profile } = useContext(AppUserContext);

    const nav_items = [
        { title: 'Vehicles', href: '/app/vehicles' },
        { title: details.vehicle.reg_no, href: `/app/vehicles/${details.vehicle.id}` },
        { title: "Hires", href: `/app/vehicles/${details.vehicle.id}/hires` },
        { title: "Details" }
    ];

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };

    const signOff = () => {

        infoAlert("Getting the activity signed off ...");

        signoffHire(details.id)
            .then(({ hire, message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, ...hire }));
            })
            .catch(e => errorAlert(e))
    }

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    {details.reference} {!!details.user_reference && <>({details.user_reference})</>}
                </Title>
                <ActionBar>

                    {(!!details.signoff_date && (!details.isPaid) && (profile.permission_level < 3)) &&
                        <ButtonToolbar>
                            <ExecutePayment
                                hire_id={details.id}
                                onUpload={hire => setDetails(d => ({ ...d, ...hire }))}
                            />
                        </ButtonToolbar>
                    }

                    {(!details.signoff_date && (profile.permission_level === 1)) &&
                        <ButtonToolbar>
                            <ConfirmAction
                                as={Btn}
                                title="Sign Off"
                                handleAction={signOff}
                                confirmationText='Are you sure you want to sign off this activity?'
                            >
                                <i className="fas fa-check-circle" />
                            </ConfirmAction>
                        </ButtonToolbar>
                    }
                </ActionBar>
            </PageHeader>

            <div className="max-800 p-1 expenses">

                <div className="text-end">
                    VEHICLE: <span style={style1}>{details.vehicle.reg_no}</span> <br />
                    BILL DATE: <span style={style1}>{moment(details.bill_date).format("DD MMMM YYYY")}</span> <br />
                </div>

                <div className="my-3">
                    <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                        UGX <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(details.total_cost, 0).format()}</span>
                    </span>
                </div>


                <Table size="sm" borderless style={{ maxWidth: '600px' }}>
                    <colgroup>
                        <col span="1" style={{ width: "40%" }} />
                        <col span="1" style={{ width: "60%" }} />
                    </colgroup>

                    <tbody>
                        <tr>
                            <td>Units</td>
                            <td>{details.no_units} {details.unit}s </td>
                        </tr>
                        <tr>
                            <td>Unit Cost</td>
                            <td>{cur(details.unit_cost, 0).format()} </td>
                        </tr>
                        <tr>
                            <td>Signed Off</td>
                            <td>
                                {!details.signoff_date ?
                                    "Not Approved" :
                                    <span>By {details.signed_off_contact.display_name} on {moment(details.signoff_date).format("DD MMMM YYYY")}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Paid</td>
                            <td>
                                {!details.isPaid ?
                                    "Not Paid" :
                                    <span>
                                        Paid on {moment(details.expense.expense).format("DD MMMM YYYY")}
                                        <Link className='d-block' to={`/app/expenses/${details.expense_id}`}>View Expense</Link>
                                    </span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>


            </div>
        </>
    )
}

const HireDetails = () => {

    const { hireid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getHire(hireid, ['expense', 'signed_off_contact', 'vehicle'])
            .then(({ hire }) => {
                setDetails(hire);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [hireid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading hire details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/" element={<ViewHire details={details} setDetails={setDetails} />} />
        </Routes>
    )

}

export default HireDetails;