import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import NewMaintenance from './maintenance-manipulation';
import ViewMaintenances from './view-maintenances';
import MaintenanceDetails from './view-maintenance';

const Maintenance = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to access requisitions.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="new" element={<NewMaintenance />} />
            {/* <Route path="payments/:paymentid" element={<ViewRequisitionPayment />} /> */}
            <Route path=":maintenanceid/*" element={<MaintenanceDetails />} />
            <Route path="/" element={<ViewMaintenances />} />
        </Routes>
    )
};

export default Maintenance;