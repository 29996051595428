import { useEffect, useState, useContext } from "react";
import { ErrorLoading, PageLoading } from "../components/loading";
import { getIncomes } from "../resources/api/dashboard";
import { getEvents } from "../resources/api/events";
import { getExpenses } from "../resources/api/dashboard";
import ProductsIncome from "./dashboard/ProductsIncome";
import IncomeExpenditure from "./dashboard/IncomeExpenditure";
import { EventCalendar } from "./events/view-events";
import { AppUserContext } from "../App";
import { Link } from "react-router-dom";


const Dashboard = () => {


    const [events, setEvents] = useState([]);

    const [error, setError] = useState();
    const [loaded, setLoaded] = useState();
    const [dash_data, setDashData] = useState({ expenses: [], incomes: [] })
    const { profile } = useContext(AppUserContext);


    /**
     * get the items
     */
    useEffect(() => {

        getEvents(true)
            .then(({ events, event_dates }) => {
                setEvents([...events, ...event_dates.map(e => ({
                    ...e,
                    __id: e.id,
                    id: e.event_id,
                    event_date: e.start,
                    end_date: e.end,
                    amount: parseFloat(e.amount)
                }))]);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [])

    useEffect(() => {

        if (profile.permission_level === 1) {
            Promise.all([
                getIncomes(),
                getExpenses()
            ]).then(([incomes, expenses]) => {
                setDashData({ incomes: incomes.data.incomes, expenses: expenses.data.expenses })
            })
        }


    }, [profile.permission_level])


    if (!loaded) return <PageLoading>Loading the events ...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <>
            <EventCalendar events={events} />

            {(profile.permission_level < 2) &&
                <div className="max-1200 my-5">
                    <IncomeExpenditure incomes={dash_data.incomes} expenses={dash_data.expenses} />
                    <ProductsIncome incomes={dash_data.incomes} />

                    <div>
                        <Link to="/app/analytics" className="h5">View More Analytics</Link>
                    </div>
                </div>
            }

        </>
    );

}

export default Dashboard;