import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ListGroup, Table, Modal, Form, Row, Col, Button, Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Routes, Route, useParams, Link, useNavigate } from "react-router-dom";
import { AppUserContext } from "../../App";
import Nav from '../../components/breadcrumb';
import { CancelButton, SubmitButton } from "../../components/btns";
import cur from "../../components/currency";
import DateTimeSelector, { DateSelector } from "../../components/datetimepicker";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { UCWords, sortFunction } from "../../components/resources";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import { changeEventStatus, createDates, createImage, createNote, deleteDate, deleteEvent, deleteImage, deleteNote, getEvent, updateSetup } from "../../resources/api/events";
import { APIURL } from "../../resources/fetch";
import EventLabour from "./event-labour";
import { EditEvent } from "./event-manipulation";
import { FileUpload } from "../../components/files";

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/events").EventObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import("../../resources/api/events").EventObject>>} props.setDetails
 */
const ViewDetails = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Events', href: "/app/events" },
        { title: details.reference }
    ]

    const { profile } = useContext(AppUserContext);
    const [key, setKey] = useState("products");

    const navigate = useNavigate();

    const addNote = (details) => {
        setDetails(d => ({ ...d, notes: d.notes.concat(details) }))
    }

    const addImage = (details) => {
        setDetails(d => ({ ...d, images: d.images.concat(details) }))
    }

    const addDates = (details) => {
        setDetails(d => ({ ...d, event_dates: d.event_dates.concat(details) }))
    }

    const handleEventDelete = () => {

        infoAlert('Deleting event, please hold...');

        deleteEvent(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate("/app/events");
            })
            .catch(e => errorAlert(e));
    }

    const handleDelete = id => {

        infoAlert('Deleting note, please hold...');

        deleteNote(id)
            .then(({ message }) => {
                setDetails(d => ({ ...d, notes: d.notes.filter(n => n.id !== id) }));
                successAlert(message);
            })
            .catch(e => errorAlert(e));

    }


    const handleImageDelete = id => {

        infoAlert('Deleting image, please hold...');

        deleteImage(details.id, id)
            .then(({ message }) => {
                setDetails(d => ({ ...d, images: d.images.filter(n => n.id !== id) }));
                successAlert(message);
            })
            .catch(e => errorAlert(e));

    }

    const changeStatus = newstatus => {
        infoAlert(`Changing event status to ${newstatus}`);

        changeEventStatus(details.id, newstatus)
            .then(({ message, event }) => {
                successAlert(message);
                setDetails(d => ({ ...d, ...event }));
            })
            .catch(e => errorAlert(e));

    }

    const handleDateDelete = (e, id) => {
        e.preventDefault();

        infoAlert('Deleting dates, please hold...');

        deleteDate(details.id, id)
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, event_dates: d.event_dates.filter(ed => ed.id !== id) }));
            })
            .catch(e => errorAlert(e));
    }

    const updateProduct = product => {
        let products = [...details.products];

        products.splice(
            products.findIndex(p => p.id === product.id),
            1,
            product
        );

        setDetails(d => ({ ...d, products: products }))
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.reference}</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn title="Download Invoice" href={`${APIURL}/events/${details.id}/pdf`} isExternalLink>
                            <i className="fas fa-file-invoice" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/events/${details.id}/labour`} title="Event's Labour">
                            <i className="fas fa-hard-hat" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <AddDates eventid={details.id} onCreate={addDates} />
                        <Btn href={`/app/payments/new?event=${details.id}`} title="Add Payment">
                            <i className="fas fa-credit-card" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        {(details.status !== "unconfirmed") &&
                            <Btn onClick={() => changeStatus('unconfirmed')} title="Unconfirm Event">
                                <i className="fas fa-thumbs-down" />
                            </Btn>
                        }
                        {(details.status !== "confirmed") &&
                            <Btn onClick={() => changeStatus('confirmed')} title="Confirm Event">
                                <i className="fas fa-thumbs-up" />
                            </Btn>
                        }
                        {(details.status !== "cancelled") &&
                            <Btn onClick={() => changeStatus('cancelled')} title="Cancel Event">
                                <i className="fas fa-times-circle" />
                            </Btn>
                        }



                        {(profile.permission_level === 1) &&
                            <Btn onClick={handleEventDelete} title="Delete Event">
                                <i className="fas fa-trash-alt" />
                            </Btn>
                        }
                    </ButtonToolbar>

                    <ButtonToolbar>
                        <Btn href={`/app/events/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/events/new" title="New Event">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <h4>Event Details</h4>
                <Row>
                    <Col sm={6}>
                        <dl>
                            <dt>Status</dt>
                            <dd>{UCWords(details.status)}</dd>
                            <dt>Client</dt>
                            <dd>
                                <Link to={`/app/clients/${details.client_id}`}>
                                    {details.client.title}
                                </Link>
                            </dd>
                            <dt>Dates</dt>
                            <dd>
                                {moment(details.event_date).format("dddd DD MMMM YYYY")}
                                {(details.end_date !== details.event_date) &&
                                    <span> to {moment(details.end_date).format("dddd DD MMMM YYYY")}</span>}

                                {details.event_dates.map(d => (
                                    <div key={d.id}>
                                        {moment(d.start).format("ddd DD MMM YYYY")}
                                        {(d.end !== d.start) &&
                                            <span> - {moment(d.end).format("ddd DD MMM YYYY")}</span>}
                                        <a className="ms-2 ms-sm-4 text-danger small" href="#." onClick={e => handleDateDelete(e, d.id)}>
                                            <i className="fas fa-times me-1" />Delete
                                        </a>
                                    </div>
                                ))}
                            </dd>
                            <dt>Location</dt>
                            <dd>{details.location}</dd>
                            <dt>Category</dt>
                            <dd>{UCWords(details.category.replace(/-/g, " "))}</dd>
                            <dt>Classification</dt>
                            <dd>{UCWords(details.classification
                                .replace(/-/g, " "))}</dd>
                        </dl>
                    </Col>
                    {(profile.permission_level < 4) &&
                        <Col sm={6}>
                            <dl>

                                <>
                                    <dt>Total Amount</dt>
                                    <dd>UGX {cur(details.amount, 0).format()}</dd>
                                    <dt>Total Paid</dt>
                                    <dd>UGX {cur(details.payment_amount, 0).format()}</dd>
                                    <dt>Balance</dt>
                                    <dd>UGX {cur(details.balance, 0).format()}</dd>
                                </>

                            </dl>
                        </Col>
                    }
                </Row>

                <Notes
                    eventid={details.id}
                    notes={details.notes || []}
                    addNote={addNote}
                    deleteNote={handleDelete}
                />

                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-2"
                >
                    <Tab eventKey="products" title="Products">
                        <Products products={details.products} />
                    </Tab>
                    <Tab eventKey="setup" title="Set Up">
                        <ProductSetUp products={details.products} onUpdateProduct={updateProduct} />
                    </Tab>
                    {(profile.permission_level < 4) &&
                        <Tab eventKey="payments" title="Payments">
                            <Payments eventid={details.id} payments={details.payments} />
                        </Tab>}
                    {(profile.permission_level < 4) &&
                        <Tab eventKey="bills" title="Bills">
                            <Bills eventid={details.id} clientid={details.client_id} bills={details.bills} />
                        </Tab>}
                    <Tab eventKey="pictures" title="Pictures">
                        <Images
                            eventid={details.id}
                            images={details.images || []}
                            addImage={addImage}
                            deleteImage={handleImageDelete}
                        />
                    </Tab>
                </Tabs>

            </div>

        </>
    )

}


/**
 * 
 * @param {Object} props 
 * @param {import("../../resources/api/events").EventProductObject[]} props.products
 * @returns 
 */
const Products = ({ products }) => {

    const { profile } = useContext(AppUserContext);

    return (
        <>
            <h4 className="mt-2">Products</h4>
            <Table responsive hover size="sm" style={{ maxWidth: "550px" }}>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th className="text-center">Dimensions</th>
                        <th className="text-center">Days</th>
                        <th className="text-center">Qty</th>
                        {(profile.permission_level < 4) &&
                            <>
                                <th className="text-center">Unit Cost</th>
                                <th className="text-end">Amount</th>
                                <th className="text-end">Balance</th>
                            </>
                        }

                    </tr>
                </thead>
                <tbody>
                    {
                        products.map(p => (
                            <tr key={p.id}>
                                <td>{p.product}</td>
                                <td className="text-center">{p.dimensions || <span className="text-muted">n/a</span>}</td>
                                <td className="text-center">{p.no_days}</td>
                                <td className="text-center">{p.qty}</td>
                                {(profile.permission_level < 4) &&
                                    <>
                                        <td className="text-end">{cur(p.unit_price, 0).format()}</td>
                                        <td className="text-end">{cur(p.total_amount, 0).format()}</td>
                                        <td className="text-end">{cur(p.total_amount - p.payment_amount, 0).format()}</td>
                                    </>
                                }
                            </tr>
                        ))
                    }

                </tbody>
            </Table>
        </>
    )
}

/**
 * 
 * @param {Object} props 
 * @param {import("../../resources/api/events").EventProductObject[]} props.products
 * @returns 
 */
const ProductSetUp = ({ products, onUpdateProduct }) => {


    const [details, setDetails] = useState(({ id: "", setup_complete: "", setup_reason: "", }))

    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const formref = useRef();

    const handleClose = () => {
        setDetails({ id: "", setup_complete: "", setup_reason: "", });
        setSubmitting(false);
    }

    const handleSubmit = e => {

        e.preventDefault();

        if (!formref.current.checkValidity()) {
            setValidated(true);
            errorAlert("There are some errors with your inputs.");
            return;
        }

        setValidated(false);

        setSubmitting(true);

        updateSetup(details.id, details)
            .then(({ product }) => {
                setShow(false);
                onUpdateProduct(product);
            })
            .catch(e => errorAlert(e))
            .finally(() => setSubmitting(false))
    }


    const handleEdit = id => {

        let _item = [...products].find(p => p.id === id);
        setDetails(({
            id: _item.id,
            setup_complete: _item.setup_complete || "",
            setup_reason: _item.setup_reason || ""
        }))
        setShow(true);

    }

    return (
        <>
            <h4 className="mt-2">Products Setup</h4>
            <Table responsive hover size="sm" style={{ maxWidth: "600px" }}>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th className="text-center">Dimensions</th>
                        <th className="">Completion Time</th>
                        <th className="">...</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        products.map(p => (
                            <tr key={p.id}>
                                <td>{p.product}</td>
                                <td className="text-center">{p.dimensions || <span className="text-muted">n/a</span>}</td>
                                <td className="">
                                    {!!p.setup_complete ? moment(p.setup_complete).format("DD MMM YYYY, HH:mm") : 'n/a'}
                                    {!!p.setup_reason &&
                                        <OverlayTrigger overlay={<Tooltip id={p.id}>{p.setup_reason}</Tooltip>}>
                                            <i className="fas fa-circle-info ps-1 ps-sm-2" />
                                        </OverlayTrigger>
                                    }
                                </td>
                                <td style={{ width: "5%" }}>
                                    <Button size="sm" onClick={() => handleEdit(p.id)} variant="link" className="text-danger">
                                        <i className="fas fa-pencil-alt" />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </Table>


            <Modal show={show} onExit={handleClose} centered>
                <Modal.Body>
                    <Form ref={formref} noValidate validated={validated} >
                        <Row className="g-2 my-3">
                            <Col sm={12} className="my-1">
                                <Form.Label className="form-field-title">Time of Setup Completion</Form.Label>
                                <DateTimeSelector
                                    value={details.setup_complete}
                                    onChange={date =>
                                        setDetails(d => ({
                                            ...d,
                                            setup_complete: date
                                        }))}
                                    placeholder=""
                                    required={true}
                                />
                                <Form.Control.Feedback type="invalid">
                                    A valid date must be provided.
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={12} className="my-1">
                                <Form.Label className="form-field-title">Comments</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    value={details.setup_reason}
                                    onChange={e => setDetails({ ...details, setup_reason: e.currentTarget.value })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    A valid date must be provided.
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>
                <Modal.Footer className="py-1">
                    <SubmitButton isSubmitting={submitting} onClick={handleSubmit}>
                        Update
                    </SubmitButton>
                    <CancelButton onClick={() => setShow(false)} isSubmitting={submitting}>
                        Cancel
                    </CancelButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}



/**
 * @param {Object} props
 * @param {string} props.eventid 
 * @param {import("../../resources/api/payments").PaymentObject[]} props.payments 
 * @returns 
 */
const Payments = ({ eventid, payments }) => {

    if (payments.length === 0) {
        return (
            <>
                <h4 className="mt-2">
                    Payments
                    <Link
                        className="small ms-3 text-secondary"
                        to={`/app/payments/new?event=${eventid}&return=1`}
                    >
                        <i className="fas fa-plus-circle" />
                    </Link>
                </h4>
                <p className="lead">
                    No payments have been registered for this event.
                </p>
            </>
        )
    }

    return (
        <>

            <h4 className="mt-2">
                Payments
                <Link
                    className="small ms-3 text-secondary"
                    to={`/app/payments/new?event=${eventid}&return=1`}
                >
                    <i className="fas fa-plus-circle" />
                </Link>
                <a
                    className="small ms-3 text-secondary"
                    href={`${APIURL}/events/${eventid}/payments/pdf`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <i className="fas fa-print" />
                </a>
            </h4>

            <Table responsive hover size="sm" style={{ maxWidth: "500px" }}>
                <thead>
                    <tr>
                        <th>Ref</th>
                        <th>Date</th>
                        <th className="text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {payments.map(p => (
                        <tr key={p.id}>
                            <td><Link to={`/app/payments/${p.id}`}>{p.reference}</Link></td>
                            <td>{moment(p.payment_date).format("DD MMM YYYY")}</td>
                            <td className="text-end">{cur(p.amount, 0).format()}</td>
                        </tr>

                    ))}

                </tbody>
            </Table>
        </>
    )
}



/**
 * @param {Object} props
 * @param {string} props.eventid 
 * @param {string} props.clientid 
 * @param {import("../../resources/api/bills").BillObject[]} props.bills 
 * @returns 
 */
const Bills = ({ eventid, clientid, bills }) => {

    if (bills.length === 0) {
        return (
            <>
                <h4 className="mt-2">
                    Bills
                    <Link
                        className="small ms-3 text-secondary"
                        to={`/app/bills/new?event=${eventid}&client=${clientid}&return=1`}
                    >
                        <i className="fas fa-plus-circle" />
                    </Link>
                </h4>
                <p className="lead">
                    No bills have been registered for this event.
                </p>
            </>
        )
    }

    return (
        <>

            <h4 className="mt-2">
                Bills
                <Link
                    className="small ms-3 text-secondary"
                    to={`/app/bills/new?event=${eventid}&client=${clientid}&return=1`}
                >
                    <i className="fas fa-plus-circle" />
                </Link>
            </h4>

            <Table responsive hover size="sm" style={{ maxWidth: "500px" }}>
                <thead>
                    <tr>
                        <th>Vendor</th>
                        <th>Ref</th>
                        <th>Date</th>
                        <th className="text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {bills.map(p => (
                        <tr key={p.id}>
                            <td><Link to={`/app/vendors/${p.vendor_id}`}>{p.vendor}</Link></td>
                            <td><Link to={`/app/bills/${p.id}`}>{p.vendor_reference}</Link></td>
                            <td>{moment(p.bill_date).format("DD MMM YYYY")}</td>
                            <td className="text-end">{cur(p.total_amount, 0).format()}</td>
                        </tr>

                    ))}

                </tbody>
            </Table>
        </>
    )
}

/**
 * @param {Object} props
 * @param {string} props.eventid 
 * @param {import("../../resources/api/events").NoteObject[]} props.notes 
 * @param {(details: import("../../resources/api/events").NoteObject) => void} props.addNote 
 * @param {(id: string) => void} props.deleteNote 
 * @returns 
 */
const Notes = ({ eventid, notes, addNote, deleteNote }) => {

    const { profile } = useContext(AppUserContext);

    const handleDelete = (e, id) => {
        e.preventDefault();
        infoAlert("Just a minute...");
        deleteNote(id);
    }

    if (notes.length === 0) {
        return (
            <>
                <h4 className="mt-2">
                    Notes
                    <NewNote eventid={eventid} onCreate={addNote} />
                </h4>
                <p className="lead">
                    No notes have been added to this event.
                </p>
            </>
        )
    }

    return (
        <>
            <h4 className="mt-2">
                Notes
                <NewNote eventid={eventid} onCreate={addNote} />
            </h4>

            <ListGroup variant="flush">

                {notes.map(n => (
                    <ListGroup.Item className="px-1" key={n.id}>
                        <div className="d-flex justify-content-between align-items-center">
                            <small className="text-muted text-uppercase">
                                {n.display_name}, {moment(n.date_added).format('DD MMMM YYYY, HH:mm')}
                            </small>
                            {(profile.id === n.added_by) &&
                                <a href="#." className="text-danger small" onClick={e => handleDelete(e, n.id)}>
                                    <i className="fas fa-times me-1" />Delete
                                </a>}

                        </div>

                        <p className="mb-1" dangerouslySetInnerHTML={{ __html: n.content.replace(/\n/g, "<br />") }} />
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </>
    )
}


/**
 * @param {Object} props
 * @param {string} props.eventid 
 * @param {import("../../resources/api/events").ImageObject[]} props.images 
 * @param {(details: import("../../resources/api/events").ImageObject) => void} props.addImage 
 * @param {(id: string) => void} props.deleteImage 
 * @returns 
 */
const Images = ({ eventid, images, addImage, deleteImage }) => {

    const { profile } = useContext(AppUserContext);

    const handleUpload = file => {
        infoAlert("Updating Event...");
        createImage(eventid, file.id)
            .then(({ image, message }) => {
                addImage(image);
                successAlert(message);
            })
            .catch(errorAlert)
    }

    const handleDelete = (e, id) => {
        e.preventDefault();
        infoAlert("Just a minute...");
        deleteImage(id);
    }

    if (images.length === 0) {
        return (
            <>
                <h4 className="mt-2">
                    Images
                    <FileUpload
                        accept="image"
                        as="a"
                        className="ms-2"
                        onUpload={handleUpload}
                    >
                        <i className="fas fa-plus-circle" />
                    </FileUpload>
                </h4>
                <p className="lead">
                    No images have been added to this event.
                </p>
            </>
        )
    }

    return (
        <>
            <h4 className="mt-2">
                Images
                <FileUpload
                    accept="image"
                    as="a"
                    className="ms-2"
                    onUpload={handleUpload}
                >
                    <i className="fas fa-plus-circle" />
                </FileUpload>
            </h4>

            <ListGroup variant="flush">

                {images.map(n => (
                    <ListGroup.Item className="px-1" key={n.id}>
                        <div className="d-flex align-items-center">
                            <a href={`${APIURL}/files/${n.file_id}/view/image`} target="_blank" rel="noreferrer">
                                <img src={`${APIURL}/files/${n.file_id}/view/image?w=100&h=100`} alt={n.title} />
                            </a>
                            <div className="ps-2">
                                <p className="lead mb-2">{n.file_title}</p>
                                <p className="text-muted mb-1">
                                    Uploaded By {n.display_name} <br />
                                    Uploaded on {moment(n.date_added).format('DD MMMM YYYY, HH:mm')}
                                </p>
                                {(profile.id === n.added_by) &&
                                    <a href="#." className="text-danger small" onClick={e => handleDelete(e, n.id)}>
                                        <i className="fas fa-times me-1" />Delete
                                    </a>}
                            </div>

                        </div>

                        {/* <p className="mb-1" dangerouslySetInnerHTML={{ __html: n.content.replace(/\n/g, "<br />") }} /> */}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </>
    )
}


/**
 * 
 * @param {Object} props 
 * @param {string} props.eventid 
 * @param {(details: import("../../resources/api/events").NoteObject) => void} props.onCreate 
 * @returns 
 */
const NewNote = ({ eventid, onCreate }) => {

    const [text, setText] = useState("");
    const [show, setShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleClick = e => {
        e.preventDefault();
        setShow(true);
    }

    const handleClose = () => {
        setText("");
        setSubmitting(false);
    }

    const handleSubmit = e => {

        e.preventDefault();

        setSubmitting(true);

        createNote(eventid, text)
            .then(({ note, message }) => {
                setShow(false);
                successAlert(message);
                onCreate(note);
            })
            .catch(e => errorAlert(e))
            .finally(() => setSubmitting(false))
    }

    return (
        <>
            <a className="text-secondary ms-3 small" href="#." onClick={handleClick}>
                <i className="fas fa-plus-circle" />
            </a>

            <Modal show={show} onExit={handleClose} centered>
                <Modal.Body>
                    <Form.Control as="textarea"
                        size="sm"
                        value={text}
                        placeholder="What would you like to say?"
                        onChange={e => setText(e.currentTarget.value)}
                        style={{ height: "200px" }}
                    />

                </Modal.Body>
                <Modal.Footer className="py-1">
                    {(text.length > 10) &&
                        <SubmitButton isSubmitting={submitting} onClick={handleSubmit}>
                            Add Note
                        </SubmitButton>
                    }

                    <CancelButton onClick={() => setShow(false)} isSubmitting={submitting}>
                        Cancel
                    </CancelButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}


/**
 * 
 * @param {Object} props 
 * @param {string} props.eventid 
 * @param {(details: import("../../resources/api/events").DateObject) => void} props.onCreate 
 * @returns 
 */
const AddDates = ({ eventid, onCreate }) => {

    const [details, setDetails] = useState({ start: "", end: "" });
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const formref = useRef();

    const handleClose = () => {
        setDetails({ start: "", end: "" });
        setSubmitting(false);
    }

    const handleSubmit = e => {

        e.preventDefault();

        if (!formref.current.checkValidity()) {
            setValidated(true);
            errorAlert("There are some errors with your inputs.");
            return;
        }

        setValidated(false);

        setSubmitting(true);

        createDates(eventid, details)
            .then(({ event_date, message }) => {
                setShow(false);
                successAlert(message);
                onCreate(event_date);
            })
            .catch(e => errorAlert(e))
            .finally(() => setSubmitting(false))
    }

    return (
        <>
            <Btn title="Add Dates" onClick={() => setShow(true)}>
                <i className="far fa-calendar-plus" />
            </Btn>

            <Modal show={show} onExit={handleClose} centered>
                <Modal.Body>
                    <Form ref={formref} noValidate validated={validated} >
                        <Row className="g-2 my-3">
                            <Col sm={6} className="my-1">
                                <Form.Label className="form-field-title">Event Date</Form.Label>
                                <DateSelector
                                    value={details.start}
                                    onChange={date =>
                                        setDetails(d => ({
                                            ...d,
                                            start: date,
                                            end: (!d.end || (d.end < date)) ? date : d.end
                                        }))}
                                    placeholder="when does it start?"
                                    required={true}

                                />
                                <Form.Control.Feedback type="invalid">
                                    A valid date must be provided.
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={6} className="my-1">
                                <Form.Label className="form-field-title">End Date</Form.Label>
                                <DateSelector
                                    value={details.end}
                                    onChange={date =>
                                        setDetails(d => ({
                                            ...d,
                                            end: date
                                        }))}
                                    placeholder="when does it end?"
                                    required={true}
                                    minDate={details.start}

                                />
                                <Form.Control.Feedback type="invalid">
                                    A valid date must be provided.
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>
                <Modal.Footer className="py-1">
                    <SubmitButton isSubmitting={submitting} onClick={handleSubmit}>
                        Add Dates
                    </SubmitButton>
                    <CancelButton onClick={() => setShow(false)} isSubmitting={submitting}>
                        Cancel
                    </CancelButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}


const EventDetails = () => {

    const { eventid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setLoaded(false);
        setError(null);

        getEvent(eventid, ['client', 'products', 'payments', 'notes', 'event_dates', 'bills', 'images'])
            .then(({ event }) => {
                event.notes.sort((a, b) => sortFunction(a, b, 'date_added'));
                setDetails(event);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [eventid])

    //load events details
    if (!isLoaded) {
        return <PageLoading>Loading event details...</PageLoading>
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route path="/edit" element={<EditEvent details={details} setDetails={setDetails} />} />
            <Route path="/labour/*" element={<EventLabour details={details} />} />
            <Route path="/" element={<ViewDetails details={details} setDetails={setDetails} />} />
        </Routes>
    )

}


export default EventDetails;