import fetchData, { APIURL, getData, postData } from "../fetch";


/**
 * 
 * @returns {Promise<{code: number, message: string, users: import("../../app/users/view-users").UserObject}>}
 */
const getUsers = () => {

    return fetchData({
        url: `${APIURL}/users`
    })
}

/**
 * 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, user: import("../../app/users/view-users").UserObject}>}
 */
const getUser = id => {
    return fetchData({
        url: `${APIURL}/users/${id}`
    })
}

/**
 * 
 * @param {{other_names: string, last_name: string, email: string, telephone: string, job_title: string, roles: string[]}} data 
 * @returns {Promise<{code: number, message: string}>}
 */
const createUser = (data) => {
    return postData({
        url: `${APIURL}/users`,
        data
    })
}

/**
 * 
 * @param {{other_names: string, last_name: string, email: string, telephone: string, job_title: string, roles: string[]}} data 
 * @returns {Promise<{code: number, message: string}>}
 */
const updateUser = (data, id) => {
    return postData({
        url: `${APIURL}/users/${id}`,
        data
    })
}

/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteUser = id => fetchData({ method: "DELETE", url: `${APIURL}/users/${id}` });

/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const resetUserPassword = id => postData({ url: `${APIURL}/users/${id}/reset-password` });


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const suspendUser = id => postData({ url: `${APIURL}/users/${id}/suspend` });


const updateRoles = (roles, id) => postData({ url: `${APIURL}/users/${id}/roles`, data: { roles } })


const updatePermissionLevel = (permission_level, id) => postData({ url: `${APIURL}/users/${id}/roles`, data: { permission_level } })

/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string, dashboard: {feenotes: import("./fee-notes").NoteObject[], titles: import("../../app/titles/view-titles").TitleObject[], accounts: import("./accounts").AccountObject[]}}>}
 */
const getDashboardDetails = () => getData({ url: `${APIURL}/dashboard` });


export {
    createUser,
    deleteUser,
    getUsers,
    getUser,
    resetUserPassword,
    suspendUser,
    updateUser,
    updateRoles,
    getDashboardDetails,
    updatePermissionLevel
}