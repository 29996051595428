
import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import PaymentDetails from './payment-details';
import { NewPayment } from './payment-manipulation';
import ViewPayments from "./view-payments";

const Payments = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 3) {
        return <ErrorLoading>You do not have the permission to access payments.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/new" element={<NewPayment />} />
            <Route path="/:paymentid/*" element={<PaymentDetails />} />
            <Route path="/" element={<ViewPayments />} />
        </Routes>
    )
}

export default Payments;