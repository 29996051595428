import fetchData, { APIURL, deleleData, getData, postData } from "../fetch";


/**
 * @typedef {{
 *  id: string
 *  title: string
 *  account_type: string
 *  opening_balance: number
 *  opening_date: string
 *  available_balance: number
 *  access_permission_level: number
 *  isActive: boolean
 *  transfers: AccountTransferObject[]
 *  payments: import("./payments").PaymentObject[]
 *  expenses: import("./expenses").ExpenseObject[]
 * }} AccountObject
 */

/**
 * @typedef {{
 *  id: string
 *  transfer_date: string
 *  from_account_id: string
 *  from_account: string
 *  to_account_id: string 
 *  to_account: string
 *  amount: number
 * }} AccountTransferObject
 */



/**
 * 
 * @param {AccountObject} data 
 * @returns {Promise<{code: number, message: string, account: AccountObject}>}
 */
const createAccount = data => postData({ url: `${APIURL}/accounts`, data })


/**
 * get account details
 * @param {String} id 
 * @param {string[]} include
 * @returns {Promise<{code: number, message: string, account: AccountObject}>}
 */
const getAccount = (id, include = []) => fetchData({ url: `${APIURL}/accounts/${id}`, data: { include: include.join(",") } })


/**
 * @param {AccountObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, account: AccountObject}>}
 */
const updateAccount = (data, id) => postData({ url: `${APIURL}/accounts/${id}`, data })

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, accounts: AccountObject[]}>}
 */
const getAccounts = () => getData({ url: `${APIURL}/accounts` });

/**
 * @returns {Promise<{code: number, message: string}>}
 */
const activateAccount = (id) => postData({ url: `${APIURL}/accounts/${id}/activate` })

/**
 * @returns {Promise<{code: number, message: string}>}
 */
const dectivateAccount = (id) => postData({ url: `${APIURL}/accounts/${id}/deactivate` })


/**
 * 
 * @param {AccountTransferObject} data 
 * @param {"in"|"out"} direction 
 * @returns {Promise<{code: number, message: string, transfer: AccountTransferObject}>}
 */
const createTransfer = (data, direction) => {

    const id = (direction === "in") ? data.to_account_id : data.from_account_id;

    return postData({ url: `${APIURL}/accounts/${id}/transfers/${direction}`, data })
}

/**
 * 
 * @param {string} id ID of the transfer to delete. 
 * @returns {Promise<{code: number, message: string}>}
 */
const deleteTransfer = id => deleleData({ url: `${APIURL}/accounts/transfers/${id}` });



export {
    getAccount,
    getAccounts,
    createAccount,
    updateAccount,
    activateAccount,
    dectivateAccount,
    createTransfer,
    deleteTransfer
}