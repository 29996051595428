
import { useContext } from 'react';
import { useState, useMemo, useEffect } from 'react';
import { Accordion, Badge, Col, Form, FormControl, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { payrollTypes } from '../../assets/constants';
import Nav from '../../components/breadcrumb';
import { SubmitButton } from '../../components/btns';
import cur from '../../components/currency';
import { ErrorLoading } from '../../components/loading';
import PageHeader, { ActionBar, ActionButton, ButtonToolbar, Title } from '../../components/pageheader';
import { groupBy, sortFunction, UCWords } from '../../components/resources';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { executePayments, getApprovedPayments } from '../../resources/api/payroll';
import { APIURL } from '../../resources/fetch';
import { getAccounts } from '../../resources/api/accounts';


/**
 * handle
 * @param {Object} props
 * 
 */
const ProcessPayroll = () => {

    const nav_items = [
        { title: 'Payroll', href: '/app/payroll' },
        { title: "Process" }
    ];

    const { profile } = useContext(AppUserContext);

    const [workers, setWorkers] = useState([]);
    const [account, setAccount] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const [accounts, setAccounts] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {

        if (profile.permission_level > 2) {
            return errorAlert("You do not have permission to process payroll");
        }

        infoAlert("Loading approved payments...");


        getAccounts()
            .then(({ accounts }) => {
                setAccounts(accounts);
                successAlert("Successfully loaded the accounts list");
            })
            .catch(e => errorAlert(e))

        getApprovedPayments()
            .then(({ payments }) => {
                if (payments.length === 0) {
                    infoAlert("Oops... there are no payments to be processed.")
                } else {
                    successAlert("Loaded all payments...");
                }
                let workers = groupBy(
                    payments
                        .filter(r => r.isProcessed)
                        .map(p => ({
                            ...p,
                            expense_category: payrollTypes[p.payroll_type].expenseCategory
                        })
                        ),
                    "staff_id"
                ),
                    items = [];

                for (let id in workers) {
                    //for each worker group by payroll type
                    let _items = workers[id],
                        type_items = [];
                    _items = groupBy(_items, "expense_category");

                    for (let type in _items) {
                        type_items.push({
                            advance_amount: 0,
                            expense_category: type,
                            total_amount: _items[type].reduce((p, c) => p + parseFloat(c.amount), 0)
                        })
                    }

                    items.push({
                        id: id,
                        display_name: workers[id][0].display_name,
                        advance_balance: workers[id][0].advance_balance,
                        total_amount: type_items.reduce((p, c) => p + parseFloat(c.total_amount), 0),
                        items: type_items,
                        allApproved: false
                    });
                }
                setWorkers(items.sort((a, b) => sortFunction(a, b, "display_name")));
            })
            .catch(e => errorAlert(`Unable to load payments. Error: ${e}`))
    }, [profile.permission_level])


    const accountingJournal = useMemo(() => {

        const categories = {}, j_categories = [];

        workers.forEach(e => {
            e.items.forEach(i => {
                if (!(i.expense_category in categories)) categories[i.expense_category] = [];

                categories[i.expense_category].push(i.total_amount);

                if (!!i.advance_amount) {
                    if (!("advances" in categories)) categories.advances = [];
                    categories.advances.push(-i.advance_amount);
                }
            })
        })

        for (let cat in categories) {
            j_categories.push({
                title: UCWords(cat),
                credit: categories[cat].filter(r => r > 0).reduce((p, c) => p + parseFloat(c), 0),
                debit: categories[cat].filter(r => r < 0).reduce((p, c) => p + parseFloat(c), 0),
            })
        }

        return j_categories;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(workers)])



    const changeAdvanceAmount = (id, idx, amount) => {

        const _workers = [...workers],
            _wi = _workers.findIndex(w => w.id === id);
        _workers[_wi].items[idx].advance_amount = amount;
        setWorkers(_workers);
    }

    // Submit the data backend
    const handleSubmit = () => {

        if (!account) {
            return errorAlert("Please set the account where this expense is coming from");
        }

        const data = {
            advance_amounts: workers
                .map(w => ({
                    staff_id: w.id,
                    advance_amount: w.items.reduce((p, c) => p + c.advance_amount, 0)
                }))
                .filter(a => (a.advance_amount > 0)),
            account
        }

        setSubmitting(true);

        executePayments(data)
            .then(({ message, reference }) => {
                successAlert(message);
                infoAlert(`Transaction Reference: ${reference}`)
                navigate(`/app/payroll/expenses/${reference}`);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }


    if (profile.permission_level > 2)
        return <ErrorLoading>You do not have the permission to process payroll.</ErrorLoading>

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    Process Payroll Expenses
                </Title>
                <ActionBar>
                    <ButtonToolbar>
                        <ActionButton title="Download PDF"
                            href={`${APIURL}/payroll-payments/approved/pdf`}
                            isExternalLink
                        >
                            <i className="fas fa-file-pdf" />
                        </ActionButton>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-900">

                <h4 className='mt-2 mt-sm-5'>Expense Details</h4>
                {(workers.length === 0) &&
                    <p className='lead'>No details to show.</p>
                }
                <Accordion flush>
                    {
                        workers.map(_w => (
                            <Accordion.Item key={_w.id} eventKey={_w.id}>
                                <Accordion.Header>
                                    <h5 className="w-100 fw-normal">{_w.display_name}</h5>
                                    <span className="me-2">
                                        {cur(_w.total_amount, 0).format()}
                                        {_w.allApproved && <>
                                            <br />
                                            <Badge pill bg="success">approved</Badge>
                                        </>}
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>

                                    <p>
                                        Advance Balance: {cur(_w.advance_balance, 0).format()}
                                    </p>

                                    <Table hover responsive="md" style={{ minWidth: "500px" }}>

                                        <thead>
                                            <tr>
                                                <th>Expense</th>
                                                <th className="text-end">Amount</th>
                                                <th>Advance</th>
                                                {/* <th>...</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {_w.items.map((w, idx) => (
                                                <tr key={idx}>
                                                    <td>{UCWords(w.expense_category.replace(/-/g, " & "))}</td>
                                                    <td className="text-end">{cur(w.total_amount).format()}</td>
                                                    <td>
                                                        {((["wages", "salaries", "wages-salaries"].indexOf(w.expense_category) !== -1) && (_w.advance_balance > 0)) ?
                                                            <FormControl
                                                                size="sm"
                                                                type="number"
                                                                style={{ maxWidth: "125px" }}
                                                                max={Math.min(_w.advance_balance, w.total_amount)}
                                                                value={w.advance_amount}
                                                                onChange={e => changeAdvanceAmount(_w.id, idx, e.currentTarget.value)}
                                                            /> :
                                                            "n/a"}

                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>

                                </Accordion.Body>
                            </Accordion.Item>
                        ))
                    }


                </Accordion>

                <h4 className='mt-2 mt-sm-5'>Expense Summary</h4>
                <Table>
                    <thead>
                        <tr>
                            <th>Account</th>
                            <th>Credit</th>
                            <th>Debit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accountingJournal.map((j, idx) => (
                            <tr>
                                <td>{j.title.replace(/-/g, " & ")}</td>
                                <td>{cur(j.credit, 0).format()}</td>
                                <td>{cur(Math.abs(j.debit), 0).format()}</td>
                            </tr>
                        ))}
                        <tr>
                            <td>Cash</td>
                            <td>0</td>
                            <td>{cur(accountingJournal.reduce((p, c) => p + (c.credit + c.debit), 0), 0).format()}</td>
                        </tr>
                    </tbody>
                </Table>

                {(workers.length > 0) &&
                    <>
                        <Row className='mt-4'>
                            <Col sm={6} className="my-2">
                                <Form.Label className="form-field-title">Payment Account</Form.Label>
                                <Form.Select
                                    as="select"
                                    value={account}
                                    onChange={e => setAccount(e.currentTarget.value)}
                                    required
                                >
                                    <option value=""></option>
                                    {accounts.map(c => <option key={c} value={c.id}>{c.title}</option>)}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="my-2">
                                <SubmitButton onClick={handleSubmit} isSubmitting={isSubmitting}>
                                    Submit
                                </SubmitButton>
                            </Col>

                        </Row>
                    </>
                }

            </div>
        </>
    )
}




export default ProcessPayroll;