import { APIURL, postData, getData } from "../fetch";


/**
 * 
 * @typedef {{
 * id: string 
 * reference: string 
 * notification_date: string 
 * _date: string 
 * _time: string
 * user_id: string 
 * title: string 
 * message: string 
 * url: string 
 * isRead: boolean 
 * read_date: string
 * date_added: string 
 * added_by: string
 * }} NotificationObject 
 */





/**
 * 
 * @param {NotificationObject} data 
 * @returns {Promise<{code: number, message: string, notifications: NotificationObject[]}>}
 */
const getNotifications = () => getData({ url: `${APIURL}/notifications` })


/**
 * 
 * @param {NotificationObject} data 
 * @returns {Promise<{code: number, message: string, notifications: NotificationObject[]}>}
 */
const getNewNotifications = (datetime) => getData({ url: `${APIURL}/notifications/new`, data: { datetime } })




/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, client: NotificationObject}>}
 */
const getNotification = (id, includes = null) => {

    const obj = { url: `${APIURL}/notifications/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}




/**
 * 
 * @param {string} id ID of the notification to be read. 
 * @returns {Promise<{code: number, message: string, notification: NotificationObject}>}
 */
const readNotification = id => postData({ url: `${APIURL}/notifications/${id}/read` })


/**
 * 
 * @param {string} reference Reference of the notification to be read. 
 * @returns {Promise<{code: number, message: string, notification: NotificationObject}>}
 */
const readNotificationByRef = reference => postData({ url: `${APIURL}/notifications/${reference}` })





export {
    readNotification,
    getNotification,
    getNotifications,
    getNewNotifications,
    readNotificationByRef
}