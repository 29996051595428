import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import ProcessRequisitions from './process';
import New from './requisition-manipulation';
import ViewRequisitionPayment from './view-payment';
import ViewRequisitionPayments from './view-payments';
import RequisitionDetails from './view-requisition';
import ViewRequisitions from './view-requisitions';

const Requisitions = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to access requisitions.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="new" element={<New />} />
            <Route path="process" element={<ProcessRequisitions />} />
            <Route path="payments" element={<ViewRequisitionPayments />} />
            <Route path="payments/:paymentid" element={<ViewRequisitionPayment />} />
            <Route path=":requisitionid/*" element={<RequisitionDetails />} />
            <Route path="/" element={<ViewRequisitions />} />
        </Routes>
    )
};

export default Requisitions;