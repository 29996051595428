import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import New from './staff-payment-manipulation';
import PaymentDetails from './view-staffpayment';
import ViewPayments from './view-staffpayments';

const StaffPayments = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to access the staff payments.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="new" element={<New />} />
            <Route path=":paymentid/*" element={<PaymentDetails />} />
            <Route path="/" element={<ViewPayments />} />
        </Routes>
    )
};

export default StaffPayments;