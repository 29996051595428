
import { APIURL, deleteData, getData, postData } from "../fetch";


/**
 * @typedef {{
 *  id: string 
 *  bill_id: string
 *  category: string
 *  unit_price: number
 *  quantity: number
 *  notes: string
 *  tax_percent: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string,
 * }} BillItemObject
 */

/**
 * @typedef {{
 *  id: string 
 *  reference: string
 *  vendor_reference: string
 *  vendor_id: string
 *  bill_date: string
 *  purchase_order_id: string|null
 *  pretax_amount: number 
 *  tax_amount: number 
 *  total_amount: number 
 *  paid_amount: number
 *  balance: number
 *  isTaxInclusive: boolean 
 *  file_id: string
 *  client_id: string
 *  event_id: string
 *  client: string|import("./clients").ClientObject
 *  job: string|import("./jobs").JobObject
 *  status: "unpaid"|"paid"|"partially-paid"|"overdue"|"cancelled"
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string 
 *  items: BillItemObject[]
 *  vendor: string|import("./vendors").VendorObject
 *  order: string|null|import("./orders").OrderObject
 *  event: null|string|import("./events").EventObject
 *  client: null|string|import("./clients").ClientObject
 * }} BillObject
 */



/**
 * 
 * @param {BillObject} data 
 * @returns {Promise<{code: number, message: string, bill: BillObject}>}
 */
const createBill = data => {
    return postData({
        url: `${APIURL}/bills`,
        data
    })
}

/**
 * 
 * @param {BillObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, bill: BillObject}>}
 */
const updateBill = (data, id) => {
    return postData({
        url: `${APIURL}/bills/${id}`,
        data
    })
}

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, bills: BillObject[]}>}
 */
const getBills = (getAll = false, conditions = null) => {

    const obj = { url: `${APIURL}/bills`, data: {} }

    if (getAll) obj.data = { expanded: 1 }
    if (conditions) obj.data = { ...(obj.data || {}), ...conditions }

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, bill: BillObject}>}
 */
const getBill = (id, includes = null) => {

    const obj = { url: `${APIURL}/bills/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteBill = id => getData({ url: `${APIURL}/bills/${id}`, method: "DELETE" })



/**
 * 
 * @param {string} id 
 * @param {import("./jobs").JobDocObject} data
 * @returns  {Promise<{code: number, message: string, document: import("./jobs").JobDocObject}>}
 */

const uploadDocument = (id, data) => postData({ url: `${APIURL}/bills/${id}/documents`, data })

const deleteDocument = (jobid, id) => deleteData({ url: `${APIURL}/bills/${jobid}/documents/${id}` });


export {
    createBill,
    getBill,
    getBills,
    updateBill,
    deleteBill,
    uploadDocument,
    deleteDocument
}