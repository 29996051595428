import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


/**
 * @param {Object} props
 * @param {boolean} props.isSubmitting 
 * @param {?React.ReactNode} props.icon 
 * @param {string} props.variant 
 */
const SubmitButton = ({
    isSubmitting,
    icon,
    variant = "success",
    disabled,
    children,
    ...props
}) => {
    return (
        <Button
            variant={variant}
            className="rounded-pill m-1" {...props}
            disabled={disabled || isSubmitting}
        >
            {isSubmitting ?
                <i className="fas fa-circle-notch fa-spin me-2" /> :
                (icon || <i className="fas fa-thumbs-up me-2" />)}{children}
        </Button>
    )
}

/**
 * 
 * @param {Object} props 
 * @param {boolean} props.isSubmitting 
 * @param {string} props.variant 
 * @param {() => void} props.onClick 
 */
const CancelButton = ({
    isSubmitting,
    variant = "danger",
    children,
    onClick,
    disabled = false,
    ...props
}) => {

    const navigate = useNavigate();
    const handleClick = onClick || (() => navigate(-1));

    return (
        <Button
            variant="danger"
            className="rounded-pill m-1"
            onClick={handleClick}
            disabled={disabled || isSubmitting}
            {...props}
        >
            <i className="fas fa-times-circle me-2" />{children || "Cancel"}
        </Button>
    )
}

/**
 * 
 * @param {{
 * field: string
 * currentField: string
 * currentOrder: string
 * handleSort: (field: string) => void 
 * }} param0 
 */
const SortButton = ({ field, currentField, currentOrder, handleSort }) => {

    const icon = field === currentField ?
        (currentOrder === 'asc' ? <i className="fas fa-sort-down" /> : <i className="fas fa-sort-up" />) :
        <i className="fas fa-sort" />;

    return <Button variant="link" className="p-0 text-secondary" onClick={() => handleSort(field)}>{icon}</Button>
}
export { SubmitButton, CancelButton, SortButton };