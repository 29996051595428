import React, { useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { AccountTypes } from "../../assets/constants";
import { CancelButton, SubmitButton } from "../../components/btns";
import { DateSelector } from "../../components/datetimepicker";
import { UCWords } from "../../components/resources";
import { errorAlert, successAlert } from "../../components/toastr";
import { createAccount, createTransfer, getAccounts, updateAccount } from "../../resources/api/accounts";
import { useEffect } from "react";



const { Control, Label } = Form;

/**
 * @param {Object} props
 * @param {"create"|"edit"} props.action
 * @param {(details: import("../../resources/api/accounts").AccountObject) => void} props.onUpload
 * @param {{
 *  show: boolean
 *  setShow: React.Dispatch<React.SetStateAction<boolean>>
 * }} props.show
 * @param {{
 *  details: import("../../resources/api/accounts").AccountObject
 *  setDetails: React.Dispatch<React.SetStateAction<import("../../resources/api/accounts").AccountObject>>
 * }} props.accountDetails
 */
const AccountForm = ({ onUpload, action = "create", accountDetails, show: toShow }) => {

    const inputref = useRef();

    const { show, setShow } = toShow;
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    const { details, setDetails } = accountDetails;



    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);
        setValidated(false);

        let promise;

        if (action === "create") {
            promise = createAccount(details);
        } else {
            promise = updateAccount(details, details.id);
        }

        promise
            .then(({ account, message }) => {
                form.reset();
                successAlert(message);
                onUpload(account);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (
        <Modal show={show} onExit={() => setValidated(false)} centered animation={false} size="lg">
            <Modal.Body>
                <Form noValidate validated={validated} ref={inputref}>

                    <Row className="g-1">
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">Title</Label>
                            <Control
                                value={details.title}
                                onChange={e => setDetails({ ...details, title: e.currentTarget.value })}
                                required
                            />
                            <Control.Feedback type="invalid">
                                Give a unique title to this account
                            </Control.Feedback>
                            <Form.Text muted>
                                Title should be unique for every account
                            </Form.Text>
                        </Col>
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">Type</Label>
                            <Form.Select
                                value={details.account_type}
                                onChange={e => setDetails({ ...details, account_type: e.currentTarget.value })}
                                required
                            >
                                <option value="" />
                                {AccountTypes.map(t => <option key={t} value={t}>{UCWords(t.replace(/-/g, " "))}</option>)}
                            </Form.Select>
                            <Control.Feedback type="invalid">
                                Required
                            </Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="g-1">
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">Provider</Label>
                            <Control
                                value={details.account_provider}
                                onChange={e => setDetails({ ...details, account_provider: e.currentTarget.value })}
                                required={details.account_type !== 'cash'}
                            />
                            <Control.Feedback type="invalid">
                                Required for non-cash accounts
                            </Control.Feedback>
                            <Form.Text muted>
                                Name of the bank or network.
                            </Form.Text>
                        </Col>
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">Access Permission Level</Label>
                            <Form.Select
                                value={details.access_permission_level}
                                onChange={e => setDetails({ ...details, access_permission_level: e.currentTarget.value })}
                                required
                            >
                                <option value="">-</option>
                                {[5, 4, 3, 2, 1].map(p => <option key={p} value={p}>{p}</option>)}

                            </Form.Select>
                            <Control.Feedback type="invalid">
                                Required.
                            </Control.Feedback>
                            <Form.Text muted>
                                People with permission level above this figure will not be able to see/transact this account.
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="g-1">
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">Opening Balance</Label>
                            <Control
                                type="number"
                                value={details.opening_balance}
                                onChange={e => setDetails({ ...details, opening_balance: e.currentTarget.value })}
                                required={details.account_type !== 'cash'}
                            />
                            <Control.Feedback type="invalid">
                                Required
                            </Control.Feedback>
                            <Form.Text muted>
                                As of creation of account on the sytem
                            </Form.Text>
                        </Col>
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">Opening Balance Date</Label>
                            <DateSelector
                                value={details.opening_date}
                                onChange={date => setDetails(d => ({ ...d, opening_date: date }))}
                                required={details.account_type !== 'cash'}
                            />
                            <Control.Feedback type="invalid">
                                Required
                            </Control.Feedback>
                            <Form.Text muted>
                                As of when you intend to start running transactions through the account
                            </Form.Text>
                        </Col>
                    </Row>



                    <Row>
                        <Col className="mt-4 mb-0 text-end">
                            <SubmitButton isSubmitting={handlingSubmit} onClick={handleSubmit}>
                                {action === "edit" ? "Update" : "Submit"}
                            </SubmitButton>
                            <CancelButton isSubmitting={handlingSubmit} onClick={() => setShow(false)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal >
    )
}



/**
 * @param {Object} props
 * @param {"create"|"edit"} props.action
 * @param {"out"|"in"} props.direction
 * @param {(details: import("../../resources/api/accounts").AccountTransferObject, action: "edit"|"create") => void} props.onUpload
 * @param {{
*  show: boolean
*  setShow: React.Dispatch<React.SetStateAction<boolean>>
* }} props.show
* @param {{
*  details: import("../../resources/api/accounts").AccountTransferObject
*  setDetails: React.Dispatch<React.SetStateAction<import("../../resources/api/accounts").AccountTransferObject>>
* }} props.transferDetails
*/
const TransferForm = ({ onUpload, action = "create", direction = "out", transferDetails, show: toShow }) => {

    const inputref = useRef();

    const { show, setShow } = toShow;
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    const { details, setDetails } = transferDetails;

    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        getAccounts()
            .then(({ accounts }) => setAccounts(accounts))
            .catch(e => errorAlert(`Unable to load accounts for transfers. ${e}`))
    }, [])



    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);
        setValidated(false);

        let promise;

        if (action === "create") {
            promise = createTransfer(details, direction);
        } else {
            promise = updateAccount(details, details.id);
        }

        promise
            .then(({ transfer, message }) => {
                form.reset();
                successAlert(message);
                onUpload(transfer, action);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (
        <Modal show={show} onExit={() => setValidated(false)} centered animation={false} size="sm">
            <Modal.Body>
                <Form noValidate validated={validated} ref={inputref}>

                    <Row className="g-1">

                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">From Account</Label>
                            <Form.Select
                                value={details.from_account_id}
                                onChange={e => setDetails({ ...details, from_account_id: e.currentTarget.value })}
                                disabled={(direction === "out")}
                                required
                            >
                                <option value="" />
                                {accounts
                                    .filter(a => a.id !== details.to_account_id)
                                    .map(a => <option key={a.id} value={a.id}>{a.title}</option>)}
                            </Form.Select>
                            <Control.Feedback type="invalid">
                                Required
                            </Control.Feedback>
                        </Col>

                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">To Account</Label>
                            <Form.Select
                                value={details.to_account_id}
                                onChange={e => setDetails({ ...details, to_account_id: e.currentTarget.value })}
                                disabled={(direction === "in")}
                                required
                            >
                                <option value="" />
                                {accounts
                                    .filter(a => a.id !== details.from_account_id)
                                    .map(a => <option key={a.id} value={a.id}>{a.title}</option>)}
                            </Form.Select>
                            <Control.Feedback type="invalid">
                                Required
                            </Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="g-1">
                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Date</Label>
                            <DateSelector
                                value={details.transfer_date}
                                onChange={date =>
                                    setDetails(d => ({
                                        ...d,
                                        transfer_date: date
                                    }))}
                                required={true}
                                minDate="2021-01-01"

                            />
                            <Control.Feedback type="invalid">
                                Required
                            </Control.Feedback>
                        </Col>
                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Amount</Label>
                            <Control
                                type="number"
                                value={details.amount}
                                onChange={e => setDetails({ ...details, amount: e.currentTarget.value })}
                                required={details.account_type !== 'cash'}
                            />
                            {/* <Form.Text muted>
                                As of creation of account on the sytem
                            </Form.Text> */}
                            <Control.Feedback type="invalid">
                                Required
                            </Control.Feedback>
                        </Col>
                    </Row>



                    <Row>
                        <Col className="mt-4 mb-0 text-end">
                            <SubmitButton isSubmitting={handlingSubmit} onClick={handleSubmit}>
                                {action === "edit" ? "Update" : "Submit"}
                            </SubmitButton>
                            <CancelButton isSubmitting={handlingSubmit} onClick={() => setShow(false)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal >
    )
}


export { TransferForm };

export default AccountForm;