import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { useState, useMemo, useContext } from 'react';
import { DateSelector } from './../../components/datetimepicker';
import { errorAlert, infoAlert, successAlert } from './../../components/toastr';
import moment from 'moment';
import { SubmitButton, CancelButton } from './../../components/btns';
import { ExpenseItems } from './../../components/finances';
import currency from './../../components/currency';
// import File from './../../components/files';
import { assortedPaymentMethods, expenseCategories, paymentMethods } from '../../assets/constants';
import { UCWords } from '../../components/resources';
import { createExpense, updateExpense } from '../../resources/api/expenses';
import SelectInput from '../../components/select';
import { useEffect } from 'react';
import { getClients } from '../../resources/api/clients';
import { getEvents } from '../../resources/api/events';
import { getAccounts } from '../../resources/api/accounts';
import { AppUserContext } from '../../App';
import { getVendors } from '../../resources/api/vendors';


const { Control, Control: { Feedback }, Label, Text } = Form;


/**
 * 
 * @param {Object} props
 * @param {"create"|"edit"} props.action 
 * @param {import('../../resources/api/expenses').ExpenseObject} props.details 
 * @param {(details: import('../../resources/api/expenses').ExpenseObject) => void} props.onSubmit 
 */
const ExpenseForm = ({ action = "create", details: expenseObject, onSubmit }) => {

    const [details, setDetails] = useState(expenseObject);
    const [items, setItems] = useState(expenseObject.items || []);
    const [deleted_items, setDeletedItems] = useState([]);

    // const [files, setFiles] = useState([]);
    const [clients, setClients] = useState([]);
    const [events, setEvents] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [vendors, setVendors] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const { profile } = useContext(AppUserContext);



    const displayAccounts = useMemo(() => {

        const _types = assortedPaymentMethods[details.method] || [];

        return accounts.filter(a => _types.indexOf(a.account_type) !== -1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.method, JSON.stringify(accounts)])



    useEffect(() => {

        infoAlert("Loading the clientele list...");

        getClients()
            .then(({ clients }) => {
                setClients(
                    clients.map(c => ({ ...c, description: `${c.email} ${c.telephone}` }))
                );

                successAlert("Successfully loaded the clientele list");
            })
            .catch(e => errorAlert(e))

        getAccounts()
            .then(({ accounts }) => {
                setAccounts(accounts);
                successAlert("Successfully loaded the accounts list");
            })
            .catch(e => errorAlert(e))


        getVendors()
            .then(({ vendors }) => {
                setVendors(
                    vendors.map(c => ({ ...c, description: c.address }))
                );
            })
    }, []);

    useEffect(() => {

        setDetails(d => ({ ...d, isBillable: false, event_id: "", markup: "" }));
        setEvents([]);

        if (!details.client_id) return;

        infoAlert("Loading events for this client");

        getEvents(true, { client_id: details.client_id })
            .then(({ events }) => {

                setEvents(events.map(e => ({
                    ...e,
                    title: e.location,
                    description: `${e.reference} | ` + moment(e.event_date).format("DD MMMM YYYY")
                })));
                successAlert("Events have been loaded");
            })
            .catch(e => errorAlert(`Could not get client events. Reason: ${e}`))

    }, [details.client_id])

    useEffect(() => {

        if (details.isBill) {
            setDetails(d => ({ ...d, isItemized: true }))
            infoAlert("Given this is a bill, expense must be itemised.")
        }

    }, [details.isBill])



    const tax = useMemo(() => {

        const _tax = parseFloat(details.tax),
            _amount = parseFloat(details.amount);

        if (_tax === 0 || !_tax || _amount === 0 || !_amount) return 0;


        if (details.isTaxInclusive) {
            return _amount - (_amount / (1 + _tax / 100));
        } else {
            return _tax / 100 * _amount;
        }
    }, [details.isTaxInclusive, details.amount, details.tax]);




    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        const data = {
            ...details,
            isBill: details.isBill ? 1 : 0,
            isItemized: details.isItemized ? 1 : 0,
            isBillable: details.isBillable ? 1 : 0,
            isTaxInclusive: details.isTaxInclusive ? 1 : 0,
            tax_percent: details.tax_percent || 0
        }

        let promise;

        if (action === "create") {
            data.items = items;
            promise = createExpense(data);
        } else {
            promise = updateExpense({
                ...data,
                new_items: items.filter(i => i.isNew),
                updated_items: items.filter(i => (!!i.id && i.hasChanged)),
                deleted_items
            }, data.id)
        }

        promise
            .then(({ expense, message }) => {
                successAlert(message);
                onSubmit(expense);
            })
            .catch(e => {
                setSubmitting(false);
                errorAlert(e);
            })

    }


    return (

        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label mt-5">Details</h4>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Vendor</Label>
                    <SelectInput
                        value={[details.vendor_id]}
                        onChange={([id]) => setDetails(d => ({
                            ...d,
                            vendor_id: id || ""
                        }))}
                        maxItems={1}
                        options={vendors}
                        placeholder="-"
                    />
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Date</Label>
                    <DateSelector
                        minDate={(profile.permission_level === 1) ? false : moment().add(-1, 'months').format('YYYY-MM-DD')}
                        maxDate={0}
                        value={details.expense_date}
                        onChange={val => setDetails(d => ({ ...d, expense_date: val }))}
                        required
                    />
                    <Feedback type="invalid">
                        You must select an expense date.
                    </Feedback>
                </Col>
            </Row>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Category</Label>
                    <Form.Select
                        as="select"
                        value={details.category}
                        onChange={e => setDetails({ ...details, category: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {expenseCategories.map(c => <option key={c} value={c}>{UCWords(c.replace(/-/g, " "))}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        You must select a category for this expense.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Your/Vendor's Reference</Label>
                    <Control
                        maxLength={60}
                        value={details.user_reference}
                        onChange={e => setDetails({ ...details, user_reference: e.currentTarget.value })}
                    />
                    <Text muted>
                        Something to remember this expense by. Should be less than 60 characters. (Optional)
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Payment Method</Label>
                    <Form.Select
                        as="select"
                        value={details.method}
                        onChange={e => setDetails({ ...details, method: e.currentTarget.value })}
                        required
                    >
                        <option value="">-</option>
                        {paymentMethods.map(c => <option key={c} value={c}>{UCWords(c).replace(/-/g, " ")}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        You must select a method for payment for this expense.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Payment Account</Label>
                    <Form.Select
                        as="select"
                        value={details.account_id}
                        onChange={e => setDetails({ ...details, account_id: e.currentTarget.value })}
                        required
                    >
                        <option value="">-</option>
                        {displayAccounts.map(c => <option key={c.id} value={c.id}>{c.title}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        You must select the account from which this expense is coming.
                    </Feedback>
                    {!details.method &&
                        <Form.Text muted>
                            First select a payment method.
                        </Form.Text>
                    }
                </Col>
            </Row>
            {!details.isItemized &&
                <Row>
                    <Col sm={6} className="my-1">
                        <Label className="form-field-title">Amount</Label>
                        <InputGroup>
                            <Control
                                type="number"
                                step={0.01}
                                value={details.amount}
                                onChange={e => setDetails({ ...details, amount: e.currentTarget.value })}
                                required
                            />
                            <Button
                                variant="secondary"
                                className="border-0"
                                title="Itemize Expense"
                                onClick={() => setDetails(d => ({ ...d, isItemized: true }))}
                            >
                                <i className="fas fa-sitemap" />
                            </Button>
                            <Feedback type="invalid">
                                You must provide an amount
                            </Feedback>
                        </InputGroup>
                        <Text muted>
                            You can decide to itemize the expense by clicking the <i className="fas fa-sitemap" /> button.
                            This means that you put in the individual items and the system will calculate the total amount.
                        </Text>
                    </Col>
                    <Col sm={6} className="my-1">
                        <Label className="form-field-title">Tax</Label>
                        <Form.Select
                            placeholder="Total amount spent"
                            value={details.tax_percent}
                            onChange={e => setDetails({ ...details, tax_percent: e.currentTarget.value })}

                        >
                            <option value="">None</option>
                            <option value="18">VAT 18%</option>
                        </Form.Select>
                        <Form.Check
                            className=""
                            type="switch"
                            id="custom-switch"
                            checked={details.isTaxInclusive}
                            onChange={() => setDetails(d => ({ ...d, isTaxInclusive: !d.isTaxInclusive }))}
                            label={details.isTaxInclusive ? "Prices are tax inclusive." : "Prices are not tax inclusive."}
                        />
                        {tax > 0 &&
                            <Text muted>Total tax of UGX {currency(tax).format()}</Text>}
                    </Col>
                </Row>
            }


            <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Notes/Description</Label>
                    <Control placeholder="-"
                        as="textarea"
                        rows={2}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        value={details.description || ""}
                        onChange={e => setDetails({ ...details, description: e.currentTarget.value })}
                    />
                    <Form.Text muted>
                        You might want to throw more clarity on the expense.
                    </Form.Text>
                </Col>
            </Row>



            {details.isItemized &&
                <>
                    <h4 className="form-section-label mt-5">Items</h4>
                    <Form.Check
                        className=""
                        type="switch"
                        id="custom-switch"
                        checked={details.isTaxInclusive}
                        onChange={() => setDetails(d => ({ ...d, isTaxInclusive: !d.isTaxInclusive }))}
                        label={details.isTaxInclusive ? "Prices are tax inclusive." : "Prices are not tax inclusive."}
                    />

                    <ExpenseItems
                        expenseitems={{ items, setItems }}
                        deleteditems={{ deleted_items, setDeletedItems }}
                        isTaxInclusive={details.isTaxInclusive}
                    >
                        {!details.isBill &&
                            <Button
                                variant="secondary"
                                size="sm"
                                className="rounded-pill m-1"
                                onClick={() => setDetails(d => ({ ...d, isItemized: false }))}
                            >
                                <i className="fas fa-ban me-2" />Unitemize Items
                            </Button>
                        }

                    </ExpenseItems>
                </>
            }



            <h4 className="form-section-label mt-5">Other Details</h4>
            <Text muted>
                Add the expense to an account. If the expense is billable, then it must be added to an account and a fee note must not be attached to it. Optionally you can add a feenote to track expenses against a feenote.
            </Text>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Client</Label>
                    <SelectInput
                        value={[details.client_id]}
                        onChange={([id]) => setDetails(d => ({ ...d, client_id: id || "" }))}
                        options={clients}
                        placeholder='-'
                        maxItems={1}
                        errorText="The client must exist in the existing lists of clients."
                    />
                </Col>

                {!!details.client_id &&
                    < Col sm={6} className="my-1">
                        <Label className="form-field-title">Event</Label>
                        <SelectInput
                            value={[details.event_id]}
                            onChange={([id]) => setDetails(d => ({ ...d, event_id: id || "" }))}
                            options={events}
                            required
                            maxItems={1}
                            errorText="The client must exist in the existing lists of clients."
                            sortBy="event_date"
                            placeholder='-'
                        />
                    </Col>
                }
            </Row>

            {
                !!details.client_id &&
                <Row>
                    <Col sm={6} className="my-1">
                        <Label className="form-field-title">Is Billable?</Label>
                        <Form.Check
                            className=""
                            type="switch"
                            id="is-billable"
                            checked={details.isBillable}
                            onChange={() => setDetails(d => ({ ...d, isBillable: !d.isBillable }))}
                            label={details.isBillable ? "Yes, expense is billable." : "No, expense is not billable."}
                        />
                    </Col>

                    {!!details.isBillable &&
                        <Col className="my-1" sm={6}>
                            <Label className="form-field-title">MarkUp</Label>
                            <InputGroup>
                                <Control
                                    type="number"
                                    value={details.markup}
                                    onChange={e => setDetails({ ...details, markup: e.currentTarget.value })}
                                    min={0}
                                    max={100}
                                />
                                <InputGroup.Text className='border-0'>
                                    %
                                </InputGroup.Text>
                                <Control.Feedback type='invalid'>
                                    Mark up must be between 0 and 100

                                </Control.Feedback>
                            </InputGroup>
                        </Col>
                    }
                </Row>
            }

            {/* <Row>
                <Col sm={6} className="my-2">
                    <Label className="form-field-title">Receipt</Label>
                    <File maxFiles={1} onChange={files => setFiles(files)} value={files} />
                </Col>
            </Row> */}

            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        Submit Expense
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>

        </Form >
    )
}


export default ExpenseForm;