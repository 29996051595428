import fetchData, { APIURL, postData } from "../fetch";



const getExpenses = () => fetchData({
    url: `${APIURL}/dashboard/expenses`
});


const getIncomes = () => fetchData({
    url: `${APIURL}/dashboard/incomes`
});

const getStaffPayments = () => fetchData({
    url: `${APIURL}/dashboard/analytics/staff-payments`
});

const getProductPayments = () => fetchData({
    url: `${APIURL}/dashboard/analytics/payments`
});

const getVehicleExpenses = () => fetchData({
    url: `${APIURL}/dashboard/analytics/vehicle-expenses`
});


const getWorkDone = () => fetchData({
    url: `${APIURL}/dashboard/analytics/work-done`
});


const printAnalytics = (data) => postData({
    url: `${APIURL}/dashboard/analytics/report`,
    data
});



export {
    getExpenses,
    getIncomes,
    getStaffPayments,
    getProductPayments,
    getWorkDone,
    printAnalytics,
    getVehicleExpenses
}