import React, { useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../components/btns";
import { errorAlert, successAlert } from "../../components/toastr";
import { createDriver, updateDriver, updateDriverContact } from "../../resources/api/drivers";
import { DateSelector } from "../../components/datetimepicker";


const { Control, Label } = Form;

/**
 * @param {Object} props
 * @param {"create"|"contact"|"driver"} props.action
 * @param {(details: import("../../resources/api/drivers").DriverObject) => void} props.onUpload
 * @param {{
 *  show: boolean
 *  setShow: React.Dispatch<React.SetStateAction<boolean>>
 * }} props.show
 * @param {import("../../resources/api/drivers").DriverObject} props.driverDetails
 */
const DriverForm = ({ onUpload, action = "create", driverDetails, show: toShow }) => {

    const inputref = useRef();

    const { show, setShow } = toShow;
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    const { details, setDetails } = driverDetails;



    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);
        setValidated(false);

        let promise;

        if (action === "create") {
            promise = createDriver(details);
        } else {
            promise = (action === "contact") ? updateDriverContact(details, details.id) : updateDriver(details, details.id);
        }

        promise
            .then(({ driver, message }) => {
                form.reset();
                successAlert(message);
                onUpload(driver);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (
        <Modal show={show} onExit={() => setValidated(false)} centered size="lg">
            <Modal.Body>
                <Form noValidate validated={validated} ref={inputref}>

                    {(action !== "driver") &&
                        <>
                            <Row className="g-1">
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">First Name(s)</Label>
                                    <Control
                                        value={details.other_names}
                                        onChange={e => setDetails({ ...details, other_names: e.currentTarget.value })}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        Contact must have other names
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Last Name</Label>
                                    <Control
                                        value={details.last_name}
                                        pattern="[a-zA-Z0-9]+"
                                        onChange={e => setDetails({ ...details, last_name: e.currentTarget.value })}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        A surname (last name) must be provided and cannot have spaces or special characters.
                                    </Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="g-1">
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Email</Label>
                                    <Control
                                        type="email"
                                        value={details.email}
                                        onChange={e => setDetails({ ...details, email: e.currentTarget.value })}
                                        required={!details.telephone}
                                    />
                                    <Control.Feedback type="invalid">
                                        A valid email address must be provided.
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Telephone</Label>
                                    <Control
                                        pattern="^\+?[1-9]{1}[0-9]{0,2}(-|\s)?([0-9]{2,4}(-|\s)?)+$"
                                        value={details.telephone}
                                        onChange={e => setDetails({ ...details, telephone: e.currentTarget.value })}
                                        required={!details.email}
                                    />
                                    <Control.Feedback type="invalid">
                                        The telephone number should be in the international format.
                                    </Control.Feedback>
                                </Col>
                            </Row>
                        </>
                    }

                    {(action !== "contact") &&
                        <>
                            <Row className="g-1">
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Permit No</Label>
                                    <Control
                                        value={details.permit_no}
                                        onChange={e => setDetails({ ...details, permit_no: e.currentTarget.value })}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        Must be provided.
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Permit Expiry Date</Label>
                                    <DateSelector
                                        step={50}
                                        value={details.permit_expiry}
                                        onChange={date => setDetails(d => ({ ...d, permit_expiry: date }))}
                                        required
                                    />
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Year Started Driving</Label>
                                    <Control
                                        type="number"
                                        step={1}
                                        pattern="[0-9]{4}"
                                        value={details.year_started}
                                        onChange={e => setDetails({ ...details, year_started: e.currentTarget.value })}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        Must be a four digit year
                                    </Control.Feedback>
                                </Col>
                            </Row>
                        </>
                    }

                    <Row>
                        <Col className="mt-4 mb-3 text-end">
                            <SubmitButton isSubmitting={handlingSubmit} onClick={handleSubmit}>
                                {action === "create" ? "Submit" : "Update"}
                            </SubmitButton>
                            <CancelButton isSubmitting={handlingSubmit} onClick={() => setShow(false)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}


export default DriverForm;