import moment from "moment";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Routes, Route, useParams, Link, useNavigate } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import cur, { UGX } from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { UCWords } from "../../components/resources";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import { deletePayment, getPayment } from "../../resources/api/payments";
import { APIURL } from "../../resources/fetch";
import { EditPayment } from "./payment-manipulation";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/payments").PaymentObject} props.details
 */
const ViewDetails = ({ details }) => {

    const nav_items = [
        { title: 'Payments', href: "/app/payments" },
        { title: details.reference }
    ]

    const navigate = useNavigate();

    const handleDelete = () => {
        infoAlert("Deleting Payment, please wait...");

        deletePayment(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate("/app/payments");
            })
            .catch(e => errorAlert(e));
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.reference}</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn title="Download Receipt" href={`${APIURL}/payments/${details.id}/pdf`} isExternalLink>
                            <i className="fas fa-file-pdf" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn onClick={handleDelete} title="Delete Payment">
                            <i className="fas fa-trash-alt" />
                        </Btn>
                        <Btn href={`/app/payments/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                    </ButtonToolbar>

                    <ButtonToolbar>
                        <Btn href="/app/payments/new" title="New Payment">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <h4>Payment Details</h4>
                <dl>
                    <dt>Amount Paid</dt>
                    <dd className="lead">{UGX(details.amount).format()}</dd>
                    <dt>Paid By</dt>
                    <dd>{details.payee}</dd>
                    <dt>Paid On</dt>
                    <dd>{moment(details.payment_date).format("dddd DD MMMM YYYY")}</dd>
                    <dt>Paid Via</dt>
                    <dd>{!!details.method && UCWords(details.method?.replace(/-/g, " "))} into {details.account?.title}</dd>
                    <dt>For Event</dt>
                    <dd><Link to={`/app/events/${details.event_id}`}>{details.event.reference} - {details.client.title}</Link></dd>
                </dl>

                <h4 className="mt-3 mt-md-5">Payment Breakdown</h4>
                <Table responsive hover size="sm" style={{ maxWidth: "600px" }}>
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th className="text-end">Total Amount</th>
                            <th className="text-end">Paid Amount</th>
                            <th className="text-end">Current Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            details.items.map(i => (
                                <tr key={i.id}>
                                    <td>{i.product}</td>
                                    <td className="text-end">{cur(i.total_amount, 0).format()}</td>
                                    <td className="text-end">{cur(i.amount, 0).format()}</td>
                                    <td className="text-end">{cur(i.balance, 0).format()}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>

            </div>

        </>
    )

}


const PaymentDetails = () => {

    const { paymentid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setLoaded(false);
        setError();

        getPayment(paymentid, ['items', 'event', 'client', 'account'])
            .then(({ payment }) => setDetails(payment))
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [paymentid])

    if (!isLoaded) return <PageLoading>Loading payment details...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route path="/edit" element={<EditPayment details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewDetails details={details} />} />
        </Routes>
    )

}


export default PaymentDetails;