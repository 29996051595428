import { APIURL, deleteData, getData, postData } from "../fetch";

/**
 * 
 * @typedef {{
 * id: string,
 * schedule_date: string, 
 * no_events: number
 * no_vehicles: number
 * status: "approved"|"draft"|"pending-approval"
 * date_submitted: string
 * submitted_by: string
 * date_approved: string
 * approved_by: string
 * items: ScheduleItemObject[]
 * }} ScheduleObject 
 */


/**
 * 
 * @typedef {{
 * id: string,
 * schedule_id: string,
 * vehicle_id: string
 * vehicle: import("./vehicles").VehicleObject|string
 * event_id: string|import("./events").EventObject
 * location: string
 * eta: string
 * ata: string
 * ata_reason: string
 * action: string
 * items: ItemProductsObject[]
 * }} ScheduleItemObject
 */


/**
 * 
 * @typedef {{
 * id: string,
 * schedule_item_id: string
 * event_product_id: string
 * quantity: number
 * }} ItemProductsObject
 */



/**
 * 
 * @param {ScheduleObject} data 
 * @returns {Promise<{code: number, message: string, schedule: ScheduleObject}>}
 */
const createSchedule = (data) => {
    return postData({
        url: `${APIURL}/schedules`,
        data
    })
}

/**
 * 
 * @param {ScheduleObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, schedule: ScheduleObject}>}
 */
const updateSchedule = (data, id) => {
    return postData({
        url: `${APIURL}/schedules/${id}`,
        data
    })
}


/**
 * @param {string} id 
 * @param {ScheduleItemObject} data of the note 
 * @returns  {Promise<{code: number, message: string, item: ScheduleItemObject}>}
 */
const updateATA = (id, data) => {

    const obj = { url: `${APIURL}/schedules/items/${id}`, data };

    return postData(obj);
}



/**
 * 
 * @returns {Promise<{code: number, message: string, schedules: ScheduleObject[]}>}
 */
const getSchedules = () => getData({ url: `${APIURL}/schedules` })

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, schedule: ScheduleObject}>}
 */
const getSchedule = (id, includes = null) => {

    const obj = { url: `${APIURL}/schedules/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteSchedule = id => deleteData({ url: `${APIURL}/schedules/${id}` });

const getEvents = (start, end) => {

    const obj = { url: `${APIURL}/schedules/events`, data: { start, end } }

    return getData(obj)

}


export {
    createSchedule,
    deleteSchedule,
    getSchedule,
    getSchedules,
    updateSchedule,
    getEvents,
    updateATA
}