import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Form, Table, Card, Row, Col, Dropdown } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from '../../components/pagination';
import moment from 'moment';
import cur, { UGX } from '../../components/currency';
import { Link, useSearchParams } from 'react-router-dom';
import { sortFunction, UCWords } from '../../components/resources';
import { useScreenType } from '../../components/custom-hooks';
import { getBills } from '../../resources/api/bills';
import { ErrorLoading, PageLoading } from '../../components/loading';

/**
 * View table items on screens larger than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/bills').BillObject[]
 * currentPage: number
 * noPageItems: number
 * totals: number
 * }} param0 
 */
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems, totals }) => {
    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            <colgroup>
                {/* <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "17%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "18%" }} /> */}
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Ref <SortButton field="vendor_reference" {...srt} handleSort={handleSort} /></th>
                    <th>Date <SortButton field="bill_date" {...srt} handleSort={handleSort} /></th>
                    <th>Due Date <SortButton field="due_date" {...srt} handleSort={handleSort} /></th>
                    <th>Vendor <SortButton field="vendor" {...srt} handleSort={handleSort} /></th>
                    <th>Order <SortButton field="purchase_order" {...srt} handleSort={handleSort} /></th>
                    <th style={{ textAlign: 'right' }}>Amount <SortButton field="total_amount" {...srt} handleSort={handleSort} /></th>
                    <th style={{ textAlign: 'right' }}>Balance <SortButton field="balance" {...srt} handleSort={handleSort} /></th>
                    <th>Client <SortButton field="client" {...srt} handleSort={handleSort} /></th>
                    <th>Job <SortButton field="job" {...srt} handleSort={handleSort} /></th>
                    <th>Status <SortButton field="status" {...srt} handleSort={handleSort} /></th>
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td><Link to={`/app/bills/${e.id}`}>{e.vendor_reference}</Link></td>
                        <td>{moment(e.bill_date).format("DD MMM YYYY")}</td>
                        <td>{moment(e.due_date).format("DD MMM YYYY")}</td>
                        <td><Link to={`/app/vendors/${e.vendor_id}`}>{e.vendor}</Link> </td>
                        <td>{!!e.purchase_order_id ?
                            <Link to={`/app/purchase-orders/${e.purchase_order_id}`}>{e.purchase_order}</Link> : 'n/a'}</td>
                        <td style={{ textAlign: 'right' }}>{cur(e.total_amount, 0).format()}</td>
                        <td style={{ textAlign: 'right' }}>{cur(e.balance, 0).format()}</td>
                        <td>{!!e.client_id ?
                            <Link to={`/app/clients/${e.client_id}`}>{e.client}</Link> : 'n/a'}</td>
                        <td>{!!e.event_id ?
                            <Link to={`/app/events/${e.event_id}`}>{e.event_location} | {e.reference}</Link> : 'n/a'}</td>
                        <td>{UCWords(e.status.replace(/-/g, " "))}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={6} />
                    <th className='text-end'>
                        {cur(totals.total, 0).format()}
                    </th>
                    <th className='text-end'>
                        {cur(totals.balance, 0).format()}
                    </th>
                    <th colSpan={4} />
                </tr>
            </tfoot>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/bills').BillObject[]
 * }} param0 
 */
const ViewMobile = ({ srt, handleSort, items }) => {
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Reference <SortButton field="vendor_reference" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Vendor <SortButton field="vendor" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Date <SortButton field="bill_date" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Due Date <SortButton field="due_date" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Total <SortButton field="total_amount" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Balance <SortButton field="balance" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Client <SortButton field="client" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Event <SortButton field="event" {...srt} handleSort={handleSort} />
                </span>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-3">
                            <span className="text-muted">{moment(e.bill_date).format("DD MMM YYYY")}</span>
                            <span className="text-muted">{UCWords(e.status.replace(/-/g, " "))}</span>
                        </div>

                        <div>
                            <Link to={`/app/bills/${e.id}`} className="lead text-secondary fw-normal">
                                {e.vendor_reference} ({UCWords(e.reference)})
                            </Link>
                            <h4 className="h4 mt-2">{UGX(e.total_amount).format()} <span className='small'>({cur(e.balance, 0).format()})</span></h4>
                            <h4 className="h6">{e.vendor}</h4>
                        </div>

                        {!!e.client &&
                            <div className="d-flex justify-content-between mt-3">
                                <span className="text-muted fw-normal">
                                    <Link to={`/app/clients/${e.client_id}`}>{e.client}</Link> | <Link to={`/app/events/${e.event_id}`}>{e.event}</Link>
                                </span>
                            </div>
                        }
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}


const ViewBills = () => {


    const [srt, setSrt] = useState({
        currentField: 'bill_date',
        currentOrder: 'desc'
    });

    const [searchParams] = useSearchParams();

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'vendor_reference' });

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [navItems, setNavItems] = useState([{
        title: 'Bills'
    }]);


    const [totals, setTotals] = useState(0);

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);


    const [bill_view, setView] = useState("unpaid");

    useEffect(() => {

        const _view = searchParams.get("view") || "unpaid";
        setView(_view);

    }, [searchParams])


    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {
        const v = search.val, f = search.field;

        let view_items = [...orginalItems];

        if (bill_view !== "all") {
            view_items = view_items.filter(i => i.status === bill_view);
        }

        if (v.length < 2 || f === "") {
            setTotals({
                total: view_items.reduce(
                    (p, c) => p + c.total_amount, 0
                ),
                balance: view_items.reduce(
                    (p, c) => p + c.balance, 0
                )
            });
            return setItems(view_items);
        };

        view_items = view_items.filter(i => i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1);
        setItems(view_items);

        setTotals({
            total: view_items.reduce(
                (p, c) => p + c.total_amount, 0
            ),
            balance: view_items.reduce(
                (p, c) => p + c.balance, 0
            )
        });

    }, [search.val, search.field, orginalItems, bill_view]);




    /**
     * get the items
     */
    useEffect(() => {

        setLoaded(false);
        setError(null);

        let conditions = null,
            vendor_id = searchParams.get("vendor");

        if (vendor_id) conditions = { vendor_id };

        getBills(true, conditions)
            .then(({ bills }) => {

                if (bills.length > 0) {

                    if (vendor_id) {
                        let vendor = bills[0].vendor;
                        setNavItems([{ title: "Bills", href: `/app/bills` }, { title: vendor }]);
                    }
                    else {
                        setNavItems([{ title: "Bill" }]);
                    }

                    bills.sort((a, b) => sortFunction(a, b, "bill_date", "desc"));
                    setOriginalItems(bills);
                    setItems(bills);
                }


            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [searchParams])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => {
            if (srt.currentOrder === 'asc') {
                return sortFunction(a, b, srt.currentField, "asc");
            }
            return sortFunction(a, b, srt.currentField, "desc");;
        }

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }

    const views = {
        all: "All",
        unpaid: "Unpaid",
        "partially-paid": "Partially Paid",
        paid: "Paid",
        overdue: "Overdue",
        cancelled: "Cancelled"
    }



    if (!loaded) return <PageLoading>Loading all bills...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1200">
                <Title>Bills</Title>
                <ActionBar>
                    {/* <ButtonToolbar>
                        <Btn onClick={onDownloadClick}>
                            <i className="fas fa-download me-1" />Download
                        </Btn>
                    </ButtonToolbar> */}
                    <ButtonToolbar>
                        <Btn href="/app/bills/new">
                            <i className="fas fa-plus-circle me-1" />New Bill
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1200">

                <div className='mb-2'>

                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic">
                            View: <strong>{views[bill_view]}</strong>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {Object.keys(views).map(v => <Dropdown.Item as={Link} key={v} to={`/app/bills?view=${v}`}>{views[v]}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="Search For" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="vendor_reference">Vendor Ref</option>
                            <option value="reference">Reference</option>
                            <option value="vendor">Vendor</option>
                            <option value="status">Status</option>
                            <option value="bill_date">Date</option>
                            <option value="job">Job</option>
                            <option value="client">Client</option>
                        </Form.Select>
                    </Col>

                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} totals={totals} /> :
                    <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>

        </>
    )
}


export default ViewBills;