
import { useEffect, useRef, useState } from "react";
import { Routes, Route, useParams, useNavigate, Link } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { deleteSchedule, getSchedule, updateATA } from "../../resources/api/schedules";
import moment from "moment";
import { UCWords, sortFunction } from "../../components/resources";
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip } from "react-bootstrap";
import { APIURL } from "../../resources/fetch";
import { EditSchedule } from "./schedule-manipulation";
import { ConfirmAction } from "../../components/prompt";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import { TimeSelector } from "../../components/datetimepicker";
import { CancelButton, SubmitButton } from "../../components/btns";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/schedules").ScheduleObject} props.details
 */
const ViewDetails = ({ details, setDetails }) => {

    const navigate = useNavigate();

    const [key, setKey] = useState("products");

    const nav_items = [
        { title: 'Schedules', href: "/app/schedules" },
        { title: moment(details.schedule_date).format("ddd DD/MM/YYYY") }
    ]

    const handleDelete = () => {
        infoAlert("Deleting the schedule");
        deleteSchedule(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate(`/app/schedules`);
            })
            .catch(errorAlert)
    }

    const updateItem = item => {
        let items = [...details.items],
            ind = items.findIndex(p => p.id === item.id);

        items.splice(
            ind,
            1,
            { ...items[ind], ...item }
        );

        setDetails(d => ({ ...d, items }))
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{moment(details.schedule_date).format("dddd DD MMMM YYYY")}</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn title="Download Schedule" href={`${APIURL}/schedules/${details.id}/pdf`} isExternalLink>
                            <i className="fas fa-file-pdf" />
                        </Btn>
                        <ConfirmAction
                            as={Btn}
                            confirmationText="Are you sure you want to delete this schedule? This action cannot be reversed."
                            handleAction={handleDelete}
                            title="Delete Schedule"
                        >
                            <i className="fas fa-trash-alt" />
                        </ConfirmAction>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/schedules/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/schedules/new" title="New Schedule">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <h4>Schedule Details</h4>
                <dl>
                    <dt>Status</dt>
                    <dd>{UCWords(details.status.replace(/-/g, " "))}</dd>
                    <dt>No Deliveries</dt>
                    <dd>{details.no_events}</dd>
                    <dt>No Vehicles</dt>
                    <dd>{details.no_vehicles}</dd>
                </dl>

                {/* <ViewEvents events={details.events} /> */}

                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="products" title="Breakdown">
                        <ScheduleProducts products={details.products} />
                    </Tab>
                    <Tab eventKey="items" title="Delivery Times">
                        <ItemDeliveries items={details.items} onUpdateItem={updateItem} />
                    </Tab>
                </Tabs>



            </div>

        </>
    )

}

/**
 * 
 * @param {Object} props 
 * @param {import("../../resources/api/schedules").ItemProductsObject[]} props.products
 * @returns 
 */
const ScheduleProducts = ({ products }) => {

    return (
        <>
            <h4 className="mt-2">Schedule</h4>
            <Table>
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Event Date</th>
                        <th>Location</th>
                        <th>Action</th>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>ETA</th>
                        <th>Vehicle</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(p => (
                        <tr key={p.id}>
                            <td><Link to={`/app/events/${p.event_id}`}>{`${p.client_title} - ${p.event_reference}`}</Link></td>
                            <td>{moment(p.event_date).format("DD MMM YYYY")}</td>
                            <td>{p.event_location}</td>
                            <td>{UCWords(p.action)}</td>
                            <td>{p.product}</td>
                            <td>{p.product_quantity}</td>
                            <td>{moment(p.eta, "HH:mm:ss").format("HH:mm")}</td>
                            <td><Link to={`/app/vehicles/${p.vehicle_id}`}>{p.reg_no}</Link></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}


/**
 * 
 * @param {Object} props 
 * @param {import("../../resources/api/schedules").ScheduleItemObject[]} props.items
 * @returns 
 */
const ItemDeliveries = ({ items, onUpdateItem }) => {


    const [details, setDetails] = useState(({ id: "", ata: "", ata_reason: "", }))

    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const formref = useRef();

    const handleClose = () => {
        setDetails({ id: "", ata: "", ata_reason: "", });
        setSubmitting(false);
    }

    const handleSubmit = e => {

        e.preventDefault();

        if (!formref.current.checkValidity()) {
            setValidated(true);
            errorAlert("There are some errors with your inputs.");
            return;
        }

        setValidated(false);

        setSubmitting(true);

        updateATA(details.id, details)
            .then(({ item }) => {
                setShow(false);
                onUpdateItem(item);
            })
            .catch(e => errorAlert(e))
            .finally(() => setSubmitting(false))
    }


    const handleEdit = id => {

        let _item = [...items].find(p => p.id === id);
        setDetails(({
            id: _item.id,
            ata: _item.ata || "",
            ata_reason: _item.ata_reason || ""
        }))
        setShow(true);

    }

    return (
        <>
            <h4 className="mt-2">Item Deliveries</h4>
            <Table responsive hover size="sm" style={{ maxWidth: "700px" }}>
                <thead>
                    <tr>
                        <th>Event</th>
                        <th className="">Location</th>
                        <th className="">Action</th>
                        <th className="">Vehicle</th>
                        <th className="">ETA</th>
                        <th className="">ATA</th>
                        <th className="">...</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map(p => (
                            <tr key={p.id}>
                                <td><Link to={`/app/events/${p.event_id}`}>{p.client_title} - {p.event_reference}</Link></td>
                                <td>{p.event_location}</td>
                                <td>{p.action}</td>
                                <td><Link to={`/app/vehicles/${p.vehicle_id}`}>{p.reg_no}</Link></td>
                                <td className="">{!!p.eta ? moment(p.eta, "HH:mm:ss").format("HH:mm") : 'n/a'}</td>
                                <td className="">
                                    {!!p.ata ? moment(p.ata, "HH:mm:ss").format("HH:mm") : 'n/a'}
                                    {!!p.ata_reason &&
                                        <OverlayTrigger overlay={<Tooltip id={p.id}>{p.ata_reason}</Tooltip>}>
                                            <i className="fas fa-circle-info ps-2" />
                                        </OverlayTrigger>
                                    }
                                </td>
                                <td style={{ width: "5%" }}>
                                    <Button size="sm" onClick={() => handleEdit(p.id)} variant="link" className="text-danger">
                                        <i className="fas fa-pencil-alt" />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </Table>


            <Modal show={show} onExit={handleClose} centered>
                <Modal.Body>
                    <Form ref={formref} noValidate validated={validated} >
                        <Row className="g-2 my-3">
                            <Col sm={12} className="my-1">
                                <Form.Label className="form-field-title">Time of Delivery</Form.Label>
                                <TimeSelector
                                    value={details.ata}
                                    onChange={date =>
                                        setDetails(d => ({
                                            ...d,
                                            ata: date
                                        }))}
                                    placeholder=""
                                    required={true}
                                />
                                <Form.Control.Feedback type="invalid">
                                    A valid date must be provided.
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={12} className="my-1">
                                <Form.Label className="form-field-title">Comments</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    value={details.ata_reason}
                                    onChange={e => setDetails({ ...details, ata_reason: e.currentTarget.value })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    A valid date must be provided.
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>
                <Modal.Footer className="py-1">
                    <SubmitButton isSubmitting={submitting} onClick={handleSubmit}>
                        Update
                    </SubmitButton>
                    <CancelButton onClick={() => setShow(false)} isSubmitting={submitting}>
                        Cancel
                    </CancelButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}




const ScheduleDetails = () => {

    const { scheduleid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();


    useEffect(() => {

        setLoaded(false);
        setError(null);

        // const sortFn = (a, b) => sortFunction(a, b, 'event_date', "asc");

        getSchedule(scheduleid, ["products", "items"])
            .then(({ schedule }) => {
                schedule.products.sort((a, b) => sortFunction(a, b, "eta"));
                setDetails({ ...schedule });
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [scheduleid])

    //load events details

    if (!isLoaded) {
        return <PageLoading />
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Routes>
            <Route path="/edit" element={<EditSchedule details={details} />} />
            <Route path="/" element={<ViewDetails details={details} setDetails={setDetails} />} />
        </Routes>
    )

}


export default ScheduleDetails;