import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Table } from 'react-bootstrap';
import { Route, Routes, useParams } from 'react-router-dom';
import style from '../expenses/expenses.scss';
import { useEffect, useState } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import moment from "moment";
// import { UCWords } from '../../components/resources';
import cur from '../../components/currency';
import { payrollTypes } from '../../assets/constants';
import { getPayrollPayment } from '../../resources/api/payroll';
import { EditPayment } from './staff-payment-manipulation';
import { useMemo } from 'react';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * @param {import('../../resources/api/payroll').StaffPaymentObject} props.details
 * 
 */
const ViewPayment = ({ details }) => {

    const nav_items = [
        { title: 'Staff Payments', href: '/app/staff-payments' },
        { title: details.reference }
    ];

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };

    const canEdit = useMemo(() => {

        let e = true;
        details.workers.forEach(w => {
            if (e && (w.isPaid || w.isApproved)) e = false;
        })

        return e;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(details.workers)])

    // const approvePayment = 

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    {details.reference}
                </Title>
                <ActionBar>
                    {/* <ButtonToolbar>
                        <Btn title="Approve All">
                            <i className="fas fa-check-circle" />
                        </Btn>
                    </ButtonToolbar> */}
                    <ButtonToolbar>
                        {canEdit &&
                            <Btn href={`/app/staff-payments/${details.id}/edit`} title="Edit Details">
                                <i className="fas fa-pencil-alt" />
                            </Btn>
                        }

                        <Btn href="/app/staff-payments/new" title="Initiate Payment">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-800 p-1 expenses">

                <div className="text-end">
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    INITIATION DATE: <span style={style1}>{moment(details.date_added).format("DD MMMM YYYY")}</span> <br />
                    {/* STATUS: <span style={style1}>{UCWords(details.status.replace(/-/g, " "))}</span><br /> */}
                </div>

                <div className="my-3">
                    <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                        UGX <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(details.total_amount).format()}</span>
                    </span>
                </div>

                <Table size="sm" borderless style={{ maxWidth: '500px' }}>
                    <colgroup>
                        <col span="1" style={{ width: "50%" }} />
                        <col span="1" style={{ width: "50%" }} />
                    </colgroup>

                    <tbody>
                        <tr>
                            <td>Category</td>
                            <td>{payrollTypes[details.payroll_type].title}</td>
                        </tr>
                        <tr>
                            <td>Overtime Charge</td>
                            <td>{cur(details.overtime_rate).format()}</td>
                        </tr>
                    </tbody>
                </Table>
                <WorkerPayments items={details.workers} />
            </div>
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/payroll').LabourObject[]} props.items 
 * @returns 
 */
const WorkerPayments = ({ items }) => (
    <div className={`mt-3 mt-sm-4 mb-2 ${style}`}>
        <h5>Items</h5>

        <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "30%" }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "22%" }} />
                <col span="1" style={{ width: "8%" }} />
                <col span="1" style={{ width: "7%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Duration</th>
                    <th>Overtime(hrs)</th>
                    <th>Amount</th>
                    <th>Approved</th>
                    <th>Paid</th>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => (
                    <tr key={i.id}>
                        <td>{idx + 1}</td>
                        <td>{i.display_name}</td>
                        <td>{i.duration}</td>
                        <td>{i.overtime_hrs}</td>
                        <td>{cur(i.amount, 0).format()}</td>
                        <td>{i.isProcessed ? "Yes" : "No"}</td>
                        <td>{i.isPaid ? "Yes" : "No"}</td>
                    </tr>
                ))}
            </tbody>

        </Table>

    </div>
)

const PaymentDetails = () => {

    const { paymentid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getPayrollPayment(paymentid, ['workers'])
            .then(({ payment }) => {
                setDetails(payment);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [paymentid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading payment details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/edit" element={<EditPayment details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewPayment details={details} />} />
        </Routes>
    )

}

export default PaymentDetails;