import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import StaffDetails from './staff-details';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import ViewStaff from './view-staff';

const Staff = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level !== 1) {
        return <ErrorLoading>You do not have the permission to view/add/edit staff members.</ErrorLoading>
    }


    return (
        <Routes>
            <Route path="/:staffid/*" element={<StaffDetails />} />
            <Route path="/" element={<ViewStaff />} />
        </Routes>
    )
};

export default Staff;